import React, {useContext} from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'antd';
import { Context } from '../../store';
import { PlusOutlined, StarFilled, FireFilled } from '@ant-design/icons';
import { getUSDollar } from '../../libs/utility';

const SpotPositionBox = ({coin, old, toggleModal, toggleHistoryModal}) => {
    const {state} = useContext(Context);

    const symbolName = coin.symbol.baseAsset.toLowerCase();
    const detailLink = "spot";

    const addNewProcess = () => {
        toggleModal(coin.symbol.id, coin.period);
    }

    const openProcessHistory = () => {
        toggleHistoryModal(coin.symbol.id, coin.period);
    }

    const priceBuilder = () => {
        let oldPrice = 0;
        if(old && old.currentPrice) {
            oldPrice = old.currentPrice;
        }

        return <p className={coin.currentPrice >= oldPrice ? "green" : "red"}>{getUSDollar(coin.currentPrice, 5)}</p>
    }

    const openUrl = () => {
        window.open(`https://www.tradingview.com/chart/fu2csY4t/?symbol=${coin.symbol.market.toUpperCase()}%3A${coin.symbol.symbol}`, '_blank');
    }

    return (
        <Col xs={24} md={5}>
            <div className={`spot-position-box-new ${coin.period === "short" ? 'short' : ''}`}>
                <div className="pos-period">
                    {coin.period === "mid" && <StarFilled className='mid' />}
                    {coin.period === "short" && <FireFilled className='short' />}
                    {coin.symbol.market != "Binance" && <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${coin.symbol.market.replace('.', '').replace(' ', '').toLowerCase()}.svg`} alt={coin.symbol.market} />}
                </div>
                <Button type="primary" shape="circle" icon={<PlusOutlined />} size={"middle"} className='btn-add-process btn-cryptoverse' onClick={addNewProcess} />
                <div className={'currency-base'}>
                    <Link to={`/${detailLink}-currencies/${coin.symbol.baseAsset.toLowerCase()}`}>
                        <div className='currency-image'>
                            <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${symbolName}.svg`} alt="" />
                            <p className='symbol-name'><strong>{coin.symbol.baseAsset}</strong></p>
                            <p className='full-name'>({coin.symbol.name})</p>
                        </div>          
                    </Link>
                    <div className='currency-price'>
                        {priceBuilder()}
                    </div>
                </div>
                <div className="currency-details">
                    <div className="current-balance">
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <div className="label">Quantity</div>
                                <div className="value">{coin.quantity}</div>
                            </Col>
                            <Col xs={24} md={8}>
                                <div className="label">Average Price</div>
                                <div className="value">{getUSDollar(coin.averageCost, 6)}</div>
                            </Col>
                            <Col xs={24} md={8}>
                                <div className="label">Balance</div>
                                <div className="value">{getUSDollar(coin.balance)}</div>
                            </Col>
                        </Row>
                    </div>
                    <div className="pnl-stats">
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <div className="label">Total PnL</div>
                                <div className="value">{getUSDollar(coin.totalPnL)}</div>
                            </Col>
                            <Col xs={24} md={16}>
                                <div className="label c-label">Current PnL</div>
                                <div className={`value ${coin.currentPnL >= 0 ? "green" : "red"} c-value`}>{getUSDollar(coin.currentPnL)} ({Number(coin.percentPnL).toFixed(2)}%)</div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="history">
                    <Row gutter={16}>
                        <Col xs={8} md={12} className='mobile-add'>
                            <Button type="primary" onClick={addNewProcess} block className='btn-cryptoverse'>New</Button>
                        </Col>
                        <Col xs={8} md={12}>
                            <Button type="primary" onClick={openProcessHistory} block className='btn-cryptoverse'><span className="web-text">Process History</span><span className="mobile-text">History</span></Button>
                        </Col>
                        <Col xs={8} md={12}>
                            <Button type="primary" block className="btn-black" onClick={openUrl}><span className="web-text">TradingView</span><span className="mobile-text">TV</span></Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Col>
    );
};

export default React.memo(SpotPositionBox);