import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import SpotPositionBox from '../../components/SpotPositionBox/indexFirebase';
import {Context} from '../../store';
import {Row, Col, Button, Switch, Checkbox} from 'antd';
import './style.scss';
import AddSpotProcessModal from '../../components/AddSpotProcessModal/indexFirebase';
import ProcessHistoryModal from '../../components/ProcessHistoryModal/indexFirebase';
import AddNewSymbolModal from '../../components/AddNewSymbolModal';

import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { AuthContext } from '../../contexts/AuthContext';
import SpotPositionTable from '../../components/SpotPositionTable';
import { usePrevious } from '../../hooks/usePrevious';
import { firebaseService, binanceService, gateService, mexcService } from '../../services';
import { SyncOutlined } from '@ant-design/icons';
import { getUSDollar, groupBy } from '../../libs/utility';
import { CSVLink } from "react-csv";

const csvHeader = [
    { label: 'Symbol', key: 'symbol.symbol' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Average Cost', key: 'averageCost' },
    { label: 'Balance', key: 'balance' },  
    { label: 'Current Price', key: 'currentPrice' },     
    { label: 'Current PnL', key: 'currentPnL' },   
    { label: 'Current PnL (%)', key: 'percentPnL' },
  ];

const periodOrder = ["short", "mid", "long"];

const Wallet = () => {
    const {state, dispatch} = useContext(Context);
    const { user } = useContext(AuthContext);
    const [balance, setBalance] = useState([]);
    const prevBalance = usePrevious(JSON.parse(JSON.stringify(balance)));
    const [totalPnL, setTotalPnL] = useState(0);
    const [currentPnL, setCurrentPnL] = useState(0);
    const [currentPnLPercent, setCurrentPnLPercent] = useState(0);
    const [activeBalance, setActiveBalance] = useState(0);
    // TODO: spot accountları çekip dağılıma göre hesaplatıcaz.
    const [passiveBalance, setPassiveBalance] = useState(0);
    const [toggleProcessModal, setToggleProcessModal] = useState(false);
    const [toggleHistoryModal, setToggleHistoryModal] = useState(false);
    const [toggleNewSymbolModal, setToggleNewSymbolModal] = useState(false);

    const [symbolList, setSymbolList] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [viewType, setViewType] = useState("Card");
    const [hideZero, setHideZero] = useState(false);
    const [period, setPeriod] = useState(null);
    const [marketList, setMarketList] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState([]);

    const addNewSymbol = async () => {
        let newSymbol = {
            baseAsset: "ARKM",
            iconUrl: "arkm.svg",
            market: "Binance",
            name: "Arkham",
            official: "",
            pricePrecision: 2,
            quantityPrecision: 0,
            quoteAsset: "USDT",
            symbol: "ARKMUSDT",
            type: "BOTH"
        }

        console.log('new symbol', newSymbol);
        const addedSymbol = await firebaseService.addSymbol(newSymbol);
        console.log('added symbol', addedSymbol);
        return addedSymbol;
    }

    const getAllSymbols = async () => {
        const symbols = await firebaseService.getSymbols();
        console.log('all symbols', symbols);
        return symbols;
    }

    /*
    var groupBy = function(xs, key, subKey) {
        return xs.reduce(function(rv, x) {
          if(subKey) {
            (rv[x[key][subKey]] = rv[x[key][subKey]] || []).push(x);
          } else {
            (rv[x[key]] = rv[x[key]] || []).push(x); 
          }
          return rv;
        }, {});
    };
    */

    useEffect(() => {
        let interval;
        const q = query(collection(db, "spot-balances"), where("userId", "==", user.uid));
        let unsubscribe = onSnapshot(q, async (snapshot) => {
            //console.log('on snapshot', snapshot);
            let balances = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

            if(balances.length) {
                let newBalance = await Promise.all(balances.map(async b => {
                    // burayı da yine symbolleri globalde tutup dolu ise oradan dolduracaz.

                    let symbol = await new Promise(async (resolve, reject) => {
                        if(state.firebaseSymbols.length) {
                            let symbol = state.firebaseSymbols.find(s => s.id === b.symbolId);
                            //console.log('state symbol', symbol);
                            resolve(symbol)
                        }
                    });
                    
                    symbol.id = b.symbolId;
                    let newBalanceItem = b;
                    newBalanceItem.symbol = symbol;
                    return newBalanceItem;          
                }));
                let groupByMarket = groupBy(newBalance, 'symbol', 'market');

                //let groupByMarket = Object.groupBy(newBalance, ({symbol: {market}}) => market);

                let marketList = Object.keys(groupByMarket)
                setMarketList(marketList);

                let extendedBalance = await getTickers(newBalance);

                setBalance(extendedBalance.sort((a, b) => periodOrder.indexOf(a.period) - periodOrder.indexOf(b.period) || (b.quantity * b.averageCost) - (a.quantity * a.averageCost)));
                clearInterval(interval);
                interval = setInterval(async () => {
                    let extendedBalance = await getTickers(newBalance);
                    setBalance(extendedBalance.sort((a, b) => periodOrder.indexOf(a.period) - periodOrder.indexOf(b.period) || (b.quantity * b.averageCost) - (a.quantity * a.averageCost)));
                }, 60000);  
            };
        }, async (error) => {
            console.log('snapshot error', error);
        });

        if(state.firebaseSymbols.length) {
            //console.log('firebase symbols', state.firebaseSymbols);
            setSymbolList(state.firebaseSymbols);
        }

        return () => {
            unsubscribe();
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if(!selectedMarkets.length) {
            setSelectedMarkets(marketList);
        }
    }, [marketList]);

    const getTickers = async (data) => {
        let binanceSymbols = data.filter(w => w.symbol.market === 'Binance' || w.symbol.market === 'Binance TR').map(w => w.symbol.baseAsset + w.symbol.quoteAsset);
        let otherSymbols = data.filter(w => w.symbol.market != 'Binance' && w.symbol.market != 'Binance TR').map(w => w.symbol.baseAsset + w.symbol.quoteAsset);
        //console.log('symbols', data, binanceSymbols, mexcSymbols, balance);

        //let getBinanceDuplicates = binanceSymbols.filter((val, i) => binanceSymbols.indexOf(val) !== i);
        //let getMexcDuplicates = mexcSymbols.filter((val, i) => mexcSymbols.indexOf(val) !== i);

        let uniqueBinanceSymbols = [...new Set(binanceSymbols)];
        let uniqueOtherSymbols = [...new Set(otherSymbols)];

        let currentPricesBinance = await binanceService.getTickerPrice(JSON.stringify(uniqueBinanceSymbols));
        let currentPricesOthers = uniqueOtherSymbols.length ? await gateService.getTickerPrice(uniqueOtherSymbols) : [];

        let currentPricesList = [...currentPricesBinance, ...currentPricesOthers];

        let currentWallet = [];
        currentPricesList.map(r => {
            let extendItems = data.filter(w => w.symbol.symbol === r.symbol);

            extendItems.map(e => {
                e.currentPrice = r.price;
                e.balance = e.quantity * e.averageCost;
                e.currentPnL = (r.price * e.quantity) - (e.quantity * e.averageCost);
                e.percentPnL = e.averageCost > 0 ? ((r.price - e.averageCost) * 100) / e.averageCost : 0;
                currentWallet.push(e);

            });

            return extendItems;
        })
        return currentWallet;  
    }

    const onUpdatePrice = async () => {
        let extendedBalance = await getTickers(balance);
        setBalance(extendedBalance.sort((a, b) => periodOrder.indexOf(a.period) - periodOrder.indexOf(b.period) || (b.quantity * b.averageCost) - (a.quantity * a.averageCost)));
    }

    const toggleSymbolModal = () => {
        setToggleNewSymbolModal(!toggleNewSymbolModal);
    }

    const toggleModal = (hasSelected, period = "long") => {
        //console.log('toggleModal', hasSelected);
        if(hasSelected) {
            let symbol = symbolList.find(s => s.id === hasSelected);
            setSelectedSymbol(symbol);
            setPeriod(period);
        }
        else {
            setSelectedSymbol(null);
            setPeriod(null);
        }
        setToggleProcessModal(!toggleProcessModal);
    }

    const toggleHistoryProcessModal = (hasSelected, period = 'long') => {
        //console.log('toggleHistoryModal', hasSelected);
        if(hasSelected) {
            let symbol = symbolList.find(s => s.id === hasSelected);
            setSelectedSymbol(symbol);
            setPeriod(period);
        }
        else {
            setSelectedSymbol(null);
            setPeriod(null);
        }
        setToggleHistoryModal(!toggleHistoryModal);
    }

    const filterWithHideZero = useMemo(() => {
        let filteredBalance = balance;
        filteredBalance = balance.filter(b => {
            let isTrue = false;
            selectedMarkets.map(m => {
                if(b.symbol?.market === m) {
                    isTrue = true;
                }
            });

            return isTrue;
        });

        if(filteredBalance.length > 0) {
            let totalPnl = filteredBalance.filter(b => b.period !== "short").reduce((total, item) => total + Number(item.totalPnL), 0);
            let actBalance = filteredBalance.filter(b => b.period !== "short").reduce((total, item) => total + (item.averageCost * item.quantity), 0);
            let currBalance =  filteredBalance.filter(b => b.period !== "short").reduce((total, item) => total + (item.currentPrice * item.quantity), 0);
            let currPnl = filteredBalance.filter(b => b.period !== "short").reduce((total, item) => total + ((item.currentPrice * item.quantity) - (item.quantity * item.averageCost)), 0);
            let currPnlPercent = ((currBalance - actBalance) * 100) / actBalance || 0;
            //console.log('abs', currPnl, currBalance - actBalance, ((actBalance - Math.abs(currPnl)) * 100) / Math.abs(currPnl));
            //(record.currentPrice * record.quantity) - (record.quantity * record.averageCost);
            setTotalPnL(totalPnl.toFixed(2));
            setActiveBalance(actBalance.toFixed(2));
            setCurrentPnL(currPnl.toFixed(2));
            setCurrentPnLPercent(currPnlPercent.toFixed(2));
        }
        else {
            setTotalPnL(0);
            setActiveBalance(0);
            setCurrentPnL(0);
            setCurrentPnLPercent(0);          
        }

        if (hideZero) {
            filteredBalance = filteredBalance.filter(b => b.quantity > 0);
        }

        return filteredBalance;
    }, [hideZero, balance, selectedMarkets]);

    let getBalances = useMemo(() => filterWithHideZero.map((coin, i) => {
        return (
            <SpotPositionBox coin={coin} old={prevBalance[i]} key={i} toggleModal={toggleModal} toggleHistoryModal={toggleHistoryProcessModal} />
        );
    }), [hideZero, balance, selectedMarkets]);  

    let getMarketList = useMemo(() => {
        return (
            <div className='market-list'>
                <Checkbox.Group options={marketList} defaultValue={marketList} onChange={setSelectedMarkets} />
            </div>
        );
    }, [marketList]);

    const onHideZeroQuantity = (e) => {
        setHideZero(e.target.checked);
        //console.log(`checked = ${e.target.checked}`);
    };


    const switchMode = (checked) => {
        //console.log('switch', checked);
        setViewType(checked ? "Card" : "Table");
    }
    
    return (
        <div className='wallet container-xl'>
            <div className="wallet-header">
                <Row gutter={16}>
                    <Col xs={24} md={6}>
                        <p className="wallet-title">SPOT <span>WALLETS</span></p>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className='price-area'>
                            <p className="realized">Realized PnL: <span>{getUSDollar(totalPnL)}</span></p>
                            <p className="active-balance">Active Balance: <span>{getUSDollar(activeBalance)}</span></p>
                            <p className='current-pnl'>Current PnL: <span className={currentPnL > 0 ? 'green' : 'red'}>{getUSDollar(currentPnL)} ({currentPnLPercent}%)</span></p>
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div className='wallet-action'>
                            <Button type="primary" shape="circle" icon={<SyncOutlined />} size={"middle"} className='btn-gray' onClick={onUpdatePrice} />
                            <Button type={'primary'} className="btn-cryptoverse" onClick={() => toggleModal(null)}>Add New Process</Button>
                            <CSVLink data={balance} headers={csvHeader} filename={"spot-balance.csv"}><Button type={'primary'} className="btn-green">Export Excel</Button></CSVLink>
                        </div>
                    </Col>
                    {balance.length ?
                        <>
                            <Col xs={24} md={12}>
                                <div className='item count'>
                                    <p>Total Assets: <span>{filterWithHideZero.filter(b => b.quantity > 0).length}</span></p>
                                    {marketList.length > 1 && getMarketList}
                                </div>
                            </Col>
                            <Col xs={24} md={12}>
                                <div className='switch-mode'>
                                    <Checkbox onChange={onHideZeroQuantity}>hide zero quantity items</Checkbox>
                                    <Switch checkedChildren="Card" unCheckedChildren="Table" defaultChecked onChange={switchMode}/>
                                </div>
                            </Col>
                        </>
                        : ""                
                    }
                </Row>
            </div>
            <div className='wallet-body'>
                <Row gutter={16}>
                    {balance.length && viewType === "Table" ? 
                        <Col span="24"><SpotPositionTable balance={filterWithHideZero} prevBalance={prevBalance} toggleModal={toggleModal} toggleHistoryModal={toggleHistoryProcessModal} /></Col>
                        : 
                        getBalances
                    }
                </Row>
            </div>

            {toggleProcessModal && <AddSpotProcessModal showModal={toggleProcessModal} toggleModal={toggleModal} symbols={symbolList} selectedSymbol={selectedSymbol} period={period} />}
            {toggleHistoryModal && <ProcessHistoryModal showModal={toggleHistoryModal} toggleModal={toggleHistoryProcessModal} selectedSymbol={selectedSymbol} period={period} />}
            {user.uid === "2N94LxkquqZWiRawQiJIYNaVhi32" &&
                <>
                    <Button type={'primary'} className="btn-cryptoverse" onClick={toggleSymbolModal} style={{marginRight: 10}}>Add New Symbol</Button>
                    <Button type={'primary'} className="btn-cryptoverse" onClick={getAllSymbols}>Get All Symbols</Button>
                    {toggleNewSymbolModal && <AddNewSymbolModal showModal={toggleNewSymbolModal} toggleModal={toggleSymbolModal} />}

                </>
            }
        </div>
    );
};

export default React.memo(Wallet);

// Hesabım sayfası yapılacak. Orada tw base link alanı olur. Ayrıca belki total bakiye manuel eklenip wallet'ta hesaplara dahil edilebilir.
// firebase kotası fena o yuzden symbolleri state'e ekleyip ordan okuycaz.
