import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import SpotPositionBox from '../../components/SpotPositionBox/indexFirebase';
import {Context} from '../../store';
import {Row, Col, Button, Switch, Checkbox} from 'antd';
import './style.scss';
import AddSpotProcessModal from '../../components/AddSpotProcessModal/indexFirebase';
import ProcessHistoryModal from '../../components/ProcessHistoryModal/indexFirebase';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { AuthContext } from '../../contexts/AuthContext';
import SpotPositionTable from '../../components/SpotPositionTable';
import { usePrevious } from '../../hooks/usePrevious';
import { firebaseService, binanceService, gateService, mexcService } from '../../services';
import { SyncOutlined } from '@ant-design/icons';
import { getUSDollar } from '../../libs/utility';
import { CSVLink } from "react-csv";

const csvHeader = [
    { label: 'Symbol', key: 'symbol.symbol' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Average Cost', key: 'averageCost' },
    { label: 'Balance', key: 'balance' },  
    { label: 'Current Price', key: 'currentPrice' },     
    { label: 'Current PnL', key: 'currentPnL' },   
    { label: 'Current PnL (%)', key: 'percentPnL' },
  ];

const Cumulative = () => {
    const {state, dispatch} = useContext(Context);
    const { user } = useContext(AuthContext);
    console.log('Cumulative Page Render');
    return (
        <div className='cumulative container-xl'>
        </div>
    );
};

export default React.memo(Cumulative);

// Hesabım sayfası yapılacak. Orada tw base link alanı olur. Ayrıca belki total bakiye manuel eklenip wallet'ta hesaplara dahil edilebilir.
// firebase kotası fena o yuzden symbolleri state'e ekleyip ordan okuycaz.
