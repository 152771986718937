import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from './store';
import {AuthProvider} from './contexts/AuthContext';
import { createRoot } from 'react-dom/client';

/*
ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>    
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
*/
const Root = () => {
  return (
      <Provider>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>    
        </AuthProvider>
      </Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);
