import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Input } from 'antd';
import {auth} from '../../firebase-config';
import {signInWithPopup, GoogleAuthProvider} from 'firebase/auth';
import Logo from '../../components/Logo';
import './style.scss';
import { setCookie } from '../../libs/utility';

const Authentication = () => {
    const {user, privateKey, googleLogin, setPrivateKey} = useContext(AuthContext);

    const onChangeHandler = (e) => {
        e.target.value !== "" && setPrivateKey(e.target.value);
        setCookie('privateKey', e.target.value, 7);
    }

    const signInWithGoogle = () => {
        googleLogin();
    }

    return (
        privateKey !== process.env.REACT_APP_PRIVATE_KEY ?
        <div className='private-key-screen'>
            <div className='enter-logo'>
                <Logo />
            </div>
            <p>Please Enter Private Key</p>
            <Input onChange={onChangeHandler} />
        </div>
        :
        <div className='google-auth-screen'>
            <Button size="large" onClick={signInWithGoogle}>Sign In with Google</Button>
        </div>
    );
};

export default Authentication;

// npm install -g firebase-tools

/*
Deploy to Firebase Hosting
You can deploy now or later. To deploy now, open a terminal window, then navigate to or create a root directory for your web app.

Sign in to Google
firebase login
Initiate your project
Run this command from your app’s root directory:

firebase init
When you’re ready, deploy your web app
Put your static files (e.g., HTML, CSS, JS) in your app’s deploy directory (the default is “public”). Then, run this command from your app’s root directory:

firebase deploy
After deploying, view your app at cryptoverse-55488.web.app

Need help? Check out the Hosting docs
*/