import { importCexOrder, importCexTypeOrder } from './enums';

export const createCandleStickSocketUrl = (type, symbolList, time = '1h') => {
    let baseUrl;
    if(type === "FUTURES") {
        baseUrl = process.env.REACT_APP_BINANCE_FUTURES_SOCKET_BASE_URL;
    }
    else {
        baseUrl = process.env.REACT_APP_BINANCE_SPOT_SOCKET_BASE_URL;
    }

    const mergeSymbolQuery = symbolList.reduce((prevVal, currVal, i) => {
        return i !== 0 ? `${prevVal}/${currVal.symbol.toLowerCase()}@kline_${time}` : `${currVal.symbol.toLowerCase()}@kline_${time}`;
    }, '');

    return `${baseUrl}stream?streams=${mergeSymbolQuery}`;
    //return `${baseUrl}?streams=btcusdt@kline_1m/avaxusdt@kline_1m/solusdt@kline_1m`;
}

export const allMarketMarkPriceStreamUrl = (time) => {
    let baseUrl = process.env.REACT_APP_BINANCE_FUTURES_SOCKET_BASE_URL;
    let refreshTime = time ? `@${time}` : '';
    return `${baseUrl}ws/!markPrice@arr${refreshTime}`;
}

export const createCurrencyPriceSocketUrl = (type, symbol) => {
    let baseUrl;
    if(type === "FUTURES") {
        baseUrl = process.env.REACT_APP_BINANCE_FUTURES_SOCKET_BASE_URL;
        return `${baseUrl}ws/${symbol.toLowerCase()}@markPrice@1s`;
    }
    else {
        baseUrl = process.env.REACT_APP_BINANCE_SPOT_SOCKET_BASE_URL;
        return `${baseUrl}ws/${symbol.toLowerCase()}@ticker`;
    }
    
    //return `${baseUrl}ws/${symbol.toLowerCase()}@markPrice@1s`;
    //return `${baseUrl}?streams=btcusdt@kline_1m/avaxusdt@kline_1m/solusdt@kline_1m`;
}

export const convertCandlestickData = (candlestick) => {
    let modifiedCandlesticks = candlestick.map(candle => {
        let tick = candle[4].split('.');
        let modifiedCandlestick = {
            openTime: candle[0],
            open: parseFloat(candle[1]),
            high: parseFloat(candle[2]),
            low: parseFloat(candle[3]),
            close: parseFloat(candle[4]),
            vol: candle[5],
            closeTime: candle[6],
            color: candle[4] >= candle[1] ? 'green' : 'red',
            change: calculatePercentage(candle[4], candle[1]),
            amplitude: calculatePercentage(candle[2], candle[3]),
            localOpenTime: new Date(candle[0]).toLocaleString(),
            tick: tick[1] ? tick[1].length : 0
        }
        return {candlestick: modifiedCandlestick};
    });


    return modifiedCandlesticks;
}

export const convertSocketMessageToCandleStick = (data) => {
    let tick = data.k.c.split('.');
    let modifiedCandlestick = {
      openTime: data.k.t,
      open: parseFloat(data.k.o),
      high: parseFloat(data.k.h),
      low: parseFloat(data.k.l),
      close: parseFloat(data.k.c),
      vol: data.k.v,
      closeTime: data.k.T,
      color: data.k.c >= data.k.o ? 'green' : 'red',
      change: calculatePercentage(data.k.c, data.k.o),
      amplitude: calculatePercentage(data.k.h, data.k.l),
      localOpenTime: new Date(data.k.t).toLocaleString(),
      tick: tick[1] ? tick[1].length : 0
  }

  return {candlestick: modifiedCandlestick};
}
/*
export const convertSocketMessageToCandleStick = (data) => {
    const newArray = [
        data.k.t, 
        data.k.o, 
        data.k.h, 
        data.k.l, 
        data.k.c, 
        data.k.v, 
        data.k.T, 
        data.k.q,
        data.k.n,
        data.k.V,
        data.k.Q,
        data.k.B
      ];

    return newArray;
}*/

export const calculatePercentage = (firstPrice, secondPrice) => {
    //console.log('calculatePercentage inside', kline);
    return Number(((firstPrice - secondPrice) * 100 / secondPrice).toFixed(2));
}

export const epochToDate = (epoch) => {
    if (epoch < 10000000000)
        epoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
    const newEpoch = epoch + (new Date().getTimezoneOffset() * -1); //for timeZone        
    //return new Date(newEpoch);
    return new Date(epoch);

}

export const getPassingTime = (openDate, closeDate) => {
    //const diffHours = Math.floor(Math.abs(openDate - closeDate) / (1000 * 60 * 60) % 24);
    //let diffMinutes = Math.ceil(Math.abs(openDate - closeDate) / (1000 * 60) % 60);

    let hours = Math.floor(Math.abs(openDate - closeDate) / 36e5); //36e5 = 60*60*1000
    let minutes = Math.floor(((Math.abs(openDate - closeDate) / 1000) / 60) % 60);

    return `${hours}h, ${minutes}m`;
}

export const getUSDollar = (price, digit) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: digit,
    });

    return USDollar.format(price);
}

export const groupBy = (xs, key, subKey) => {
    return xs.reduce(function(rv, x) {
      if(subKey) {
        (rv[x[key][subKey]] = rv[x[key][subKey]] || []).push(x);
      } else {
        (rv[x[key]] = rv[x[key]] || []).push(x); 
      }
      return rv;
    }, {});
};

export const setCookie = (cookieName, cookieValue, exdays = 30) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
}

export const getCookie = (cookieName) => {
    let pattern = RegExp(cookieName + '=.[^;]*');
    let matched = document.cookie.match(pattern);
    if (matched) {
        let cookie = matched[0].split('=');
        return cookie[1];
    }
    return false;
}

export const getSybolsListDiffrence = (tvList, firebaseList) => {
    // list.sort((a, b) => ('' + a.baseAsset).localeCompare(b.baseAsset))

    let tvListStringArray = tvList.replace(/BINANCE:/g, '').split(',');

    let firebaseListStringArray = firebaseList.map(x => x.symbol);

    return tvListStringArray.filter(x => !firebaseListStringArray.includes(x));
}

export const sortByCexObject = importCexOrder
.reduce((obj, item, index) => {
  return {
    ...obj,
    [item]: index,
  };
}, {});

export const sortByCexTypeObject = importCexTypeOrder
.reduce((obj, item, index) => {
  return {
    ...obj,
    [item]: index,
  };
}, {});


//ws = new WebSocket("wss://fstream.binance.com/stream?streams=btcusdt@kline_1m/solusdt@kline_1m/atomusdt@kline_1m/avaxusdt@kline_1m/wavesusdt@kline_1m/arpausdt@kline_1m/hntusdt@kline_1m/galausdt@kline_1m/peopleusdt@kline_1m/duskusdt@kline_1m");
