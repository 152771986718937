import React, { useContext, useState, useEffect } from 'react';
import SpotPositionBox from '../../components/SpotPositionBox';
import {Context} from '../../store';
import {Row, Col, Input, Button} from 'antd';
import './style.scss';
import { cryptoverseService } from '../../services';
import AddSpotProcessModal from '../../components/AddSpotProcessModal';
import ProcessHistoryModal from '../../components/ProcessHistoryModal';

const SpotBalance = () => {
    const {state} = useContext(Context);
    const [searchKey, setSearchKey] = useState("");
    const [balance, setBalance] = useState([]);
    const [totalPnL, setTotalPnL] = useState(0);
    const [activeBalance, setActiveBalance] = useState(0);
    const [toggleProcessModal, setToggleProcessModal] = useState(false);
    const [toggleHistoryModal, setToggleHistoryModal] = useState(false);
    const [symbolList, setSymbolList] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState(null);

    useEffect(() => {
        cryptoverseService.getBalance(1)
            .then(res => {
                console.log(res);
                setBalance(res.sort((a, b) => b.quantity - a.quantity));
            })
            .catch(err => {
                console.log(err);
            });

        cryptoverseService.gelAllSymbols()
            .then(res => {
                setSymbolList(res);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        if(balance.length > 0) {
            let totalPnl = balance.reduce((total, item) => total + Number(item.totalPnL), 0);
            let actBalance = balance.reduce((total, item) => total + (item.averageCost * item.quantity), 0);
            setTotalPnL(totalPnl.toFixed(4));
            setActiveBalance(actBalance.toFixed(4));
        }
    }, [balance]);

    const refreshData = () => {
        cryptoverseService.getBalance(1)
        .then(res => {
            console.log(res);
            setBalance(res.sort((a, b) => b.quantity - a.quantity));
        })
        .catch(err => {
            console.log(err);
        });     
    }

    const toggleModal = (hasSelected) => {
        console.log('toggleModal', hasSelected);
        if(hasSelected) {
            let symbol = symbolList.find(s => s.id === hasSelected);
            setSelectedSymbol(symbol);
        }
        else {
            setSelectedSymbol(null);
        }
        setToggleProcessModal(!toggleProcessModal);
    }

    const toggleHistoryProcessModal = (hasSelected) => {
        console.log('toggleHistoryModal', hasSelected);
        if(hasSelected) {
            let symbol = symbolList.find(s => s.id === hasSelected);
            setSelectedSymbol(symbol);
        }
        else {
            setSelectedSymbol(null);
        }
        setToggleHistoryModal(!toggleHistoryModal);
    }

    let getBalances = balance.map((coin, i) => {
        return (
            <SpotPositionBox coin={coin} key={i} toggleModal={toggleModal} toggleHistoryModal={toggleHistoryProcessModal} />
        );
    });

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    }
    
    return (
        <div className='futures container-xl'>
            <Row gutter={16}>
                <Col span="8">
                    <span>Total PNL: {totalPnL}</span>
                    <Button type={'primary'} onClick={() => toggleModal(null)}>Add New Process</Button>
                </Col>
                <Col span="8">
                    <p>SPOT BALANCE</p>
                </Col>
                <Col span="8">
                    <span>Active Balance: {activeBalance} $</span>
                </Col>
                {getBalances}
            </Row>
            {toggleProcessModal && <AddSpotProcessModal showModal={toggleProcessModal} toggleModal={toggleModal} symbols={symbolList} selectedSymbol={selectedSymbol} refresh={refreshData} />}
            {toggleHistoryModal && <ProcessHistoryModal showModal={toggleHistoryModal} toggleModal={toggleHistoryProcessModal} selectedSymbol={selectedSymbol} />}
        </div>
    );
};

export default React.memo(SpotBalance);