import React from 'react';
import {Link} from 'react-router-dom';
import './style.scss';

const Logo = () => {
    return (
        <div className='logo'>
            <Link to="/">
                <img src={process.env.PUBLIC_URL + '/images/cryptoloque-main.svg'} alt="" />
            </Link>
        </div>
    );
};

export default Logo;