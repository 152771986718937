import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, Input, Button, Row, Col, Radio, Select, message, Form} from 'antd';
import { Context } from '../../store';
import { cryptoverseService } from '../../services';
import './style.scss';
import { firebaseService } from '../../services';
import { AuthContext } from '../../contexts/AuthContext';
import { cexes, accountEnum, accountType } from '../../libs/enums';

const type = [
    {
        label: 'Spot',
        value: 'SPOT',
    },
    {
        label: 'Futures',
        value: 'FUTURES',
    },
    {
        label: 'Both',
        value: 'BOTH',
    }
];

const AddNewSymbolModal = (props) => {
    const [form] = Form.useForm();
    const { user } = useContext(AuthContext);
    //console.log('gelen selected', props.selectedSymbol);
    const {state, dispatch} = useContext(Context);

    const [symbol, setSymbol] = useState('');
    const [coinName, setCoinName] = useState('');
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [selectedType, setSelectedType] = useState(null);



    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedMarket(value);
    };
    const handleTypeChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedType(value);
    };

    useEffect(() => {
        let defaultMarket = cexes?.find(c => c.name === "Binance").name;
        let defaultType = type?.find(c => c.label === "Both").value;
        form.setFieldValue('market', defaultMarket);
        form.setFieldValue('type', defaultType);
        setSelectedMarket(defaultMarket);
        setSelectedType(defaultType);
    }, []);

    const saveNewSymbol = async () => {
        console.log('user', symbol);

        let newSymbol = {
            baseAsset: symbol.toUpperCase(),
            iconUrl: `${symbol.toLocaleLowerCase()}.svg`,
            market: selectedMarket,
            name: coinName,
            official: "",
            pricePrecision: 2,
            quantityPrecision: 0,
            quoteAsset: "USDT",
            symbol: `${symbol.toUpperCase()}USDT`,
            type: selectedType
        }
        console.log('new symbol', newSymbol);

        const addedSymbol = await firebaseService.addSymbol(newSymbol);
        
        if(addedSymbol) {
            newSymbol.id = addedSymbol;
            console.log('added symbol', addedSymbol);    
            props.toggleModal();
        }
        return addedSymbol;
    }

    return (
        <>
            <Modal title="Add New Symbol" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={300} height={800}>
                <div className="add-new-process-modal">
                    <Form form={form} layout="vertical" onFinish={saveNewSymbol}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Enter Symbol:" name="coinSymbol" rules={[
                                {
                                    required: true,
                                    message: 'Please enter symbol!',
                                },
                            ]}>
                                <Input placeholder='Enter symbol.' 
                                    value={symbol} onChange={e => setSymbol(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Enter Coin Name:" name="coinName" rules={[
                                {
                                    required: true,
                                    message: 'Please enter coin nane!',
                                },
                            ]}>
                                <Input placeholder='Enter coin name.' 
                                    value={coinName} onChange={e => setCoinName(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="select-symbol">
                            <Form.Item
                                label="Select Market:"
                                name="market"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select market!',
                                },
                                ]}
                            >
                                <Select 
                                    showSearch 
                                    placeholder="Search to Select" 
                                    optionFilterProp="children" 
                                    filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())} 
                                    style={{ width: '100%'}} 
                                    onChange={handleChange}
                                    name="market"
                                    options={
                                    cexes?.map(s => {
                                        return {value: s.name, label: s.name }
                                    })}
                                    ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="select-symbol">
                            <Form.Item
                                label="Select Type:"
                                name="type"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select type!',
                                },
                                ]}
                            >
                                <Select 
                                    showSearch 
                                    placeholder="Search to Select" 
                                    optionFilterProp="children" 
                                    filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())} 
                                    style={{ width: '100%'}} 
                                    onChange={handleTypeChange}
                                    name="type"
                                    options={
                                    type?.map(s => {
                                        return {value: s.value, label: s.label }
                                    })}
                                    ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Add Symbol</Button>
                        </Col>
                    </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddNewSymbolModal;

/*
        let newSymbol = {
            baseAsset: "ARKM",
            iconUrl: "arkm.svg",
            market: "Binance",
            name: "Arkham",
            official: "",
            pricePrecision: 2,
            quantityPrecision: 0,
            quoteAsset: "USDT",
            symbol: "ARKMUSDT",
            type: "BOTH"
        }
*/