import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import './style.scss';

const TradingViewWidgetEmbed = ({currency, marketType}) => {
  console.log('Trading View Widget', currency?.symbol);

    return (<>
      {currency?.symbol ? 
        <TradingViewWidget
        symbol={`BINANCE:${currency.symbol}PERP`}
        interval="15"
        timezone="Europe/Istanbul"
        hide_side_toolbar={false}
        allow_symbol_change={false}
        withdateranges={true}
        theme={Themes.Light}
        locale="tr"
        autosize
        show_popup_button={true}
        popup_width="1000"
        popup_height="650"
        studies={[
            "BB@tv-basicstudies",
            "MAExp@tv-basicstudies",
            "RSI@tv-basicstudies"
        ]}
    /> : ''}
    </>
    );
};

export default React.memo(TradingViewWidgetEmbed);