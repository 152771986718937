import React, { useEffect, useState } from 'react';
import './style.scss';
import {Row, Col, Button, InputNumber, Switch, Checkbox, Modal, Form} from 'antd';
import { SwapOutlined, EditOutlined } from '@ant-design/icons';
import { getUSDollar } from '../../libs/utility';
import { accountType, accountEnum } from '../../libs/enums';
import { firebaseService } from '../../services';

const AccountBox = ({account, toggleModal, updatedBalance}) => {
    const [form] = Form.useForm();
    const [pastPnLToggleModal, setPastPnLToggleModal] = useState(false);
    const [currentPnLToggleModal, setCurrentPnLToggleModal] = useState(false);
    const [pastPnL, setPastPnL] = useState(0);
    const [editCurrentPnL, setEditCurrentPnL] = useState(0);
    console.log('account box', account);

    const mainBalance = account.firstBalance + account.transfer;
    const currentPnL = account.currentPnL;
    const currentBalance = mainBalance + currentPnL;

    const addNewTransfer = () => {
        toggleModal(account);
    }

    const updatePastPnL = async () => {
        let updatedAccount = {...account};
        let currentPnL = updatedAccount.currentPnL + pastPnL;
        updatedAccount.pastPnL = pastPnL;
        updatedAccount.currentPnL = currentPnL;

        updatedAccount.passiveBalance = updatedAccount.firstBalance + updatedAccount.transfer + currentPnL - updatedAccount.activeBalance;
        console.log('updatedAccount',updatedAccount);
        await firebaseService.updateAccount(updatedAccount);
        updatedBalance();
        setPastPnLToggleModal(false);
    }

    const updateCurrentPnL = async () => {
        console.log('current pnl', editCurrentPnL);
        let updatedAccount = {...account};
        let currentPnL = updatedAccount.currentPnL + editCurrentPnL;
        updatedAccount.currentPnL = currentPnL;
        updatedAccount.passiveBalance = updatedAccount.firstBalance + updatedAccount.transfer + currentPnL - updatedAccount.activeBalance;
        await firebaseService.updateAccount(updatedAccount);
        updatedBalance();
        setCurrentPnLToggleModal(false);
    }

    const pastPnLModal = () => {
        return (
            <Modal title="Edit Past PnL" open={pastPnLToggleModal} footer={null} onCancel={() => setPastPnLToggleModal(false)} width={300} height={800}>
            <div className="past-pnl-modal">
                <Form form={form} layout="vertical" onFinish={updatePastPnL}>
                <Row gutter={16}>
                    <Col span={24}>
                        <p>{account.cexName} - {account.type} hesabın için geçmiş PnL miktarını ekleyebilirsin.</p>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Enter Past PnL:" name="pastPnL" rules={[
                            {
                                required: true,
                                message: 'Please enter past PnL!',
                            },
                        ]}>
                            <InputNumber placeholder='Enter past PnL.'
                                value={pastPnL} 
                                onChange={value => setPastPnL(value)}
                                formatter={(value) => `$ ${value}`}
                                parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Save</Button>
                    </Col>
                </Row>
                </Form>
            </div>
        </Modal>
        );
    }

    const currentPnLModal = () => {
        return (
            <Modal title="Edit Current PnL" open={currentPnLToggleModal} footer={null} onCancel={() => setCurrentPnLToggleModal(false)} width={300} height={800}>
            <div className="past-pnl-modal">
                <Form form={form} layout="vertical" onFinish={updateCurrentPnL}>
                <Row gutter={16}>
                    <Col span={24}>
                        <p>{account.cexName} - {account.type} hesabın için güncel PnL miktarını ekleyebilirsin. <br />Girilen değer mevcut PnL'e eklenecektir.</p>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Enter Current PnL:" name="currentPnL" rules={[
                            {
                                required: true,
                                message: 'Please enter current PnL!',
                            },
                        ]}>
                            <InputNumber placeholder='Enter current PnL.'
                                value={editCurrentPnL} 
                                onChange={value => setEditCurrentPnL(value)}
                                formatter={(value) => `$ ${value}`}
                                parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Save</Button>
                    </Col>
                </Row>
                </Form>
            </div>
        </Modal>
        );
    }

    return (
        <div className="account-box">
            <div className='header'>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${account.cexName.replace('.', '').replace(' ', '').toLowerCase()}.svg`} alt="" />
                <p>{account.cexName.toUpperCase()}</p>
                <span>{account.type}</span>
                <div className='main-balance'>
                    <span>Main Balance</span>
                    <span>{getUSDollar(mainBalance)}</span>
                </div>
            </div>
            <div className='body'>
                <div className='price-box'>
                <Row gutter={16}>
                        <Col xs={8} md={8}>
                            <div className="label">Incoming</div>
                            <div className="value">{getUSDollar(account.firstBalance)}</div>
                        </Col>
                        <Col xs={8} md={8}>
                            <div className="label">Transfer <SwapOutlined className='icon' onClick={addNewTransfer} /></div>
                            <div className="value">{getUSDollar(account.transfer)}</div>
                        </Col>
                        <Col xs={8} md={8}>
                            <div className="label">Past PnL {account.pastPnL === 0 && <EditOutlined className='icon' onClick={() => setPastPnLToggleModal(true)} />}</div>
                            <div className={`value ${account.pastPnL >= 0 ? "green" : "red"} c-value`}>{getUSDollar(account.pastPnL)}</div>
                        </Col>
                    </Row>
                </div>
                <div className='price-box s2'>
                    <Row gutter={16}>
                        <Col xs={12} md={12}>
                            <div className="label">Current Balance</div>
                            <div className="value">{getUSDollar(currentBalance)}</div>
                        </Col>
                        <Col xs={12} md={12}>
                            <div className="label c-label">Realized PnL {account.type !== accountEnum.SPOT && <EditOutlined className='icon' onClick={() => setCurrentPnLToggleModal(true)} />}</div>
                            <div className={`value ${currentPnL >= 0 ? "green" : "red"} c-value`}>{getUSDollar(currentPnL)} ({currentPnL ? Number(((currentBalance - mainBalance) * 100) / mainBalance).toFixed(2) : 0}%)</div>
                        </Col>
                    </Row>
                </div>
                <div className='price-box s2'>
                    <Row gutter={16}>
                        <Col xs={12} md={12}>
                            <div className="label">Active Balance</div>
                            <div className="value">{getUSDollar(account.activeBalance)} ({account.activeBalance ? Number((account.activeBalance * 100) / currentBalance).toFixed(2) : 0}%)</div>
                        </Col>
                        <Col xs={12} md={12}>
                            <div className="label c-label">Passive Balance</div>
                            <div className={`value`}>{getUSDollar(account.passiveBalance)} ({account.passiveBalance ? Number((account.passiveBalance * 100) / currentBalance).toFixed(2) : 0}%)</div>
                        </Col>
                    </Row>
                </div>
            </div>
            {pastPnLToggleModal && pastPnLModal()}
            {currentPnLToggleModal && currentPnLModal()}
        </div>
    );
};

export default React.memo(AccountBox);