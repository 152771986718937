import React, { useEffect, useContext } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Context } from '../../store';
import { CalculatorOutlined } from '@ant-design/icons';
import './style.scss';
import {analysisModel} from '../../libs/enums';
import {Button} from 'antd';
import { Drawer} from 'antd';

const nav = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Analysis",
        path: '/analysis'
    },
    {
        name: "Currencies",
        path: "/currencies"
    },
    {
        name: "All PNL",
        path: "/all-pnl"
    },
    {
        name: "Spot Wallets",
        path: "/wallet"
    },
    {
        name: "Cumulative",
        path: "/cumulative"
    },
    {
        name: "Accounts",
        path: "/accounts"
    }, 
];

const MobileDrawer = ({onClose, open}) => {
    const location = useLocation();
    const {state, dispatch} = useContext(Context);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const navigation = nav.map((navItem, i) => {
        let className = location.pathname === navItem.path ? "active": "";
        return <li key={i} className={className}>
        <Link to={navItem.path} onClick={onClose}>{navItem.name}</Link>
      </li>
    });

    return (
        <Drawer title="Basic Drawer" placement="left" onClose={onClose} open={open} getContainer={false} width={'100%'}>
            <ul>
                {navigation}        
            </ul>
        </Drawer>
    );
};

export default MobileDrawer;