import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, InputNumber, Button, Row, Col, Radio, Select} from 'antd';
import { Context } from '../../store';
import { cryptoverseService } from '../../services';
import './style.scss';

const processSide = [
    {
        label: 'Buy',
        value: 'Buy',
    },
    {
        label: 'Sell',
        value: 'Sell',
    }
];

const AddSpotProcessModal = (props) => {
    console.log('gelen selected', props.selectedSymbol);
    const {state, dispatch} = useContext(Context);
    const [side, setSide] = useState("Buy");
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedSymbol(value);
    };

    useEffect(() => {
        props.selectedSymbol && setSelectedSymbol(props.selectedSymbol.id);
    }, []);

    const saveNewProcess = () => {
        let newProcess = {
            userId: 1,
            symbolId: selectedSymbol,
            side: side,
            unitPrice: unitPrice,
            quantity: quantity,
            date: Date.now(),
            realizedPnL: 0
        }
        if(selectedSymbol && side && unitPrice && quantity) {
            cryptoverseService.addNewProcess(newProcess)
            .then(res => {
                console.log(newProcess);
                props.toggleModal(null);
                props.refresh();
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    return (
        <>
            <Modal title="Add New Process" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={300} height={800}>
                <div className="add-new-process-modal">
                    <Row gutter={16}>
                        <Col span={24} className="select-symbol">
                            <span className='form-label'>Select Symbol:</span>
                            <Select 
                                showSearch 
                                value={selectedSymbol}
                                placeholder="Search to Select" 
                                optionFilterProp="children" 
                                filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())} 
                                style={{ width: '100%'}} 
                                onChange={handleChange}
                                options={
                                props.symbols?.map(s => {
                                    return {value: s.id, label: s.symbol }
                                })
                            }></Select>
                        </Col>
                        <Col span={24} className="select-side">
                            <span className='form-label'>Select Side:</span>
                            <Radio.Group options={processSide} onChange={({target: {value}}) => {setSide(value)}} value={side} optionType="button" buttonStyle="solid" />
                        </Col>
                        <Col span={24}>
                            <span className='form-label'>Enter Quantity:</span>
                            <InputNumber placeholder='Enter quantity.' name="quantity" value={quantity} onChange={value => setQuantity(value)} />
                        </Col>
                        <Col span={24}>
                            <span className='form-label'>Enter Unit Price:</span>
                            <InputNumber placeholder='Enter unit price.' name="unitPrice" value={unitPrice} onChange={value => setUnitPrice(value)} />
                        </Col>
                        <Col span={24}>
                            <Button type="primary" block size="large" onClick={saveNewProcess}>Add Process</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default AddSpotProcessModal;