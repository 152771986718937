import { getRequestApi } from "../libs/request";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase-config";
const headers = {
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": 'application/json',
}

const getTickerInfo = async (symbol, interval) => {
    let url = `${process.env.REACT_APP_GATE_SPOT_API_BASE_URL}/spot/tickers`;
    if(symbol) {
        url = url + `?curreny_pair=${symbol}`; 
    }
    return getRequestApi(url, {}, headers);
}


const getTickerPrice = async (symbols) => {
    const firebaseProxy = httpsCallable(functions, 'firebaseProxyOnCall');

    let url = process.env.NODE_ENV === 'development' ? `/api/v4/spot/tickers` : `${process.env.REACT_APP_MEXC_SPOT_API_BASE_URL}/spot/tickers`;
    let result = await new Promise(async (resolve, reject) => {
        if(process.env.NODE_ENV === 'development') {
            let localResult = await getRequestApi(url, {}, headers);
            resolve(localResult);
        } else {
            let proxyResult = await firebaseProxy({url: 'https://api.gateio.ws/api/v4/spot/tickers'});
            resolve(proxyResult.data);
        }
    });

    let currentPrices = symbols.map(s => {
        let findSymbol = result.find(r => r.currency_pair.replace('_', '') === s);
        let convertedSymbol = {
            symbol: findSymbol.currency_pair.replace('_', ''),
            price: findSymbol.last
        }
        return convertedSymbol;
    });

    return currentPrices;
}

export const gateService = {
    getTickerInfo,
    getTickerPrice
};
