import React, {useContext} from 'react';
import { Context } from '../../store';
import {Link} from 'react-router-dom';
import {Row, Col, Tabs, Radio, Button, DatePicker, Checkbox} from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import './style.scss';

const SupportResistanceBox = ({sr}) => {
    const {state, dispatch} = useContext(Context);

    const imageName = state.symbols.find(x => x.symbol === sr.symbol)?.baseAsset?.toLowerCase();

    return (
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
            <div className='sr-deals'>
                <div className='head'>
                <Link to={`/futures-currencies/${sr.symbol.replace('USDT', '').toLowerCase()}`}>
                    {<img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}.svg`} alt="" />}
                        <span><strong>{sr.symbol}</strong></span>
                    </Link>
                    <a href={`https://tr.tradingview.com/chart/?symbol=${sr.symbol}PERP`} title="" target="_blank" rel="noopener noreferrer">
                        <img src="/images/tw-logo.svg" className='tw' alt="" />
                        <DoubleRightOutlined style={{ fontSize: '14px', color: '#2962ff' }} />
                    </a>
                </div>
                <div className='lines'>
                    <img src={`/images/sr/${sr.image}.svg`} alt="" />
                    <div className="line-text">
                        <span className="bold">Line: {sr.line}</span>
                        <span className="bold">Strength: {sr.strength}</span>
                        <span>Prev Line: {sr.prevLine}</span>
                        <span>Next Line: {sr.nextLine}</span>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default SupportResistanceBox;

/*
<img src={`${process.env.PUBLIC_URL}/images/sp/redzero.svg`} alt="" />


                    <Col xs={12} sm={8} md={6} lg={6} xl={col} key={deal.key}>
                    <div className="other-deal-symbol">
                        <Link to={`/futures-currencies/${deal.symbol.replace('USDT', '').toLowerCase()}`}>
                            {<img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}.svg`} alt="" />}
                            <span><strong>{deal.symbol}</strong></span>
                            {deal.rsi && <span className="rsi">[ {deal.rsi} ]</span>}
                            <img src={`${process.env.PUBLIC_URL}/images/sp/redzero.svg`} alt="" />
                        </Link>
                    </div>
                </Col>


<a href={currency.official} title="" target="_blank" rel="noopener noreferrer">Official Site</a>


<div class="ant-col ant-col-xs-12 ant-col-sm-8 ant-col-md-6 ant-col-lg-6 ant-col-xl-3" style="padding-left: 8px; padding-right: 8px;"><div class="other-deal-symbol"><a href="/futures-currencies/ar"><img src="/images/crypto-logo/ar.svg" alt=""><span><strong>ARUSDT</strong></span></a>

<div class="lines">
<img src="/images/sp/redzero.svg" alt="" style="height: 70px;">
<div class="line-text">
    <span class="mark">Line: 5.56765</span>
    <span class="mark">Strength: 2</span>
    <span>Prev Line: 4.54567</span>
    <span>Next Line: 6.67890</span>
</div>
</div></div></div>
*/