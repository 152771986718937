import React, {useState, useContext, useEffect} from 'react';
import {Modal, Table, Button, Row, Col, Tag} from 'antd';
import {epochToDate} from '../../libs/utility';
import { firebaseService } from '../../services';
import { AuthContext } from '../../contexts/AuthContext';
import { getUSDollar } from '../../libs/utility';
import { DeleteFilled, ExclamationCircleFilled } from '@ant-design/icons';

import './style.scss';

const { confirm } = Modal;

const TransferHistoryModal = (props) => {
    const { user } = useContext(AuthContext);
    //console.log('gelen selected history', props.selectedSymbol);
    const [transferData, setTransferData] = useState([]);

    const columns = [
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            width: 100,
            render: date => epochToDate(date).toLocaleDateString()
        },
        {
          title: 'From',
          dataIndex: 'fromName',
          key: 'fromName',
          align: 'center',
          render: fromName => `${fromName}`
        },
        {
            title: 'To',
            dataIndex: 'toName',
            key: 'toName',
            align: 'center',
            render: toName => `${toName}`
          },
        {
            title: 'Amount',
            dataIndex: 'transferAmount',
            key: 'transferAmount',
            align: 'center',
            width:100,
            render: transferAmount => `${getUSDollar(transferAmount)}`
        }
    ];

    useEffect(() => {
        firebaseService.getTransfers(user.uid)
            .then(res => {
                //console.log('history', res);
                setTransferData(res.sort((a, b) => a.date - b.date));
            })
    }, []);

    return (
        <>
            <Modal title="Transfer History" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={800} height={800}>
                <div className="transfer-history-modal">
                    {
                        <div className='history-info'>
                            <Row gutter={0}>
                            </Row>  
                        </div>
                    }

                    <Table columns={columns} dataSource={transferData} bordered pagination={false} scroll={{y: 278,}}/>
                </div>
            </Modal>
        </>
    );
};

export default TransferHistoryModal;