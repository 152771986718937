import React, {useContext} from 'react';
import './style.scss';
import {Table, Tag} from 'antd';
import {epochToDate} from '../../libs/utility';
import {Context} from '../../store';
import { UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CurrencyHistoricalTable = ({data}) => {
    const {state} = useContext(Context);

    const columns = [
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => tagBuilder(text, record),
          filters: [
              {
                  text: 'LONG',
                  value: 'OPEN LONG'
              },
              {
                  text: 'SHORT',
                  value: 'OPEN SHORT'
              }
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: 'Pos Type',
            dataIndex: 'positionType',
            key: 'positionType',
            render: (text, record) => posBuilder(record),
        },
        {
            title: 'Strength',
            dataIndex: 'point',
            key: 'point',
            className: 'deal-strength',
            render: (text, record) => strengthProgressBar(text),
        },
        {
          title: 'Open Price',
          dataIndex: 'openPrice',
          key: 'openPrice',
          render: price => `$ ${price}`
        },
        {
            title: 'Close Price',
            dataIndex: 'closePrice',
            key: 'closePrice',
            render: price => `$ ${price}`
          },
        {
          title: 'Open Date',
          key: 'openDate',
          dataIndex: 'openDate',
          render: date => epochToDate(date).toLocaleString()
        },
        {
            title: 'Close Date',
            key: 'closeDate',
            dataIndex: 'closeDate',
            render: date => epochToDate(date).toLocaleString()
          },
        {
            title: 'Pass Time',
            key: 'time',
            dataIndex: 'time',
            render: (time, record) => `${time}`,
            sorter: (a, b) => a.time - b.time,
        },
        {
          title: 'Reverse (C)',
          key: 'closeReverse',
          dataIndex: 'closeReverse',
          render: (closeReverse, record) => reverseBuilder(closeReverse, record.closeReverseIndex),
          sorter: (a, b) => a.closeReverse - b.closeReverse,
        },
        {
            title: 'Reverse (M)',
            key: 'maxReverse',
            dataIndex: 'maxReverse',
            render: (maxReverse, record) => reverseBuilder(maxReverse, record.maxReverseIndex),
            sorter: (a, b) => a.maxReverse - b.maxReverse,
        },
        {
            title: 'First Reverse',
            key: 'firstReverse',
            dataIndex: 'firstReverse',
            render: (firstReverse, record) => reverseBuilder(firstReverse, 1),
            sorter: (a, b) => a.firstReverse - b.firstReverse,
        },
        {
            title: 'Max Profit',
            key: 'highProfit',
            dataIndex: 'highProfit',
            render: (highProfit, record) => reverseBuilder(highProfit, record.highPriceIndex),
            sorter: (a, b) => a.highProfit - b.highProfit,
        },
        {
            title: 'Most Profit',
            key: 'mostProfit',
            dataIndex: 'mostProfit',
            render: (mostProfit, record) => reverseBuilder(mostProfit, record.topNeedleIndex),
            sorter: (a, b) => a.mostProfit - b.mostProfit,
        },
        {
            title: 'Profit/Loss',
            key: 'pnl',
            dataIndex: 'pnl',
            render: (pnl, record) => `${pnl} %`,
            sorter: (a, b) => a.pnl - b.pnl,
        }
      ];

    const strengthProgressBar = (value) => {
        let pathColor;
        switch (true) {
            case (value < 17):
                pathColor = '#ff0d0d';
                break;
            case (value >= 17 && value < 34):
                pathColor = '#ff4e11';
                break;
            case (value >= 34 && value < 50):
                pathColor = '#ff8e15';
                break;
            case (value >= 50 && value < 67):
                pathColor = '#fab733';
                break; 
            case (value >= 67 && value < 84):
                pathColor = '#acb334';
                break;
            case (value >= 84):
                pathColor = '#69b34c';
                break;
                default:
                break;
        }
        return <div style={{width:48, height:48}}>
            <CircularProgressbar 
                value={value} 
                text={`${value}%`} 
                strokeWidth={12}
                styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0.25,
                
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                
                    // Text size
                    textSize: '24px',
                
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                
                    // Colors
                    pathColor: pathColor,
                    textColor: '#333',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                  })} />
        </div>
    }

    const tagBuilder = (text, record) => {
        let color = null;
        let icon = null;
        switch (text) {
            case 'LONG':
                color = "#87d068";
                icon = <UpCircleOutlined />;
                break;
            case 'SHORT':
                color = "#f50";
                icon = <DownCircleOutlined />;
                break;
            case 'EMPTY':
                color = "#000";
                icon = <CloseCircleOutlined />;
                break;
            default:
                color = "black";
                break;
        }
        return <div className='deals-tag'>
                    <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag><br />
               </div>
    }

    const posBuilder = (record) => {
        return <div className="pos-type">
            <Tag color={"#87d068"}>{record.posType}</Tag>
            <Tag color={"#3b5081"}>{record.closeType}</Tag>
        </div>
    }
    
    const reverseBuilder = (reverse, index) => {
        return <div className="reverse">
            <span className="reverse-value">{reverse} %</span>
            {index > 0 && <span className="reverse-index">{index}</span>}
        </div>
    }

    const rowClassNameBuilder = (record, index) => {
        if(state.analysisOptions.model === "rsiDivergence" || state.analysisOptions.model === "TC") {
            if(record.pnl > 0) {
                return "soft-green";
            }
            else if (record.pnl === 0) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
        else if(!state.enabledSimulator && (state.analysisOptions.model !== "depthHill" && state.analysisOptions.model !== "explosionLoose")) {
            return record.mostProfit > 1 ? "soft-green" : "soft-red";
        }
        else {
            if(record.pnl > 0.1) {
                return "soft-green";
            }
            else if (record.pnl >= 0 && record.pnl <= 0.1) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
    }

    return (
        <div className='last-24-hours-deals-table'>
            <Table columns={columns} dataSource={data} bordered pagination={false} rowClassName={rowClassNameBuilder} />
        </div>
    );
};


export default CurrencyHistoricalTable;