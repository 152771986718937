import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, InputNumber, Button, Row, Col, Radio, Select} from 'antd';
import { cryptoverseService } from '../../services';
import {epochToDate} from '../../libs/utility';

import './style.scss';

const ProcessHistoryModal = (props) => {
    console.log('gelen selected history', props.selectedSymbol);
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [historicalData, setHistoricalData] = useState([]);

    const columns = [
        {
          title: 'Side',
          dataIndex: 'side',
          key: 'side',
          render: side => `${side}`
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: quantity => `${quantity}`
          },
        {
            title: 'Unit Price',
            key: 'unitPrice',
            dataIndex: 'unitPrice',
            render: unitPrice => `$ ${unitPrice}`,
        },
        {
            title: 'Total Cost',
            key: 'total',
            dataIndex: 'total',
            render: total => `$ ${total.toFixed(4)}`,
        },
        {
            title: 'Realized PnL',
            key: 'realizedPnL',
            dataIndex: 'realizedPnL',
            render: realizedPnL => `$ ${realizedPnL.toFixed(4)}`,
        },
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            render: date => epochToDate(date).toLocaleString()
        }
    ];


    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedSymbol(value);
    };

    useEffect(() => {
        props.selectedSymbol && setSelectedSymbol(props.selectedSymbol);
        cryptoverseService.getProcess(1, props.selectedSymbol.id)
            .then(res => {
                console.log('history', res);
                setHistoricalData(res);
            })

    }, []);

    return (
        <>
            <Modal title="Process History" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={800} height={800}>
                <div className="process-history-modal">
                    <Row gutter={16}>
                        <Col span={24} className="title">
                            <p>{props.selectedSymbol.symbol} Process History</p>
                        </Col>
                    </Row>
                    <Table columns={columns} dataSource={historicalData} bordered pagination={false} />
                </div>
            </Modal>
        </>
    );
};

export default ProcessHistoryModal;