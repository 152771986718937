import axios from './axios';

const getRequestApi = (url, data, options) => {
    return requestApi('GET', url, data, options);
}

const postRequestApi = (url, data, options) => {
    return requestApi('POST', url, data, options);
}

const putRequestApi = (url, data, options) => {
    return requestApi('PUT', url, data, options);
}

const deleteRequestApi = (url, data, options) => {
    return requestApi('DELETE', url, data, options);
}

const requestApi = (type, url, data, options) => {
    return new Promise((resolve, reject) => {
        axios({
            method: type,
            url: url,
            data: data,
            headers: options
        })
        .then(response => {
            if(response.status === 200 || response.status === 201) {
                resolve(response.data);
            }
        })
        .catch(error => {
            reject(error);
        });
    })
}

export {getRequestApi, postRequestApi, putRequestApi, deleteRequestApi};