import { getRequestApi, postRequestApi, putRequestApi, deleteRequestApi } from "../libs/request";

const headers = {
    //"X-MBX-APIKEY": process.env.REACT_APP_BINANCE_API_KEY,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
}

const buildUrl = (url) => {
    return `${process.env.REACT_APP_CRYPTOVERSE_API}/${url}`;
}
/* User Endpoints */
const createUser = async (user) => {
    let url = buildUrl('user');
    return postRequestApi(url, {user});
}

/* Symbol Endpoints */
const gelAllSymbols = async () => {
    let url = buildUrl('spot-symbol');
    return getRequestApi(url, {});
}

const createSymbol = async (symbol) => {
    let url = buildUrl('spot-symbol');
    return postRequestApi(url, symbol);
}

const updateSymbol = async (symbol) => {
    let url = buildUrl('spot-symbol');
    return putRequestApi(url, symbol);
}

const deleteSymbol = async (id) => {
    let url = buildUrl(`spot-symbol/${id}`);
    return deleteRequestApi(url, {});
}
/* Symbol Endpoints - End */

/* Process Endpoints */
const getProcess = async (userId, symbolId = null) => {
    let query = "";
    if(symbolId) {
        query = `&symbolId=${symbolId}`;
    }
    let url = buildUrl(`spot-process?userId=${userId}${query}`);
    return getRequestApi(url, {}, headers);
}

const addNewProcess = async (process) => {
    let url = buildUrl('spot-process');
    return postRequestApi(url, process);
}
/* Process Endpoints - End */

/* Balance Endpoints */
const getBalance = async (userId, symbolId = null) => {
    let query = "";
    if(symbolId) {
        query = `&symbolId=${symbolId}`;
    }
    let url = buildUrl(`spot-balance?userId=${userId}${query}`);
    return getRequestApi(url, {});
}
/* Balance Endpoints - End */


export const cryptoverseService = {
    createUser,
    gelAllSymbols,
    createSymbol,
    updateSymbol,
    deleteSymbol,
    getProcess,
    addNewProcess,
    getBalance
};