import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, InputNumber, Input, Button, Row, Col, Radio, Select, message, Form} from 'antd';
import { Context } from '../../store';
import { cryptoverseService } from '../../services';
import './style.scss';
import { firebaseService } from '../../services';
import { AuthContext } from '../../contexts/AuthContext';
import { cexes, accountEnum, accountType } from '../../libs/enums';
import { getUSDollar } from '../../libs/utility';

const AddTransferModal = (props) => {
    const [form] = Form.useForm();
    const { user } = useContext(AuthContext);
    //console.log('gelen selected', props.selectedSymbol);
    const {state, dispatch} = useContext(Context);

    const [cexList, setCexList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedCex, setSelectedCex] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [transferAmount, setTransferAmount] = useState(0);
    console.log('add transfer modal', props.selectedAccount);

    const handleCexChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedCex(value);
        setSelectedType(null);
        form.setFieldValue('accountType');
    };

    const handleTypeChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedType(value);
    };

    useEffect(() => {
        if (props.selectedAccount) {
            form.setFieldValue('mainAccount', props.selectedAccount.cexName + ' - ' + props.selectedAccount.type);

            if (props.selectedAccount.cexName === "Binance") {
                if (props.selectedAccount.type === accountEnum.SPOT) {
                    setCexList(cexes);
                } else {
                    setCexList(cexes.filter(c => c.name === 'Binance'));
                }
            } else {
                if (props.selectedAccount.type === accountEnum.SPOT) {
                    setCexList(cexes.filter(c => c.name === 'Binance' || c.name === props.selectedAccount.cexName));
                } else {
                    setCexList(cexes.filter(c => c.name === props.selectedAccount.cexName));
                }
            }
            setTypeList([]);
        }
    }, []);

    useEffect(() => {
        if(selectedCex) {
            let selectedCexObj = cexes.find(c => c.id === selectedCex);
            if (selectedCexObj.name === props.selectedAccount.cexName) {
                if(props.selectedAccount.type === accountEnum.SPOT) {
                    setTypeList(accountType.filter(t => t.value !== accountEnum.SPOT));
                } else {
                    setTypeList(accountType.filter(t => t.value === accountEnum.SPOT));
                }
            } else {
                setTypeList(accountType.filter(t => t.value === accountEnum.SPOT));
            }
        }
    }, [selectedCex, props.selectedAccount]);

    const addNewTransfer = async () => {
        console.log('user', user.uid);

        let transferRecord = {};

        let checkAccount = await firebaseService.getAccount(user.uid, selectedCex, selectedType);

        let transferredAccount = {...props.selectedAccount};
        transferredAccount.transfer = transferredAccount.transfer - transferAmount;
        transferredAccount.passiveBalance = transferredAccount.passiveBalance - transferAmount;

        console.log('check account', transferredAccount, checkAccount, selectedCex, selectedType);

        await firebaseService.updateAccount({...transferredAccount});
        transferRecord.fromName = transferredAccount.cexName + ' - ' + transferredAccount.type;
        console.log('transfer record from id', transferredAccount.id);
        transferRecord.fromId = transferredAccount.id;
        // olay buraya kadar gelebildiyse, transfer edilecek miktar transfere müsait miktardır.
        if (checkAccount.length) {
            // daha önce bu hesap varmış, güncellenecek.
            // hesap binance ve spot ise farklı davranılacak.
            let recipientAccount = {...checkAccount[0]};
            console.log('recipient Account', recipientAccount);
            if (recipientAccount.cexName === "Binance" && recipientAccount.type === accountEnum.SPOT) {
                recipientAccount.transfer = recipientAccount.transfer + transferAmount;
                recipientAccount.passiveBalance = recipientAccount.passiveBalance + transferAmount;

            } else {
                recipientAccount.firstBalance = recipientAccount.firstBalance + transferAmount;
                recipientAccount.passiveBalance = recipientAccount.passiveBalance + transferAmount;
            }

            await firebaseService.updateAccount({...recipientAccount});
            transferRecord.toName = recipientAccount.cexName + ' - ' + recipientAccount.type;
            transferRecord.toId = recipientAccount.id;
        } else {
            // hesap yok oluşturulacak.
            // varolmayan hesabın binance spot olma ihtimali yok. dümdüz yeni hesap ekle.
            let recipientCex = cexes.find(c => c.id === selectedCex);
            let newAccount = {
                activeBalance: 0,
                cexId: recipientCex.id,
                cexName: recipientCex.name,
                firstBalance : transferAmount,
                passiveBalance: transferAmount,
                pastPnL: 0,
                currentPnL: 0,
                transfer: 0,
                type: selectedType,
                userId: user.uid,
            };
            if (selectedType === accountEnum.SPOT) {
                let balances = await firebaseService.getBalances(user.uid);
                let filteredBalances = balances.filter(b => b.symbol.market === recipientCex.name);
                let totalPnl = filteredBalances.reduce((total, item) => total + Number(item.totalPnL), 0);
                let activeBalance = filteredBalances.reduce((total, item) => total + (item.averageCost * item.quantity), 0);
                // eklenen hesap farklı borsanın spot hesabı ise spot işlemler kontrol edilip gerekli kayıtlar yapılacak
                // currentPnL, activeBalance, passiveBalance hesapları.
                newAccount.activeBalance = activeBalance;
                newAccount.passiveBalance = transferAmount + totalPnl - activeBalance;
                newAccount.currentPnL = totalPnl;
            }
            let createdAccount = await firebaseService.addAccount(newAccount);
            transferRecord.toName = recipientCex.name + ' - ' + selectedType;
            transferRecord.toId = createdAccount;
            console.log('created account', createdAccount);
        }
        transferRecord.userId = user.uid;
        transferRecord.transferAmount = transferAmount;
        transferRecord.date = Date.now();
        transferRecord.exchangeRate = 0;

        console.log('transfer record', transferRecord);
        let makeTransfer = await firebaseService.addTransfer(transferRecord);
        console.log('make transfer', makeTransfer);
        props.toggleModal();
        props.updatedBalance();
    }

    return (
        <>
            <Modal title="Make a Transfer" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={300} height={800}>
                <div className="add-transfer-modal">
                    <Form form={form} layout="vertical" onFinish={addNewTransfer}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Main Account:" name="mainAccount">
                                <Input placeholder='Enter quantity.' 
                                    value={props.selectedAccount.cexName} onChange={() => {}}
                                    disabled={true}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={24} className="select-cex">
                            <Form.Item
                                label="Select Transfer Cex:"
                                name="cexId"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select transfer cex!',
                                },
                                ]}
                            >
                                <Select 
                                    showSearch 
                                    placeholder="Search to Select" 
                                    optionFilterProp="children" 
                                    filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())} 
                                    style={{ width: '100%'}} 
                                    onChange={handleCexChange}
                                    name="cexId"
                                    options={
                                    cexList.map(c => {
                                        return {value: c.id, label: c.name }
                                    })}
                                    ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="select-type">
                            <Form.Item
                                label="Select Transfer Account Type:"
                                name="accountType"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select transfer account type!',
                                },
                                ]}
                            >
                                <Select 
                                    showSearch 
                                    placeholder="Search to Select" 
                                    optionFilterProp="children" 
                                    filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())} 
                                    style={{ width: '100%'}} 
                                    onChange={setSelectedType}
                                    name="accountType"
                                    options={
                                    typeList.map(s => {
                                        return {value: s.value, label: s.label }
                                    })}
                                    disabled={!selectedCex ? true : false}
                                    ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <p class="available">Available Balance: <span>{getUSDollar(props.selectedAccount.passiveBalance)}</span></p>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Enter Transfer Amount:" name="transferAmount" rules={[
                                {
                                    required: true,
                                    message: 'Please enter transfer amount!',
                                }
                            ]}>
                                <InputNumber placeholder='Enter transfer amount.'
                                    min={0}
                                    max={props.selectedAccount.passiveBalance}
                                    value={transferAmount} 
                                    onChange={value => setTransferAmount(value)}
                                    formatter={(value) => `$ ${value}`}
                                    parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Make Transfer</Button>
                        </Col>
                    </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddTransferModal;

/*
{
    fromName: 'Binance - SPOT',
    fromId: 'aasdasdasfasfadf',
    toName: 'Gate.io - SPOT',
    toId: 'asdadadsasda',
    transferAmount: 15000,
    userId: 'sdadasdasda',
    date: date.now(),

    id: 'f3rgggdf45y4g'
}
*/