import { db } from '../firebase-config';
import { onSnapshot, collection, addDoc, query, where, getDoc, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import FirebaseSymbols from '../data/firebase-symbols.json';
import { cexes, accountEnum, accountType } from '../libs/enums';




/*
USER MODEL: 
binanceFuturesApiKey: "" (string)
binanceSpotApiKey: ""(string)
displayName: "Hasan Göçmen" (string)
email: "hsngcmn@gmail.com" (string)
localId: "" (string)
photoUrl: "" (string)
totalBalance: 0
*/

const getUser = async (userId) => {
    const docRef = doc(db, "users", userId);
    const result = await getDoc(docRef);
    //console.log('result', result);
    let user = result.data();
    return user;
}

const addUser = async (user) => {
    const docRef = doc(db, "users", user.uid);
    let newUser = {
        binanceFuturesApiKey: "",
        binanceSpotApiKey: "",
        displayName: user.displayName,
        email: user.email,
        localId: user.uid,
        photoUrl: user.photoURL,
        totalBalance: 0,
        withdrawal:0
    }
    const addedUser = await setDoc(docRef, newUser);
    return addedUser.id;
}

const updateUserBalance = async (balance, user) => {
    //console.log('updateBalance', updatedBalance);
    let copyUser = {...user};
    const docRef = doc(db, 'users', user.localId);
    copyUser.totalBalance = copyUser.totalBalance + balance;
    //console.log('balance id', updatedBalance.id);
    let updatedUser = await setDoc(docRef, copyUser);
    console.log('uu', updatedUser);
    return updatedUser;
}

const updateUserWithdrawal = async (withdrawal, user) => {
    //console.log('updateBalance', updatedBalance);
    let copyUser = {...user};
    const docRef = doc(db, 'users', user.localId);
    copyUser.withdrawal = copyUser.withdrawal + withdrawal;
    //console.log('balance id', updatedBalance.id);
    let updatedUser = await setDoc(docRef, copyUser);
    return updatedUser;
}

/*
Symbol Model:
baseAsset: "PEPE" (string)
iconUrl: "pepe.svg" (string)
market: "Binance" (string)
name: "Pepe" (string)
official: "" (string)
pricePrecision: 2 (number)
quantityPrecision: 0 (number)
quoteAsset: "USDT" (string)
symbol: "PEPEUSDT" (string)
type: "FUTURES" (string)
*/

const getSymbols = async () => {
    const collectionRef = collection(db, "symbols");
    const result = await getDocs(collectionRef);
    let symbols = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    return symbols;
}

const getSymbol = async (symbolId) => {
    const docRef = doc(db, "symbols", symbolId);
    const result = await getDoc(docRef);
    //console.log('result', result);
    let symbol = result.data();
    return symbol;
}

const addSymbol = async (symbol) => {
    const collectionRef = collection(db, "symbols");
    const newSymbol = await addDoc(collectionRef, symbol);
    return newSymbol.id;
}



/******* ACCOUNTS *******/

const getCexes = async () => {
    const collectionRef = collection(db, "cex");
    const result = await getDocs(collectionRef);
    let cexes = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    return cexes;
}

const getAccounts = async (userId) => {
    const q = query(collection(db, "accounts"), where("userId", "==", userId));
    const result = await getDocs(q);
    let accounts = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    return accounts;
}

const getAccount = async (userId, cexId, type) => {
    const q = query(collection(db, "accounts"), where("userId", "==", userId), where("cexId", "==", cexId), where("type", "==", type));
    const result = await getDocs(q);
    let account = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    return account;
}

const updateAccount = async (updatedAccount) => {
    const docRef = doc(db, 'accounts', updatedAccount.id);
    delete updatedAccount.id;
    let account = await setDoc(docRef, updatedAccount);
    return account;
}

const addAccount = async (account) => {
    const collectionRef = collection(db, "accounts");
    const newAccount = await addDoc(collectionRef, account);
    return newAccount.id;
}

const getTransfers = async (userId) => {
    const q = query(collection(db, "transfer"), where("userId", "==", userId));
    const result = await getDocs(q);
    let transfers = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    return transfers;
}

const addTransfer = async (transfer) => {
    const collectionRef = collection(db, "transfer");
    const newTransfer = await addDoc(collectionRef, transfer);
    return newTransfer.id;
}



/*
Process Model:
symbolId: "xxx" (string)
userId: "xxx" (string)
side: "Buy/Sell" (string)
unitPrice: 0 (number)
quantity: 0 (number)
total: unitPrice * quantity (number)
date: date.now() (timestamp)
realizedPnL: *calculated data (number)
*/
const createProcess = async (process) => {
    const collectionRef = collection(db, "spot-processes");
    process.total = process.quantity * process.unitPrice;
    if(process.side === "Buy") { 
        // Alım işlemi eklenecek.
        let addProcess = await addDoc(collectionRef, process);
        const docRef = doc(db, "spot-processes", addProcess.id);
        let newProcessRes = await getDoc(docRef);
        let newProcess = {...newProcessRes.data(), id: newProcessRes.id};
        //console.log('new process', newProcess);

        //let newProcess = await spotProcessService.createProcess(process);
        if(newProcess) {
            //console.log('newProcess', newProcess.userId, newProcess.symbolId);
            let getBalanceRecord = await getBalance(newProcess.userId, newProcess.symbolId, newProcess.period);
            //console.log('getBalanceRecord', [...getBalanceRecord]);
            if(getBalanceRecord.length) {
                //console.log('lan nereye giriyor bu amk');
                // kayıt varsa güncellenecek. (adet ve ort alım fiyatı hesaplanacak.)
                let oldPaid = getBalanceRecord[0].quantity * getBalanceRecord[0].averageCost;
                let totalQnty = Number((newProcess.quantity + getBalanceRecord[0].quantity).toFixed(4));
                let totalPaid = oldPaid + newProcess.total;
                let newAverageCost = totalPaid / totalQnty;
                getBalanceRecord[0].quantity = totalQnty;
                getBalanceRecord[0].averageCost = newAverageCost;

                //console.log('get balance record', getBalanceRecord, totalQnty);
                updateBalance(getBalanceRecord[0]); // gönderilen data kontrol edilecek.
            } else {
                // kayıt yok ise yeni kayıt eklenecek.
                let newBalance = {
                    userId: newProcess.userId,
                    symbolId: newProcess.symbolId,
                    quantity: newProcess.quantity,
                    averageCost: newProcess.unitPrice,
                    totalPnL: 0.0,
                    period: newProcess.period,
                    date: Date.now()
                }

                addBalance(newBalance);
            }

            let getSymbol = FirebaseSymbols.symbols.find(s => s.id === newProcess.symbolId);
            let getCex = cexes.find(c => c.name === getSymbol.market);
            let accountType = accountEnum.SPOT;
            let getAccountRecord = await getAccount(newProcess.userId, getCex.id, accountType);
            // Tamam olmuş gibi boş hesapla test edilecek.
            if(getAccountRecord.length && newProcess.period !== 'short') {
                // account vardır. gerekli güncelleme yapılacak ve update edilecek.
                getAccountRecord[0].activeBalance = getAccountRecord[0].activeBalance + newProcess.total;
                getAccountRecord[0].passiveBalance = getAccountRecord[0].passiveBalance - newProcess.total;
                updateAccount(getAccountRecord[0]);
            }
        }
        return newProcess;
    }
    else {
        // Satış işlemi eklenecek.
        let getBalanceRecord = await getBalance(process.userId, process.symbolId, process.period);

        if(getBalanceRecord.length) {
            if(getBalanceRecord[0].quantity >= process.quantity) {
                let sellTotal = process.quantity * process.unitPrice;
                let costTotal = process.quantity * getBalanceRecord[0].averageCost;
                let pnl = sellTotal - costTotal;
                process.total = sellTotal;
                process.realizedPnL = pnl;
    
                let addProcess = await addDoc(collectionRef, process);
                const docRef = doc(db, "spot-processes", addProcess.id);
                let newProcessRes = await getDoc(docRef);
                let newProcess = {...newProcessRes.data(), id: newProcessRes.id};
                //console.log('new process', newProcess);
    
                if(newProcess) { // dönen data kontrol edilecek.
                    //let leftQuantity = getBalanceRecord[0].quantity - newProcess.quantity;
                    let leftQuantity = Number((getBalanceRecord[0].quantity - newProcess.quantity).toFixed(4));
                    
                    //console.log('satış quant', leftQuantity, getBalanceRecord[0].quantity, newProcess.quantity);
                    getBalanceRecord[0].quantity = leftQuantity;
                    getBalanceRecord[0].totalPnL = getBalanceRecord[0].totalPnL + pnl;
                    if(leftQuantity === 0) {
                        // bütün mallar satıldıysa average sıfırlanır.
                        getBalanceRecord[0].averageCost = 0;
                    }
    
                    updateBalance(getBalanceRecord[0]); // gönderilen data kontrol edilecek.               

                    let getSymbol = FirebaseSymbols.symbols.find(s => s.id === newProcess.symbolId);
                    let getCex = cexes.find(c => c.name === getSymbol.market);
                    let accountType = accountEnum.SPOT;
                    let getAccountRecord = await getAccount(newProcess.userId, getCex.id, accountType);
        
                    // Tamam olmuş gibi boş hesapla test edilecek.
                    if(getAccountRecord.length && newProcess.period !== 'short') {
                        // account vardır. gerekli güncelleme yapılacak ve update edilecek.
                        getAccountRecord[0].activeBalance = getAccountRecord[0].activeBalance - costTotal;
                        getAccountRecord[0].passiveBalance = getAccountRecord[0].passiveBalance + newProcess.total;
                        getAccountRecord[0].currentPnL = getAccountRecord[0].currentPnL + newProcess.realizedPnL;
                        updateAccount(getAccountRecord[0]);
                    }
                }
                return newProcess; // yeni eklenen işlem kaydı döndürülecek.
            } else {
                // eldeki adetten fazlasını satmaya kalkarsa hata gönderiyoruz.
                throw new Error('You cannot sell more than the amount you have!');
            }
        } else {
            return "Bu satış işlemi için cüzdanda bakiye bulunmuyor.";
        }
    }
}
 
const getProcess = async (userId, symbolId, period) => {
    const collectionRef = collection(db, "spot-processes");
    let q = query(collectionRef, where("userId", "==", userId), where("symbolId", "==", symbolId), where("period", "==", period));
    let result = await getDocs(q);
    let process = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    //console.log('balance', process);
    return process.sort((a, b) => a.date - b.date);
}

const deleteProcess = async (processId) => {
    const docRef = doc(db, "spot-processes", processId);
    const result = await getDoc(docRef);
    //console.log('result', result);
    let process = result.data();

    let getBalanceRecord = await getBalance(process.userId, process.symbolId, process.period);
    let getBalanceProcessRecords = await getProcess(process.userId, process.symbolId, process.period);
    //console.log('getBalanceRecord', [...getBalanceRecord]);

    let getSymbol = FirebaseSymbols.symbols.find(s => s.id === process.symbolId);
    let getCex = cexes.find(c => c.name === getSymbol.market);
    let accountType = accountEnum.SPOT;
    let getAccountRecord = await getAccount(process.userId, getCex.id, accountType);

    // TODO: burada o balance'a ait son process sanki silinmeden doğrudan balance'in kendisi siliniyor. Hesapta sorun yok gibi ama fazladan process db de kalıyor. kontrol edilecek.
    if(process.side === 'Buy') {
        // alım işlemi ise yapılacak hesap ve güncellemeler.
        let oldPaid = getBalanceRecord[0].quantity * getBalanceRecord[0].averageCost; // 4.65 x 21.5 0 99.975
        let leftPaid = oldPaid - process.total; // 99.975 - 99.975 = 0
        //let leftQuantity = getBalanceRecord[0].quantity - process.quantity;
        let leftQuantity = Number((getBalanceRecord[0].quantity - process.quantity).toFixed(4)); // 4.65 - 4.65 = 0
        let newAverageCost = leftQuantity ? leftPaid / leftQuantity : 0; // 0 / 0;

        getBalanceRecord[0].quantity = leftQuantity;
        getBalanceRecord[0].averageCost = newAverageCost;

        if(getBalanceProcessRecords.length > 1) {
            updateBalance(getBalanceRecord[0]);
        } else {
            await deleteBalance(getBalanceRecord[0].id);
        }

        // Tamam olmuş gibi boş hesapla test edilecek.
        if(getAccountRecord.length && process.period !== 'short') {
            // account vardır. gerekli güncelleme yapılacak ve update edilecek.
            getAccountRecord[0].activeBalance = getAccountRecord[0].activeBalance - process.total;
            getAccountRecord[0].passiveBalance = getAccountRecord[0].passiveBalance + process.total;
            updateAccount(getAccountRecord[0]);
        }
    } else {
        // satiş işlemi ise yapılacak hesap ve güncellemeler.
        //let oldQuantity = getBalanceRecord[0].quantity + process.quantity;
        let oldQuantity = Number((getBalanceRecord[0].quantity + process.quantity).toFixed(4));
        
        let oldBalance = (process.total - process.realizedPnL) + (getBalanceRecord[0].quantity * getBalanceRecord[0].averageCost);
        getBalanceRecord[0].quantity = oldQuantity;
        getBalanceRecord[0].totalPnL = getBalanceRecord[0].totalPnL - process.realizedPnL;
        getBalanceRecord[0].averageCost = oldBalance / oldQuantity;
        if(getBalanceProcessRecords.length > 1) {
            updateBalance(getBalanceRecord[0]);
        } else {
            await deleteBalance(getBalanceRecord[0].id);
        }

        // Tamam olmuş gibi boş hesapla test edilecek.
        if(getAccountRecord.length && process.period !== 'short') {
            // account vardır. gerekli güncelleme yapılacak ve update edilecek.
            getAccountRecord[0].activeBalance = getAccountRecord[0].activeBalance + (process.total - process.realizedPnL);
            getAccountRecord[0].passiveBalance = getAccountRecord[0].passiveBalance - process.total;
            getAccountRecord[0].currentPnL = getAccountRecord[0].currentPnL - process.realizedPnL;
            updateAccount(getAccountRecord[0]);
        }
    }

    await deleteDoc(docRef);
    return true;
}

/*
const hiddenGetAllProcess = async () => {
    const collectionRef = collection(db, "spot-processes");
    let result = await getDocs(collectionRef);

    let extendedProcessList = await Promise.all(
        result.docs.map(async doc => {
            let process = {...doc.data(), id: doc.id};
            let uptProcess = await hiddenUpdateProcess(process);

            return uptProcess;
        }));

    return extendedProcessList;
}

const hiddenUpdateProcess = async (process) => {
    const docRef = doc(db, 'spot-processes', process.id);
    process.period = "long";
    let updatedProcess = await setDoc(docRef, process);
    return updatedProcess;
}
*/

/*
Balance Model:
symbolId: "xxx" (string)
userId: "xxx" (string)
quantity: 0 (number)
averageCost: 0 (number)
totalPnL: 0 (number)
*/
const getBalances = async (userId) => {
    const q = query(collection(db, "spot-balances"), where("userId", "==", userId));

    let result = await getDocs(q);
    let balances = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    //console.log('balance', balances);
    if(balances.length) {
        let newBalance = await Promise.all(balances.map(async b => {
            //let symbol = await getSymbol(b.symbolId);
            let symbol = FirebaseSymbols.symbols.find(s => s.id === b.symbolId);
            //console.log('B', b, symbol);
            let newBalanceItem = b;
            newBalanceItem.symbol = symbol;
            //console.log('new balance item', newBalanceItem);
            return newBalanceItem;          
        }));
        //console.log('new balance', newBalance);
        return newBalance;
    }

    return [];
}

const getBalance = async (userId, symbolId, period = "long") => {
    // Komple bütün hepsini çekmesin tek item getirecek.
    const q = query(collection(db, "spot-balances"), where("userId", "==", userId), where("symbolId", "==", symbolId), where("period", "==", period));
    /*let userQuery = query(where("userId", "==", userId));
    let symbolQuery;
    if (symbolId) {
        symbolQuery = query(where("symbolId", "==", symbolId));
    }*/

    let result = await getDocs(q);
    let balance = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    //console.log('balance', balance);
    if(balance.length) {
        let newBalance = await Promise.all(balance.map(async b => {
            //let symbol = await getSymbol(b.symbolId);
            let symbol = FirebaseSymbols.symbols.find(s => s.id === b.symbolId);
            //console.log('B', b, symbol);
            let newBalanceItem = b;
            newBalanceItem.symbol = symbol;
            //console.log('new balance item', newBalanceItem);
            return newBalanceItem;          
        }));
        //console.log('new balance', newBalance);
        return newBalance;
    }

    return [];
}

const updateBalance = async (updatedBalance) => {
    //console.log('updateBalance', updatedBalance);
    //const copyUpdatedBalance = {...updatedBalance};
    const docRef = doc(db, 'spot-balances', updatedBalance.id);
    delete updatedBalance.id;
    delete updatedBalance.symbol;
    //console.log('balance id', updatedBalance.id);
    let balance = await setDoc(docRef, updatedBalance);
    return balance;
}

const addBalance = async (balance) => {
    const collectionRef = collection(db, "spot-balances");
    let newBalance = await addDoc(collectionRef, balance);
    return newBalance;
}

const deleteBalance = async (balanceId) => {
    const docRef = doc(db, "spot-balances", balanceId);
    await deleteDoc(docRef);
    return true;
}

/*
const hiddenGetAllBalances = async () => {
    const collectionRef = collection(db, "spot-balances");
    let result = await getDocs(collectionRef);

    let extendedBalancesList = await Promise.all(
        result.docs.map(async doc => {
            let balance = {...doc.data(), id: doc.id};
            let uptBalance = await hiddenUpdateBalance(balance);

            return uptBalance;
        }));

    return extendedBalancesList;
}

const hiddenUpdateBalance = async (balance) => {
    const docRef = doc(db, 'spot-balances', balance.id);
    balance.period = "long";
    let updatedBalance = await setDoc(docRef, balance);
    return updatedBalance;
}
*/

export const firebaseService = {
    getUser,
    addUser,
    updateUserBalance,
    updateUserWithdrawal,
    getSymbols,
    getSymbol,
    addSymbol,
    getBalances,
    getBalance,
    addBalance,
    updateBalance,
    createProcess,
    getProcess,
    deleteProcess,
    getCexes,
    getAccounts,
    getAccount,
    updateAccount,
    addAccount,
    getTransfers,
    addTransfer
};