import './App.scss';

import { AuthContext } from './contexts/AuthContext';
import { useContext, useEffect } from 'react';
import AuthApp from './containers/AuthApp';
import Authentication from './containers/Authentication';
import { Context } from './store';
import { getCookie } from './libs/utility';

function App() {
  const {user, privateKey, setPrivateKey } = useContext(AuthContext);
  const {state, dispatch} = useContext(Context);

  console.log('ENV', process.env.NODE_ENV);

  useEffect(() => {
    let prvtKey = getCookie('privateKey');
    if(prvtKey) {
      setPrivateKey(prvtKey);
    }
  }, []);

  if(state.analysisOptions.loginFree) {
    return <AuthApp />;
  }
  else {
    return user && user.accessToken && privateKey === process.env.REACT_APP_PRIVATE_KEY ? <AuthApp /> : <Authentication />
  }

  return <Authentication />
}

export default App;