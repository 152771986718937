import React, {useState, useContext, useEffect} from 'react';
import {Modal, Table, Button, Row, Col, Tag} from 'antd';
import {epochToDate} from '../../libs/utility';
import { firebaseService } from '../../services/';
import { AuthContext } from '../../contexts/AuthContext';
import { getUSDollar } from '../../libs/utility';
import { DeleteFilled, ExclamationCircleFilled } from '@ant-design/icons';

import './style.scss';

const { confirm } = Modal;

const ProcessHistoryModal = (props) => {
    const { user } = useContext(AuthContext);
    //console.log('gelen selected history', props.selectedSymbol);
    const [historicalData, setHistoricalData] = useState([]);
    const [balance, setBalance] = useState({});

    const columns = [
        {
          title: 'Side',
          dataIndex: 'side',
          key: 'side',
          align: 'center',
          render: side => <Tag color={side === "Buy" ? "#2ca52c" : "#f6465d"}>{side}</Tag>
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: quantity => `${quantity}`
          },
        {
            title: 'Unit Price',
            key: 'unitPrice',
            dataIndex: 'unitPrice',
            render: unitPrice => `${getUSDollar(unitPrice, 6)}`,
        },
        {
            title: 'Total Cost',
            key: 'total',
            dataIndex: 'total',
            render: total => `${getUSDollar(total)}`,
        },
        {
            title: 'PnL',
            key: 'realizedPnL',
            dataIndex: 'realizedPnL',
            render: realizedPnL => `${getUSDollar(realizedPnL)}`,
        },
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            render: date => epochToDate(date).toLocaleDateString()
        },
        {
            title: '',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width:50,
            render: (id, record, index) => historicalData.length - 1 === index ? <Button type="primary" shape="circle" icon={<DeleteFilled />} size={"small"} className='btn-add-process btn-red' onClick={() => showConfirm(id)} /> :   ""
        }
    ];

    const showConfirm = (processId) => {
        confirm({
            title: 'İşlem Silme Onayı',
            icon: <ExclamationCircleFilled />,
            content: 'Bu işlem kaydını silmek istediğinize emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'Vazgeç',
            onOk() {
              //console.log('OK');
              deleteProcess(processId);
            },
            onCancel() {
              //console.log('Cancel');
            },
        });
    };

    const deleteProcess = (processId) => {
        //console.log('delete process id', processId);
        firebaseService.deleteProcess(processId)
            .then(res => {
                /*
                firebaseService.getProcess(user.uid, props.selectedSymbol.id)
                .then(res => {
                    //console.log('history', res);
                    setHistoricalData(res);
                })
                */
                props.toggleModal(null);
            })
    }

    useEffect(() => {
        firebaseService.getProcess(user.uid, props.selectedSymbol.id, props.period)
            .then(res => {
                //console.log('history', res);
                setHistoricalData(res);
            })
        firebaseService.getBalance(user.uid, props.selectedSymbol.id, props.period)
            .then(res => {
                //console.log('historical balance', res[0]);
                setBalance(res[0]);
            })
    }, []);

    return (
        <>
            <Modal title="Process History" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={800} height={800}>
                <div className="process-history-modal">
                    {balance &&
                        <div className='history-info'>
                            <Row gutter={0}>
                                <Col span={8} className="title">
                                    <div className='history-image'>
                                        <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${props.selectedSymbol?.iconUrl}`} alt="" />
                                        <p><strong>{props.selectedSymbol?.symbol}</strong></p> 
                                    </div>   
                                </Col>
                                <Col span={8} className="history-prices">
                                    <p>Average Cost: <span>{getUSDollar(balance.averageCost, 6)}</span></p>
                                    <p>Quantity: <span>{balance.quantity}</span></p>
                                </Col>
                                <Col span={8} className="history-prices">
                                    <p>Active Balance: <span>{getUSDollar(balance.quantity * balance.averageCost)}</span></p>
                                    <p>Total PnL: <span>{getUSDollar(balance.totalPnL)}</span></p>
                                </Col>
                            </Row>  
                        </div>
                    }

                    <Table columns={columns} dataSource={historicalData} bordered pagination={false} scroll={{y: 278,}}/>
                </div>
            </Modal>
        </>
    );
};

export default ProcessHistoryModal;