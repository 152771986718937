import React from 'react';
import './style.scss';

const Footer = () => {
    return (
        <>
            <p>Cryptoloque © 2022 - All rights reserved.</p>
        </>
    );
};

export default Footer;