import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import SpotPositionBox from '../../components/SpotPositionBox/indexFirebase';
import {Context} from '../../store';
import {Row, Col, Button, Switch, Checkbox, InputNumber, Modal, Form} from 'antd';
import './style.scss';
import AddTransferModal from '../../components/AddTransferModal';
import ProcessHistoryModal from '../../components/ProcessHistoryModal/indexFirebase';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { AuthContext } from '../../contexts/AuthContext';
import SpotPositionTable from '../../components/SpotPositionTable';
import { usePrevious } from '../../hooks/usePrevious';
import { firebaseService, binanceService, gateService, mexcService } from '../../services';
import { SyncOutlined } from '@ant-design/icons';
import { getUSDollar, groupBy, sortByCexObject } from '../../libs/utility';
import { CSVLink } from "react-csv";
import { cexes, accountEnum, accountType, importCexOrder } from '../../libs/enums';
import AccountGroup from '../../components/AccountGroup';
import { PlusCircleOutlined } from '@ant-design/icons';
import TransferHistoryModal from '../../components/TransferHistoryModal';

const generateAccountList = () => {
    let accountList = [];
    cexes.map(c => {

    });
}

// TODO: bu component iki kere render oluyor ama neden?
const Accounts = () => {
    const {state, dispatch} = useContext(Context);
    const { user } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [withdrawalForm] = Form.useForm();
    const [firstForm] = Form.useForm();

    const [accountValue, setAccountValue] = useState(0);
    const [dbUser, setDbUser] = useState();
    const [allAccounts, setAllAccounts] = useState({});
    const [toggleTransferModal, setToggleTransferModal] = useState(false);
    const [addNewBalanceToggleModal, setAddNewBalanceToggleModal] = useState(false);
    const [withdrawalToggleModal, setWithdrawalToggleModal] = useState(false);
    const [transferHistoryToggleModal, setTransferHistoryToggleModal] = useState(false);
    const [newBalance, setNewBalance] = useState(0);
    const [newWithdrawal, setNewWithdrawal] = useState(0);

    const [selectedAccount, setSelectedAccount] = useState(null);

    const [deposit, setDeposit] = useState(0);
    const [withdrawal, setWithdrawal] = useState(0);
    const [mainBalance, setMainBalance] = useState(0); 
    const [currentBalamce, setCurrentBalance] = useState(0);
    const [currentPnL, setCurrentPnL] = useState(0);
    const [currentPnLPercent, setCurrentPnLPercent] = useState(0);

    const getCexes = async () => {
        let cexes = await firebaseService.getCexes();
        if(cexes) {
            console.log('cexes', cexes);
        }
    }

    const checkBalance = useCallback(async () => {
        console.log('check balance function');
        
        let firebaseUser = await firebaseService.getUser(user.uid);
        if(firebaseUser) {
            console.log('firebase user', firebaseUser);
            setDbUser(firebaseUser);
            if (firebaseUser.totalBalance) {
                let accounts = await firebaseService.getAccounts(user.uid);
                console.log('core accounts', accounts);
                accounts.sort((a, b) => sortByCexObject[a.cexName] - sortByCexObject[b.cexName]);
                let deposit = accounts.reduce((total, item) => total + Number(item.firstBalance + item.transfer), 0);
                let currentPnL = accounts.reduce((total, item) => total + Number(item.currentPnL), 0);
                let currentBalance = deposit + currentPnL;
                let currentPnlPercent = (((currentBalance - deposit) * 100) / deposit);
                setDeposit(deposit);
                setWithdrawal(firebaseUser.withdrawal);
                setMainBalance(deposit - firebaseUser.withdrawal);

                setCurrentBalance(currentBalance);
                setCurrentPnL(currentPnL);
                setCurrentPnLPercent(currentPnlPercent);
                let accountGroups = groupBy(accounts, 'cexName');
                console.log('USER ACCOUNTS', accounts, accountGroups);
                setAllAccounts(accountGroups);
            }
        }
    }, []);

    useEffect(() => {
        checkBalance();
        //getCexes();
    }, []);

    const toggleModal = (account) => {
        console.log('toggleModal Account', account);
        if(account) {
            setSelectedAccount(account);
        }
        else {
            setSelectedAccount(null);
        }
        setToggleTransferModal(!toggleTransferModal);
    }

    const HistoryToggleModal = () => {
        setTransferHistoryToggleModal(!transferHistoryToggleModal);
    }

    const createAccount = async () => {        
        let balances = await firebaseService.getBalances(dbUser.localId);
        let filteredBinanceBalances = balances.filter(b => b.symbol.market === 'Binance');
        let totalPnl = filteredBinanceBalances.reduce((total, item) => total + Number(item.totalPnL), 0);
        let activeBalance = filteredBinanceBalances.filter(b => b.period !== "short").reduce((total, item) => total + (item.averageCost * item.quantity), 0);
        let firstExchangeRate = firstForm.getFieldValue("firstExchange");
        firebaseService.updateUserBalance(accountValue, dbUser)
            .then(res => {
                let binance = cexes.find(c => c.name === "Binance");
                let binanceSpot = {
                    activeBalance: activeBalance,
                    cexId: binance.id,
                    cexName: binance.name,
                    firstBalance : accountValue,
                    passiveBalance: accountValue + totalPnl - activeBalance,
                    pastPnL: 0,
                    currentPnL: totalPnl,
                    transfer: 0,
                    type: accountEnum.SPOT,
                    userId: dbUser.localId,
                };
                firebaseService.addAccount(binanceSpot)
                    .then(async res => {
                        if (res) {
                            console.log('binance spot hesabı başarı ile oluşturuldu', res);
                            let transferRecord = {
                                fromName: 'Bank',
                                fromId: 0,
                                toName: binance.name + ' - ' + accountEnum.SPOT,
                                toId: res,
                                userId: dbUser.localId,
                                transferAmount: accountValue,
                                exchangeRate: firstExchangeRate,
                                date: Date.now()
                            };
                            let makeTransfer = await firebaseService.addTransfer(transferRecord);
                            console.log('first transfer', makeTransfer);
                            checkBalance();
                        }
                    });
            });
    }

    const updateMainBalance = () => {
        firebaseService.updateUserBalance(newBalance, dbUser)
            .then(async res => {
                let binanceSpot = {...allAccounts['Binance'].find(a => a.type === accountEnum.SPOT)};
                binanceSpot.firstBalance = binanceSpot.firstBalance + newBalance;
                binanceSpot.passiveBalance = binanceSpot.passiveBalance + newBalance;
                console.log('Binance SPOT', binanceSpot);
                let exchangeRate = form.getFieldValue('exchangeRateBalance');
                firebaseService.updateAccount({...binanceSpot})
                    .then(async res => {
                        let transferRecord = {
                            fromName: 'Bank',
                            fromId: 0,
                            toName: binanceSpot.cexName + ' - ' + accountEnum.SPOT,
                            toId: binanceSpot.id,
                            userId: dbUser.localId,
                            transferAmount: newBalance,
                            exchangeRate: exchangeRate,
                            date: Date.now()
                        };
                        console.log('Main Balance Transfer Records', transferRecord);
                        let makeTransfer = await firebaseService.addTransfer(transferRecord);
                        console.log('add balance transfer', makeTransfer);
                        setNewBalance(0);
                        form.resetFields();
                        setAddNewBalanceToggleModal(false);
                        checkBalance();
                    });
            })
    }

    const addNewBalanceModal = () => {
        return (
            <Modal title="Add More Deposit" open={addNewBalanceToggleModal} footer={null} onCancel={() => setAddNewBalanceToggleModal(false)} width={300} height={800}>
            <div className="new-balance-modal">
                <Form form={form} layout="vertical" onFinish={updateMainBalance} initialValues={{exchangeRateBalance: 0}}>
                <Row gutter={16}>
                    <Col span={24}>
                        <p>Mevcut bakiyenin üstüne ekleme yapabilirsin.</p>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Enter New Deposit Amount:" name="newBalance" rules={[
                            {
                                required: true,
                                message: 'Please enter new deposit amount!',
                            },
                        ]}>
                            <InputNumber placeholder='Enter New Deposit Amount.'
                                value={newBalance} 
                                onChange={value => setNewBalance(value)}
                                formatter={(value) => `$ ${value}`}
                                parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="TL Value of 1 Dollar:" name="exchangeRateBalance" rules={[
                            {
                                required: true,
                                message: 'Please enter value!',
                            },
                        ]}>
                            <InputNumber placeholder='Enter Value.'
                                min={0}
                                formatter={(value) => `${value}`}
                                parser={(value) => value.replace(/,/g, '.')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Add New Balance</Button>
                    </Col>
                </Row>
                </Form>
            </div>
        </Modal>
        );
    }

    const updateMainBalanceWithdrawal = () => {
        firebaseService.updateUserWithdrawal(newWithdrawal, dbUser)
        .then(async res => {
            let binanceSpot = {...allAccounts['Binance'].find(a => a.type === accountEnum.SPOT)};
            // binanceSpot.firstBalance = binanceSpot.firstBalance + newBalance;
            // binanceSpot.passiveBalance = binanceSpot.passiveBalance + newBalance;
            binanceSpot.transfer = binanceSpot.transfer + (-newWithdrawal); // TODO: artı eksi durumu kontrol edilecek.
            binanceSpot.passiveBalance = binanceSpot.passiveBalance + (-newWithdrawal); // TODO: artı eksi durumu kontrol edilecek.
            console.log('Binance SPOT', binanceSpot);
            let exchangeRate = withdrawalForm.getFieldValue('exchangeRateWithdrawal');
            firebaseService.updateAccount({...binanceSpot})
                .then(async res => {
                    let transferRecord = {
                        fromName: binanceSpot.cexName + ' - ' + accountEnum.SPOT,
                        fromId: binanceSpot.id,
                        toName: 'Bank',
                        toId: 0,
                        userId: dbUser.localId,
                        transferAmount: newWithdrawal,
                        exchangeRate: exchangeRate,
                        date: Date.now()
                    };
                    console.log('Main Balance Transfer Records', transferRecord);
                    let makeTransfer = await firebaseService.addTransfer(transferRecord);
                    console.log('add balance transfer', makeTransfer);
                    setNewWithdrawal(0);
                    withdrawalForm.resetFields();
                    setWithdrawalToggleModal(false);
                    checkBalance();
                });
        });
    }

    const withdrawalModal = () => {
        return (
            <Modal title="Withdrawal" open={withdrawalToggleModal} footer={null} onCancel={() => setWithdrawalToggleModal(false)} width={300} height={800}>
            <div className="new-balance-modal">
                <Form form={withdrawalForm} layout="vertical" onFinish={updateMainBalanceWithdrawal} initialValues={{exchangeRateWithdrawal: 0}}>
                <Row gutter={16}>
                    <Col span={24}>
                        <p>Mevcut bakiyeden para çekimi yapabilirsin.</p>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Enter Withdrawal Amount:" name="withdrawal" rules={[
                            {
                                required: true,
                                message: 'Please enter withdrawal amount!',
                            },
                        ]}>
                            <InputNumber placeholder='Enter New Withdrawal Amount.'
                                value={newWithdrawal} 
                                onChange={value => setNewWithdrawal(value)}
                                formatter={(value) => `$ ${value}`}
                                parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="TL Value of 1 Dollar:" name="exchangeRateWithdrawal" rules={[
                            {
                                required: true,
                                message: 'Please enter value!',
                            },
                        ]}>
                            <InputNumber placeholder='Enter Value.'
                                min={0}
                                formatter={(value) => `${value}`}
                                parser={(value) => value.replace(/,/g, '.')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Make Withdrawal</Button>
                    </Col>
                </Row>
                </Form>
            </div>
        </Modal>
        );
    }

    return (
        <div className='accounts container'>
            {!dbUser?.totalBalance ? 
                <div className="account-starting new-balance-modal">
                    <Form form={firstForm} layout="vertical" onFinish={createAccount} initialValues={{firstExchange: 0}}>
                        <Row gutter={16} justify={'center'}>
                            <Col span={24}>
                                <div className='create-new-account'>Kripto borsalarında kullanmak üzere gerçek hesabınızdan aktardığınız miktarı dolar cinsinden girerek hesap işlemlerinizi takip etmeye başlayabilirsiniz. <br /><span>(Yüklenen bakiye otomatik olarak binance spot hesabı oluşturacaktır.)</span></div>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="Enter starting balance:" name="account-value" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter starting balance!',
                                    },
                                ]}>
                                    <InputNumber placeholder='Enter starting balance.'
                                        min={0}
                                        value={accountValue} 
                                        onChange={value => setAccountValue(value)}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="TL Value of 1 Dollar:" name="firstExchange" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value!',
                                    },
                                ]}>
                                    <InputNumber placeholder='Enter Value.'
                                        min={0}
                                        formatter={(value) => `${value}`}
                                        parser={(value) => value.replace(/,/g, '.')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Create an Account</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            :
                <>
                <div className='account-header'>
                    <p className='main-title'>ALL ACCOUNTS</p>
                    <div className='balance-actions'>
                        <Button type={'primary'} className="btn-cryptoverse" onClick={() => setAddNewBalanceToggleModal(true)}>Add More Deposit</Button>
                        <Button type={'primary'} danger className="btn-cryptoverse" onClick={() => setWithdrawalToggleModal(true)}>Withdrawal</Button>
                        <Button type={'primary'} className="btn-black" onClick={() => setTransferHistoryToggleModal(true)}>Transfer History</Button>
                    </div>
                </div>
                <div className='overall-balance'>
                    <Row gutter={16}>
                        <Col xs={12} md={4}>
                            <div className='balance'>
                                <div className='label'>Deposit</div>
                                <div className='value'>{getUSDollar(deposit)}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='balance'>
                                <div className='label'>Withdrawal</div>
                                <div className='value'>{getUSDollar(withdrawal)}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='balance'>
                                <div className='label'>Main Balance</div>
                                <div className='value'>{getUSDollar(mainBalance)}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='balance'>
                                <div className='label'>Current Balance</div>
                                <div className='value'>{getUSDollar(currentBalamce)}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                             <div className='balance'>
                                <div className='label'>Realized PnL ($)</div>
                                <div className={`value ${currentPnL >= 0 ? "green" : "red"}`}>{getUSDollar(currentPnL)}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='balance'>
                                <div className='label'>Realized PnL (%)</div>
                                <div className={`value ${currentPnLPercent >= 0 ? "green" : "red"}`}>{Number(currentPnLPercent).toFixed(2)}%</div>
                            </div>
                        </Col>
                    </Row>          
                </div>
                {Object.keys(allAccounts).map((a, i) => 
                    <AccountGroup group={allAccounts[a]} name={a} toggleModal={toggleModal} updatedBalance={checkBalance} />
                )}
                </>    
            }
            {toggleTransferModal && <AddTransferModal showModal={toggleTransferModal} toggleModal={toggleModal} selectedAccount={selectedAccount} updatedBalance={checkBalance} />}
            {addNewBalanceToggleModal && addNewBalanceModal()}
            {withdrawalForm && withdrawalModal()}
            {transferHistoryToggleModal && <TransferHistoryModal showModal={transferHistoryToggleModal} toggleModal={HistoryToggleModal} />}
        </div>
    );
};

export default React.memo(Accounts);


/*
- ilk ekleme ve sonrasına bakiye arttırma işlemini de transfer listesine kaydetmenin yolunu bul.
- sistemden para cıkarma işlemi için de bir popup yapılacak.binance spot ve first balance içerisinden eksiltilecek.
- para çıkarma işlemi de transfer listesine eklenecek.
- para ekleme, para çıkarma ve transfer listesi için arayüzde alan oluşturulacak.
- ekle çıkar adımlarında alınan dolar kuru Main Balance başlığının yanında ayrıca popup ile görünecek TL hesabı kar zarar vs.

*/