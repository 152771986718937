/*
let ws = null;

const CLOSE_REASON = 'INTENTIONAL_CLOSE';
const CLOSE_CODE = 3000;

const websocket = {
  isSocketInitialized: () => {
    return ws;
  },

  close: () => {
    //console.log('socket kapat tetiklendi.');
    ws && ws.close(CLOSE_CODE, CLOSE_REASON);
  },

  open: (uri, listener) => {
    ws = new WebSocket(uri);

    ws.onopen = () => {
      //console.log('socket open');
    };

    ws.onmessage = (e) => {
      const message = JSON.parse(e.data);  
      listener && listener(message); 
      //ws.close();
    };

    ws.onclose = (e) => {
      ws = null;
      //console.log('Socket is closed.', e);
    };

    ws.onerror = (err) => {
      //console.log('socket error', err);
      if(ws) ws.close();
    };
  },

  status: () => {
    return ws.readyState;
  }
};
*/

//export default websocket;


const CLOSE_REASON = 'INTENTIONAL_CLOSE';
const CLOSE_CODE = 3000;

function Websocket() {
  this.ws = null;
}

Websocket.prototype.isSocketInitialized = function() {
  return this.ws;
}

Websocket.prototype.close = function() {
  //console.log('socket kapat tetiklendi.');
  this.ws && this.ws.close(CLOSE_CODE, CLOSE_REASON);
}

Websocket.prototype.open = function(uri, listener) {
  this.ws = new WebSocket(uri);

  this.ws.onopen = () => {
    //console.log('socket open');
  };

  this.ws.onmessage = (e) => {
    const message = JSON.parse(e.data);  
    listener && listener(message); 
    //ws.close();
  };

  this.ws.onclose = (e) => {
    this.ws = null;
    console.log('Socket is closed.', e);
  };

  this.ws.onerror = (err) => {
    console.log('socket error', err);
    if(this.ws) this.ws.close();
  };
}

Websocket.prototype.status = function() {
  return this.ws.readyState;
}

export default Websocket;