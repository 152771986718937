import React, {useState, useCallback, useEffect, useContext} from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'antd';
import { StarFilled} from '@ant-design/icons';
import { Context } from '../../store';
import { usePrevious } from '../../hooks/usePrevious';
import Websocket from '../../libs/websocket-new';
import {createCurrencyPriceSocketUrl} from '../../libs/utility';

const SpotPositionBox = ({coin, toggleModal, toggleHistoryModal}) => {
    const {state} = useContext(Context);
    const [currentPrice, setCurrentPrice] = useState(0);
    const prevPrice = usePrevious(currentPrice);

    //console.log(coin);
    const symbolName = coin.symbol.baseAsset.toLowerCase();
    const detailLink = "spot";
    let currentBalance = coin.quantity * coin.averageCost;
    let currentPnL = (currentPrice * coin.quantity) - (coin.quantity * coin.averageCost);

    const listenMessage = useCallback((message) => {
        if(message.p) { 
          setCurrentPrice(Number(message.c).toFixed(4));
        } 
    }, []);
    
     useEffect(() => {
        const coinSocket = new Websocket();
        
        if(Object.keys(coin.symbol).length > 0) {
            let socketUrl = createCurrencyPriceSocketUrl('SPOT', coin.symbol.symbol);

            coinSocket.open(socketUrl, listenMessage);
        }

        return () => {
            if(Object.keys(coin.symbol).length > 0) {
                if(coinSocket.isSocketInitialized()) {
                    coinSocket.close();
                }
            }
        } 
         
    }, [listenMessage, coin.symbol]); 

    const addNewProcess = () => {
        toggleModal(coin.symbol.id);
    }

    const openProcessHistory = () => {
        toggleHistoryModal(coin.symbol.id);
    }

    return (
        <Col xs={{span: 6}}>
            <div className="spot-position-box">
                <Button className='btn-add-process' onClick={addNewProcess}>+</Button>
                <Link to={`/${detailLink}-currencies/${coin.symbol.baseAsset.toLowerCase()}`}>
                    <div className='currency-image'>
                        <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${symbolName}.svg`} alt="" />
                        <p className='symbol-name'><strong>{coin.symbol.baseAsset}</strong></p>
                        <p className='full-name'>({coin.symbol.name})</p>
                    </div>          
                </Link>
                <div className='currency-price'>
                    <p className={currentPrice > prevPrice ? "green" : "red"}>{currentPrice} $</p>
                </div>
                <div className="current-balance">
                    <Row gutter={16}>
                        <Col span={8}>
                            <div className="label">Quantity</div>
                            <div className="value">{coin.quantity}</div>
                        </Col>
                        <Col span={8}>
                            <div className="label">Average Price</div>
                            <div className="value">{(coin.averageCost).toFixed(8)} $</div>
                        </Col>
                        <Col span={8}>
                            <div className="label">Balance</div>
                            <div className="value">{(currentBalance).toFixed(4)} $</div>
                        </Col>
                    </Row>
                </div>
                <div className="pnl-stats">
                    <Row gutter={16}>
                        <Col span={12}>
                            <div className="label">Total PnL</div>
                            <div className="value">{(coin.totalPnL).toFixed(4)} $</div>
                        </Col>
                        <Col span={12}>
                            <div className="label">Current PnL</div>
                            <div className={`value ${currentPnL > 0 ? "green" : "red"}`}>{(currentPnL).toFixed(4)} $</div>
                        </Col>
                    </Row>
                </div>
                <div className="history">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Button type="primary" onClick={openProcessHistory} block>Process History</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" block>TradingView</Button>
                        </Col>
                    </Row>
                </div>
            </div>

        </Col>
    );
};

export default SpotPositionBox;