// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuh2LG0xMbRkQauj8pAj_mwJ3uxoKybLI",
  authDomain: "cryptoverse-55488.firebaseapp.com",
  projectId: "cryptoverse-55488",
  storageBucket: "cryptoverse-55488.appspot.com",
  messagingSenderId: "193314516333",
  appId: "1:193314516333:web:f42c17817cd7617127fe64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
/*
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = 'F1BAE275-5248-450F-96D2-F8B63EF1670A';
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcSHGgpAAAAALJMcjRTq4ZchPD0d83AOF2DoRc5'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
*/


/*
getToken(appCheck)
.then(() => {
  console.log('success');
})
.catch(error => {
  console.log(error.message);
});
*/
//initializeFirestore(app, {localCache: persistentLocalCache(/*settings*/{})});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

/*
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuh2LG0xMbRkQauj8pAj_mwJ3uxoKybLI",
  authDomain: "cryptoverse-55488.firebaseapp.com",
  projectId: "cryptoverse-55488",
  storageBucket: "cryptoverse-55488.appspot.com",
  messagingSenderId: "193314516333",
  appId: "1:193314516333:web:f42c17817cd7617127fe64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
*/

/*
  appId: "1:193314516333:web:5eb5812d76cb651327fe64"
*/