import React, { useContext, useState } from 'react';
import CoinListBox from '../../components/CoinListBox';
import {Context} from '../../store';
import {Row, Col, Input} from 'antd';
import './style.scss';

const Currencies = () => {
    const {state} = useContext(Context);
    const [searchKey, setSearchKey] = useState("");

    let showSymbols = state.firebaseSymbols.filter(x => {
        if(searchKey) {
            return x.symbol.toLowerCase().search(searchKey.toLowerCase()) > -1 || x.name.toLowerCase().search(searchKey.toLowerCase()) > -1;
        }
        else {
            return x;
        }
    }).map((coin, i) => {
        return (
            <CoinListBox coin={coin} key={i} />
        );
    });

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    }
    
    return (
        <div className='futures container-fluid'>
            <Row gutter={16}>
                <Col span="24">
                    <div className="currency-search">
                        <Input placeholder='Please write currency name' allowClear onChange={onSearch} />
                    </div>
                </Col>
                {showSymbols}
            </Row>
        </div>
    );
};

export default React.memo(Currencies);