import React, { useCallback, useEffect, useState, useContext } from 'react';
import websocket from '../../libs/websocket';
import Websocket from '../../libs/websocket-new';
import {createCurrencyPriceSocketUrl} from '../../libs/utility';
import { usePrevious } from '../../hooks/usePrevious';
import './style.scss';
import {Link} from 'react-router-dom';
import {Row, Col} from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Context } from '../../store';

const CoinCurrentStatus = ({marketType, symbol}) => {
    const {state} = useContext(Context);

    const lastTwo = [...state.kLinesList[symbol].candles].splice(state.kLinesList[symbol].candles.length - 2, 2);

    let colorStyle = lastTwo[0].candlestick.close > lastTwo[1].candlestick.close ? "red" : "green";

    const [currentPrice, setCurrentPrice] = useState(0);
    const prevPrice = usePrevious(currentPrice);

    const listenMessage = useCallback((message) => {
        if(message.p) { 
          setCurrentPrice(Number(message.p).toFixed(2));
        } 
    }, []);
    
     useEffect(() => {
        let socketUrl = createCurrencyPriceSocketUrl(marketType, symbol);

        const coinSocket = new Websocket();

        coinSocket.open(socketUrl, listenMessage);

        return () => {
            if(coinSocket.isSocketInitialized()) {
                coinSocket.close();
            }
        } 
         
    }, [listenMessage, symbol, marketType]); 

    return (
        <div className='coin-status'>
            <Row>
                <Col span={6} className="coin-logo">
                    <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${symbol.replace('USDT', '').toLowerCase()}.svg`} alt="" />
                    <p>{symbol}</p>
                </Col>
                <Col span={6}>
                    <span>BEFORE</span>
                    <p>{Number(lastTwo[0].candlestick.close).toFixed(2)} $</p>
                </Col>
                <Col span={6}>
                    <span>LAST <span className={`percent ${colorStyle}`}>{((lastTwo[1].candlestick.close - lastTwo[0].candlestick.close) * 100 / lastTwo[0].candlestick.close).toFixed(2)} %</span></span>
                    <div className="current-price">
                        <p className={colorStyle}>{Number(lastTwo[1].candlestick.close).toFixed(2)} $</p>
                    </div>
                </Col>
                <Col span={6}>
                    <span>CURRENT <span className={`percent ${currentPrice > lastTwo[1].candlestick.close ? "green" : "red"}`}>{((currentPrice - lastTwo[1].candlestick.close) * 100 / lastTwo[1].candlestick.close).toFixed(2)} %</span></span>
                    <div className="current-price">
                        <p className={currentPrice > prevPrice ? "green" : "red"}>{currentPrice} $</p>
                        {currentPrice > lastTwo[1].candlestick.close ?
                            <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                        :
                            <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                        }
                    </div>
                </Col>
            </Row> 
        </div>    
    );
};

export default CoinCurrentStatus;