import React from 'react';
import Logo from '../../Logo';
import Navigation from '../../Navigation';
import UserInfo from '../../UserInfo';
import { MenuOutlined } from '@ant-design/icons';

import './style.scss';

const Header = ({onChangeModel, showDrawer}) => {

    return (
        <>
            <div className="mobile-menu-icon" onClick={showDrawer}>
              <MenuOutlined style={{fontSize: 24, color: '#FFF'}} />
            </div>
            <div className='header-logo'>
              <Logo /> 
            </div>
            <Navigation onChangeModel={onChangeModel} />
            <UserInfo />
        </>
    );
};

export default Header;



/*
<Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="mail" icon={<MailOutlined />}>
          Navigation One
        </Menu.Item>
        <Menu.Item key="app" disabled icon={<AppstoreOutlined />}>
          Navigation Two
        </Menu.Item>
        <SubMenu key="SubMenu" icon={<SettingOutlined />} title="Navigation Three - Submenu">
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="setting:1">Option 1</Menu.Item>
            <Menu.Item key="setting:2">Option 2</Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Item 2">
            <Menu.Item key="setting:3">Option 3</Menu.Item>
            <Menu.Item key="setting:4">Option 4</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item key="alipay">
          <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
            Navigation Four - Link
          </a>
        </Menu.Item>
      </Menu>

*/


/**
             <Menu onClick={handleClick} theme="dark" selectedKeys={[current]} mode="horizontal">
                <Menu.Item key="calculator">
                    <Link to="/">Calculator</Link>
                </Menu.Item>
                <Menu.Item key="analysis">
                    <Link to="/analysis">Analysis</Link>
                </Menu.Item>
                <Menu.Item key="futures">
                    <Link to="/futures-currencies">Futures Currencies</Link>
                </Menu.Item>
            </Menu>
 */