import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, InputNumber, Button, Row, Col, Radio, Select, message, Form} from 'antd';
import { Context } from '../../store';
import { cryptoverseService } from '../../services';
import './style.scss';
import { firebaseService } from '../../services/';
import { AuthContext } from '../../contexts/AuthContext';

const processSide = [
    {
        label: 'Buy',
        value: 'Buy',
    },
    {
        label: 'Sell',
        value: 'Sell',
    }
];

const periods = [
    {
        label: 'Long',
        value: 'long',
    },
    {
        label: 'Mid',
        value: 'mid',
    },
    {
        label: 'Short',
        value: 'short',
    }
];

const AddSpotProcessModal = (props) => {
    const [form] = Form.useForm();
    const { user } = useContext(AuthContext);
    //console.log('gelen selected', props.selectedSymbol);
    const {state, dispatch} = useContext(Context);
    const [side, setSide] = useState("");
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0);
    const [period, setPeriod] = useState('long');

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedSymbol(value);
    };

    useEffect(() => {
        if (props.selectedSymbol) {
            setSelectedSymbol(props.selectedSymbol.id);
            form.setFieldValue('symbol', props.selectedSymbol.id);
        }
        if(props.period) {
            setPeriod(props.period);
            form.setFieldValue('period', props.period);
        } else {
            form.setFieldValue('period', period);
        }
    }, []);

    const saveNewProcess = () => {
        console.log('user', user.uid);
        let newProcess = {
            userId: user.uid,
            symbolId: selectedSymbol,
            side: side,
            unitPrice: unitPrice,
            quantity: quantity,
            date: Date.now(),
            period: period,
            realizedPnL: 0
        }
        if(selectedSymbol && side && quantity) {
            firebaseService.createProcess(newProcess)
                .then(res => {
                    console.log(newProcess);
                    props.toggleModal(null);
                    //props.refresh();
                    message.success('New transaction added successfully!');
                })
                .catch(err => {
                    //console.log(err.message);
                    message.error(err.message); 
                });
        }
    }

    return (
        <>
            <Modal title="Add New Process" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={300} height={800}>
                <div className="add-new-process-modal">
                    <Form form={form} layout="vertical" onFinish={saveNewProcess}>
                    <Row gutter={16}>
                        <Col span={24} className="select-period">
                            <Form.Item
                                name="period"
                                label="Select Period:"
                            >
                                <Radio.Group options={periods} onChange={({target: {value}}) => {setPeriod(value)}} value={period} optionType="button" buttonStyle="solid" disabled={props.period ? true : false} />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="select-symbol">
                            <Form.Item
                                label="Select Symbol:"
                                name="symbol"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select symbol!',
                                },
                                ]}
                            >
                                <Select 
                                    showSearch 
                                    placeholder="Search to Select" 
                                    optionFilterProp="children" 
                                    filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())} 
                                    style={{ width: '100%'}} 
                                    onChange={handleChange}
                                    name="symbol"
                                    options={
                                    props.symbols?.map(s => {
                                        return {value: s.id, label: `${s.symbol} ${s.market !== "Binance" ? ('(' + s.market + ')') : ''}`}
                                    })}
                                    disabled={props.selectedSymbol ? true : false}
                                    ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="select-side">
                            <Form.Item
                                name="side"
                                label="Select Side:"
                                rules={[{ required: true, message: 'Please pick a side!' }]}
                            >
                                <Radio.Group options={processSide} onChange={({target: {value}}) => {setSide(value)}} value={side} optionType="button" buttonStyle="solid" />
                            </Form.Item>
                        
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Enter Quantity:" name="quantity" rules={[
                                {
                                    required: true,
                                    message: 'Please enter quantity!',
                                },
                            ]}>
                                <InputNumber placeholder='Enter quantity.' 
                                    value={quantity} onChange={value => setQuantity(value)}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item label="Enter Unit Price:" name="unitPrice" rules={[
                                {
                                    required: true,
                                    message: 'Please enter unit price!',
                                },
                            ]}>
                                <InputNumber placeholder='Enter unit price.'
                                    min={0}
                                    value={unitPrice} 
                                    onChange={value => setUnitPrice(value)}
                                    formatter={(value) => `$ ${value}`}
                                    parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button type="primary" block size="large" htmlType="submit" className='btn-cryptoverse btn-add-process'>Add Process</Button>
                        </Col>
                    </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddSpotProcessModal;