import React, { useEffect, useContext } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Context } from '../../store';
import { CalculatorOutlined } from '@ant-design/icons';
import './style.scss';
import {analysisModel} from '../../libs/enums';
import {Button} from 'antd';



const nav = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Analysis",
        path: '/analysis'
    },
    {
        name: "Currencies",
        path: "/currencies"
    },
    {
        name: "All PNL",
        path: "/all-pnl"
    },
    {
        name: "Spot Wallets",
        path: "/wallet"
    },
    {
        name: "Cumulative",
        path: "/cumulative"
    },
    {
        name: "Accounts",
        path: "/accounts"
    },    
];

const Navigation = ({onChangeModel}) => {
    const location = useLocation();
    const {state, dispatch} = useContext(Context);
    //console.log(location);

    const openCalculator = () => {
        dispatch({type: "SHOW_CALCULATOR", payload: true});
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const navigation = nav.map((navItem, i) => {
        let className = location.pathname === navItem.path ? "active": "";
        return <li key={i} className={className}>
        <Link to={navItem.path}>{navItem.name}</Link>
      </li>
    });

    const getAnalysisModel = () => {
        const model = analysisModel.find(m => m.value === state.analysisOptions.model);
        return model.label;
    }

    return (
        <div className='navigation'>
        <ul>
                {navigation}
                {Object.keys(state.kLinesList).length > 0 && state.enabledSimulator &&
                    <li key={nav.length} className={location.pathname === '/simulator' ? "active": ""}>
                        <Link to={'/simulator'}>{'Simulator'}</Link>
                    </li>
                }            
        </ul>
        <div className='calc-icon'>
            <CalculatorOutlined style={{ fontSize: '30px', color: '#e5ac17' }} onClick={openCalculator}/>
        </div>
        <div className="current-model">
            <p>Current Analysis Model: <br /><strong>{getAnalysisModel()}</strong></p>
            {/*Object.keys(state.kLinesList).length > 0 && !state.enabledSimulator && <Button type="link" onClick={onChangeModel}>Change</Button>*/}
        </div>
      </div>
    );
};

export default Navigation;