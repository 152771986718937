import React, { useEffect, useState } from 'react';
import './style.scss';
import AccountBox from '../AccountBox';
import {Row, Col, Button, Switch, Checkbox} from 'antd';
import { sortByCexTypeObject, getUSDollar } from '../../libs/utility';
import { accountType, accountEnum } from '../../libs/enums';

const AccountGroup = ({name, group, toggleModal, updatedBalance}) => {
    group.sort((a, b) => sortByCexTypeObject[a.type] - sortByCexTypeObject[b.type]);
    console.log('group accounts', group);
    const incoming = group.find(a => a.type === accountEnum.SPOT).firstBalance;
    const mainBalance = group.reduce((total, item) => total + Number(item.firstBalance + item.transfer), 0);
    const currentPnL = group.reduce((total, item) => total + Number(item.currentPnL), 0);
    const currentBalance = mainBalance + currentPnL;
    const currentPnlPercent = (((currentBalance - mainBalance) * 100) / mainBalance);
    return (
        <div className="account-group">
            <div className='group-header'>
                <div className='info'>
                    <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${name.replace('.', '').replace(' ', '').toLowerCase()}.svg`} alt="" />
                    <p>{name.toUpperCase()}</p>
                    <span>({group.length})</span>             
                </div>
                <div className='balances'>
                    <div className='balance'>
                        <span className='label'>Incoming</span>
                        <span className='value'>{getUSDollar(incoming)}</span>
                    </div>
                    <div className='balance'>
                        <span className='label'>Main Balance</span>
                        <span className='value'>{getUSDollar(mainBalance)}</span>
                    </div>
                    <div className='balance'>
                        <span className='label'>Current Balance</span>
                        <span className='value'>{getUSDollar(currentBalance)}</span>
                    </div>
                    <div className='balance'>
                        <span className='label'>Realized PnL ($)</span>
                        <span className={`value ${currentPnL >= 0 ? "green" : "red"}`}>{getUSDollar(currentPnL)}</span>
                    </div>
                    <div className='balance'>
                        <span className='label'>Realized PnL (%)</span>
                        <span className={`value ${currentPnlPercent >= 0 ? "green" : "red"}`}>{currentPnlPercent ? Number(currentPnlPercent).toFixed(2) : 0}%</span>
                    </div>
                </div>

            </div>
            <div className='group-body'>
                <Row gutter="16">
                    {group.map(g => 
                        <Col xs={24} md={8}>
                            <AccountBox account={g} toggleModal={toggleModal} updatedBalance={updatedBalance} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default React.memo(AccountGroup);