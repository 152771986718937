import {fibonacci} from './enums';
import { std } from 'mathjs';
import {getPassingTime} from './utility';
let beforeEMAs = {
    ema5: null,
    ema8: null,
    ema13: null,
    ema21: null,
    ema34: null,
    ema50: null,
    ema55: null,
    ema89: null,
    ema100: null,
    ema144: null,
    ema233: null,
    ema377: null,
    highEma8: null,
    highEma13: null,
    lowEma8: null,
    lowEma13: null
}

let prevAvg = {}
let prevObv = 0;

let rsiValue = 14;
let spotPrevKlines = 30;
let gunstockPrevKlines = 10;
let pivotValue = 6;
let takeProfit = 1;
let stopLoss = 1;
let trailingStop = 0.2;
let posTimeLimit = 23;
let rsiDivergenceType = "tight";

export const allCurrencyCandlestickAnalysis = async (symbols, symbolList, analysisModel, simulator = false) => {
    const newCandlesticksObj = {}
    console.log(simulator);

    const supportResistance = {};
    
    Object.keys(symbols).map((symbol, i) => {
        /* Support Resistance Calculate */
        let symbolSupports = findSupportResistance(symbols[symbol], symbol);
        supportResistance[symbol] = symbolSupports;
        /* Support Resistance Calculate - End */

        let obvArray = [];

        newCandlesticksObj[symbol] = symbols[symbol].map((kline, ix) => {
            let indicatorObj = {};

            fibonacci.map(fib => {
                indicatorObj[`ma${fib}`] = ix >= fib ? movingAverage([...symbols[symbol]].splice((ix + 1) - fib, fib)) : null;
            
                if(ix === fib) {
                    beforeEMAs[`ema${fib}`] = indicatorObj[`ma${fib}`];
                }

                if(ix >= fib + 1) {
                    indicatorObj[`ema${fib}`] = expMovingAverage(beforeEMAs[`ema${fib}`], kline.candlestick.close, fib);  
                    
                    beforeEMAs[`ema${fib}`] = indicatorObj[`ema${fib}`];
                }
                else { 
                    indicatorObj[`ema${fib}`] = null;
                }           
            });
        
            // CALCULATE RSI VALUE
            if(ix >= rsiValue) {
                if(ix === rsiValue) {
                    const redGreenAvg = firstRsiAvgCalculate([...symbols[symbol]].splice((ix + 1) - (rsiValue + 1), rsiValue + 1));
                    prevAvg["prevGreenAvg"] = redGreenAvg.greenAvg;
                    prevAvg["prevRedAvg"] = redGreenAvg.redAvg;
                    //console.log('firstPrevAvg:', prevAvg["prevGreenAvg"], prevAvg["prevRedAvg"]);
                }
                else {
                    const rsiObj = relativeStrengthIndex(prevAvg.prevGreenAvg, prevAvg.prevRedAvg, kline, [...symbols[symbol]][ix - 1]);
                    prevAvg["prevGreenAvg"] = rsiObj.greenAvg;
                    prevAvg["prevRedAvg"] = rsiObj.redAvg;
                    indicatorObj.greenAvg = rsiObj.greenAvg;
                    indicatorObj.redAvg = rsiObj.redAvg;
                    indicatorObj.rsi = rsiObj.rsi;
                    //console.log('prevAvg:', prevAvg["prevGreenAvg"], prevAvg["prevRedAvg"]);
                }           
            }

            // CALCULATE BOLLINGER BANDS
            if(ix >= 21) {
                const calculate21forBoll = [...symbols[symbol]].splice((ix + 1) - 21, 21).map(x => Number(x.candlestick.close));

                const splitPrice = kline.candlestick.close.toString().split('.');
                const fixedValue = splitPrice[1] ? splitPrice[1].length : 0;
                const upper = Number((indicatorObj.ma21 + std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
                const lower = Number((indicatorObj.ma21 - std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
                //console.log('ma21: ', newObj.ma21);
                indicatorObj.bollinger = {
                    upper: upper,
                    lower: lower,
                    middle: indicatorObj.ma21,
                    bandwidth: Number((upper - lower).toFixed(fixedValue))
                }
            }

            // CALCULATE OBV (On Balance Volume)
            if(ix > 0) {
                let currObv = onBalanceVolume(prevObv, [...symbols[symbol]][ix - 1], kline, ix);
                indicatorObj.obv = currObv;
                prevObv = currObv;
                obvArray.push(currObv);
            }
            else {
                prevObv = 0;
            }

            if(ix > 21) {
                let obvMa21 = obvMovingAverage([...obvArray].splice((ix + 1) - 21, 21));
                indicatorObj.obvMa21 = obvMa21;
            }

            // FIND PIVOT POINTS
            if(ix > pivotValue) {
                let prevCandles = symbols[symbol].slice(ix - pivotValue, ix);
                let nextCandles = symbols[symbol].slice(ix + 1, ix + 1 + pivotValue);
                //console.log(symbol, kline.candlestick.localOpenTime, prevCandles, nextCandles, kline);
                let pivot = checkPivot(prevCandles, nextCandles, kline);
                kline.candlestick.pivot = pivot;
            }

            kline.indicators = indicatorObj;

            return kline;
        });

        return true;
    });

    const allPastPositionsList = [];
    const allOpenPositionsList = [];
    const activeDeals = [];

    Object.keys(newCandlesticksObj).map((symbol, i) => {
        let prevKline = [];
        let twoPrevKline = [];
        let prevKlines = [];
        let lastPivot = null;
        let beforeComparisonResult = '';

        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            openPrice: null,
            highPrice: null,
            closePrice: null,
            openDate: null,
            closeDate:  null,
            posType: '',
            closeType: null
        };

        newCandlesticksObj[symbol].map((kline, ix) => {
            prevKline = newCandlesticksObj[symbol][ix - 1];
            twoPrevKline = newCandlesticksObj[symbol][ix - 2];

            if(analysisModel === "zombieSpot") {
                prevKlines = [...newCandlesticksObj[symbol]].splice(ix - spotPrevKlines, spotPrevKlines)
            }
            else if (analysisModel === "depthHill") {
                prevKlines = [...newCandlesticksObj[symbol]].splice(ix - gunstockPrevKlines, gunstockPrevKlines)
            }
            else if (analysisModel === "rsiDivergence") {
                prevKlines = [...newCandlesticksObj[symbol]].splice(ix - (pivotValue + 1), (pivotValue + 1))
            }
            else {
                prevKlines = [...newCandlesticksObj[symbol]].splice(ix - 5, 5); 
            }

            if(analysisModel === "rsiDivergence") {
                let hasPivot = rsiDivergenceType === "tight" ? twoPrevKline?.candlestick.pivot : prevKline.candlestick.pivot;
                if(hasPivot === "low") {
                    lastPivot = newCandlesticksObj[symbol].slice(0, ix - 2).sort((a, b) => b.candlestick.openTime - a.candlestick.openTime).find(c => c.candlestick.pivot === "low")
                }
                else if(hasPivot === "high") {
                    lastPivot = newCandlesticksObj[symbol].slice(0, ix - 2).sort((a, b) => b.candlestick.openTime - a.candlestick.openTime).find(c => c.candlestick.pivot === "high")
                }
            }

            if(ix > 200) {
                if(!simulator) {
                    let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supportResistance[symbol], lastPivot);

                    //beforeComparisonResult = comparisonResult.position;  
                    
                    if(currentDeal.status === "LONG" || currentDeal.status === "SHORT") {
                        currentDeal.candleCount++;

                        if(currentDeal.openPrice) {
                            const closeMinusPerc = (Number(kline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                            let tempCloseReverse = currentDeal.closeReverse;
                            currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
                            let tempMaxReverse = currentDeal.maxReverse;
                            
                            let pnl = Number(((kline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));

                            if(currentDeal.status === "LONG") {
                                currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                                const maxMinusPerc = (Number(kline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                                currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                                currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
    
                                currentDeal.highPriceIndex = Number(kline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                                currentDeal.highPrice = Number(kline.candlestick.close) > currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                                currentDeal.topNeedleIndex = Number(kline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                                currentDeal.topNeedle = Number(kline.candlestick.high) > currentDeal.topNeedle ? Number(kline.candlestick.high) : currentDeal.topNeedle;    
                                
                                if(currentDeal.candleCount === 1) {
                                    currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                                } 

                                currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                                currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));           
                            }
                            else if(currentDeal.status === "SHORT") {
                                currentDeal.closeReverse = -1 * closeMinusPerc < tempCloseReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
                                const maxMinusPerc = (Number(kline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                                currentDeal.maxReverse = -1 * maxMinusPerc < tempMaxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
                                currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
    
                                currentDeal.highPriceIndex = Number(kline.candlestick.close) < currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                                currentDeal.highPrice = Number(kline.candlestick.close) < currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                                currentDeal.topNeedleIndex = Number(kline.candlestick.low) < currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                                currentDeal.topNeedle = Number(kline.candlestick.low) < currentDeal.topNeedle ? Number(kline.candlestick.low) : currentDeal.topNeedle;    
    
                                if(currentDeal.candleCount === 1) {
                                    currentDeal.firstReverse = -1 * maxMinusPerc < tempMaxReverse ? Number(-1 * maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                                }

                                currentDeal.highProfit = Number(-1 * ((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                                currentDeal.mostProfit = Number(-1 * ((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));    
                                
                                pnl = -1 * pnl; 
                            }

                            currentDeal.closePrice = Number(kline.candlestick.close);
                            currentDeal.closeDate = kline.candlestick.closeTime;
                            currentDeal.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(currentDeal.openDate));
                            
                            if(analysisModel === "depthHill") {
                                //pnl = pnl < -1.5 ? -1.5 : pnl;
                                /*if(currentDeal.mostProfit >= 1) {
                                    pnl = pnl < 0 ? 0.1 : pnl;
                                }
                                else {
                                    pnl = pnl < -2 ? -2 : pnl;
                                }*//*
                                if(currentDeal.topNeedle > currentDeal.openPrice * 1.02 && currentDeal.topNeedle < currentDeal.openPrice * 1.03 && kline.candlestick.low <= currentDeal.openPrice) {
                                    pnl = 0;
                                }
                                else *//*if(currentDeal.topNeedle >= currentDeal.openPrice * 1.03) {
                                    pnl = currentDeal.mostProfit - 3;
                                    / *
                                    if(kline.candlestick.low <= currentDeal.openPrice) {
                                        pnl = 0;
                                    }
                                    else {
                                        pnl = currentDeal.mostProfit - 3;
                                    }* /
                                }
                                else *//*if(pnl < -4) {
                                    pnl = -4;
                                }*/

                                if(currentDeal.mostProfit >= 1) {
                                    pnl = currentDeal.mostProfit - 1;
                                }
                                else if(pnl < -2) {
                                    pnl = -2;
                                }
                            }
                            else if(analysisModel === "explosionLoose") {
                                if(currentDeal.mostProfit > 3 && kline.candlestick.low <= currentDeal.topNeedle * 0.97) {
                                    pnl = currentDeal.mostProfit - 3;
                                }
                                else if(currentDeal.mostProfit <= 3 && currentDeal.mostProfit > 0 && kline.candlestick.low <= currentDeal.topNeedle * 0.95) {
                                    pnl = currentDeal.mostProfit - 5;
                                }
                                else if(pnl < -2) {
                                    pnl = -2;
                                }


                                /*
                                else if(currentDeal.mostProfit > 1 && kline.candlestick.low <= currentDeal.openPrice) {
                                    pnl = 0;
                                }
                                else if(currentDeal.mostProfit > 10 && kline.candlestick.low <= currentDeal.topNeedle * 0.96) {
                                    pnl = currentDeal.mostProfit - 4;
                                }  
                                else if(currentDeal.mostProfit > 5 && kline.candlestick.low <= currentDeal.topNeedle * 0.97) {
                                    pnl = currentDeal.mostProfit - 3;
                                }
                                else if(currentDeal.mostProfit > 2 && kline.candlestick.low <= currentDeal.topNeedle * 0.98) {
                                    pnl = currentDeal.mostProfit - 2;
                                }
                                else if(currentDeal.mostProfit > 1 && kline.candlestick.low <= currentDeal.topNeedle * 0.99) {
                                    pnl = currentDeal.mostProfit - 1;
                                }
                                else if(currentDeal.mostProfit <= 1 && kline.candlestick.low <= currentDeal.topNeedle * 0.98) {
                                    pnl = currentDeal.mostProfit - 2;
                                }*/
                            }
                            else if(analysisModel === "rsiDivergence") {
                                /*if(currentDeal.mostProfit >= takeProfit) {
                                    pnl = takeProfit;
                                }*//*
                                else if (comparisonResult.closeType === "CP-rd3") {
                                    pnl = 0;
                                }*/
                                if (comparisonResult.closeType === "CP-rd-t") {
                                    pnl = currentDeal.mostProfit - trailingStop;
                                }
                                else {
                                    pnl = currentDeal.maxReverse <= -stopLoss ? -stopLoss : pnl;
                                }
                            }
                            else {
                                pnl = pnl < -5 ? -5 : pnl;
                            }

                            currentDeal.pnl = Number(pnl.toFixed(2));     
                        }

                    }

                    if(currentDeal.status !== comparisonResult.position) {     
                
                        if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {  
                            currentDeal.closeType = comparisonResult.closeType;

                            // all position object push
                            allPastPositionsList.push({...currentDeal});
                            currentDeal.status = "CLOSE";
                            currentDeal.openPrice = null;
                            currentDeal.highPrice = null;
                            currentDeal.highPriceIndex = 0;
                            currentDeal.topNeedle = null;
                            currentDeal.topNeedleIndex = 0;
                            currentDeal.posType = "";
                            currentDeal.closeReverse = 0;
                            currentDeal.closeReverseIndex = 0;
                            currentDeal.maxReverse = 0;
                            currentDeal.maxReverseIndex = 0;
                            currentDeal.firstReverse = 0;
                            currentDeal.candleCount = 0;   
                            
                            // Eğer poz kapanırsa aynı mumda LONG ya da SHORT yeniden açılabilir mi kontrolü.
                            beforeComparisonResult = currentDeal.status;
                            comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supportResistance[symbol], lastPivot);
                        }
        
                        if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                            currentDeal.key = ix + symbol;
                            currentDeal.closeType = null;
                            currentDeal.status = comparisonResult.position;
                            currentDeal.posType = comparisonResult.posType;
                            currentDeal.openPrice = Number(kline.candlestick.close);
                            currentDeal.highPrice = Number(kline.candlestick.close);
                            currentDeal.topNeedle = Number(kline.candlestick.close);
                            currentDeal.closePrice = null;
                            currentDeal.openDate = kline.candlestick.closeTime;
                            currentDeal.closeDate = null;
                            currentDeal.time = null;
                            currentDeal.highProfit = null;
                            currentDeal.mostProfit = null;
                            currentDeal.candleCount = 0;
                            currentDeal.closeReverse = 0;
                            currentDeal.closeReverseIndex = 0;
                            currentDeal.maxReverse = 0;
                            currentDeal.maxReverseIndex = 0;
                            currentDeal.firstReverse = 0;
                            currentDeal.pnl = null;

                            if(analysisModel === "depthHill") {
                                currentDeal.supportLine = comparisonResult.supportLine;
                            }                       
                        }
                    }

                    // son muma geldiysek;
                    if(ix === newCandlesticksObj[symbol].length - 1 && currentDeal.status !== '') {

                        // son mum 'close' degil yani hala açık bir pozisyon.
                        if(currentDeal.status !== "CLOSE") {
                            currentDeal.closeType = "NC";
            
                            allOpenPositionsList.push({...currentDeal}); 
                        }

                        // son mumda değişiklik oluyor yani current deal.
                        console.log('last candlestick', beforeComparisonResult, currentDeal.status);
                        if(beforeComparisonResult !== currentDeal.status) {
                            let activeDeal = {
                                key: symbol,
                                status: '',
                                symbol: symbol,
                                price: kline.candlestick.close,
                                date: kline.candlestick.closeTime,
                                volume: kline.candlestick.vol,
                                posType: currentDeal.posType,
                                closeType: currentDeal.closeType,
                                ema20: kline.indicators.ema20,
                                ema50: kline.indicators.ema50,
                                ema100: kline.indicators.ema100,
                                ema200: kline.indicators.ema200,
                                prevRsi: prevKline.indicators.rsi,
                                rsi: kline.indicators.rsi,
                                bbUp: kline.indicators.bollinger.upper,
                                bbMid: kline.indicators.bollinger.middle,
                                bbLow: kline.indicators.bollinger.lower
                            };
            
                            if (currentDeal.status === "CLOSE" || currentDeal.status === "LONGCLOSE") {
                                activeDeal.status = 'CLOSE POSITION';
                            }
                            else {
                                activeDeal.status = `OPEN ${currentDeal.status}`;
                            }
                            //console.log('Current Single Deal:', currentDeal);
                            activeDeals.push({...activeDeal}); 
                        }
                    }

                    beforeComparisonResult = comparisonResult.position;
                }
            }

            return kline;
        });

        return true;
    });

    return {candlesticks: newCandlesticksObj, pastPositions: allPastPositionsList, openPositions: allOpenPositionsList, supports: supportResistance, activeDeals: activeDeals };
}

export const allCurrencyLastCandlestickAnalysis = async (symbols, lastKlines, symbolList, analysisModel, openPositions) => {
    // güncellenen yeni mumların eski liste ile analiz edilip döndürülmesi.
    const supportResistance = {};
    
    Object.keys(lastKlines).map((symbol, i) => {
        let indicatorObj = {};

        fibonacci.map(fib => { 
            indicatorObj[`ma${fib}`] = movingAverage([...symbols[symbol]].splice(symbols[symbol].length - (fib - 1), (fib - 1)), lastKlines[symbol].candlestick, symbol);
            indicatorObj[`ema${fib}`] = expMovingAverage(symbols[symbol][symbols[symbol].length - 1].indicators[`ema${fib}`], lastKlines[symbol].candlestick.close, fib);
        }); 
   
        const prevKline = symbols[symbol][symbols[symbol].length - 1];

        // CALCULATE RSI VALUE
        const rsiObj = relativeStrengthIndex(prevKline.indicators.greenAvg, prevKline.indicators.redAvg, lastKlines[symbol], prevKline);
        indicatorObj.greenAvg = rsiObj.greenAvg;
        indicatorObj.redAvg = rsiObj.redAvg;
        indicatorObj.rsi = rsiObj.rsi;

        // CALCULATE BOLLINGER BANDS
        const calculate21forBoll = [...symbols[symbol]].splice(symbols[symbol].length - 21, 21).map(x => Number(x.candlestick.close));

        const splitPrice = lastKlines[symbol].candlestick.close.toString().split('.');
        const fixedValue = splitPrice[1] ? splitPrice[1].length : 0;
        const upper = Number((indicatorObj.ma21 + std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
        const lower = Number((indicatorObj.ma21 - std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
        indicatorObj.bollinger = {
            upper: upper,
            lower: lower,
            middle: indicatorObj.ma21,
            bandwidth: Number((upper - lower).toFixed(fixedValue))
        }

        lastKlines[symbol].indicators = indicatorObj;

        /* Support Resistance Calculate */
        //let tempCurrentKlines = [...symbols[symbol]];
        //tempCurrentKlines.push(lastKlines[symbol]);
        //let symbolSupports = findSupportResistance(tempCurrentKlines);
        //supportResistance[symbol] = symbolSupports;
        /* Support Resistance Calculate - End */

        return true;
    });

    // Pivotların ve Support Resistance çizgilerinin güncellenmesi
    Object.keys(symbols).map((symbol, i) => {
        // Son gelen mumlar ana mum listesine ekleniyor.
        symbols[symbol].push(lastKlines[symbol]);

        // şimdi burada yeni gelen mum ile birlikte önceki 6 mumun pivot değerleri tam net değil yeniden düzenlenecek.
        symbols[symbol].map((kline, ix) => {
            if(ix >= symbols[symbol].length - 1 - pivotValue) {
                let prevCandles = symbols[symbol].slice(ix - pivotValue, ix);
                let nextCandles = symbols[symbol].slice(ix + 1, ix + 1 + pivotValue);
                //console.log(symbol, kline.candlestick.localOpenTime, prevCandles, nextCandles, kline);
                let pivot = checkPivot(prevCandles, nextCandles, kline);
                kline.candlestick.pivot = pivot;
            }
        });

        let symbolSupports = findSupportResistance([...symbols[symbol]], symbol);
        supportResistance[symbol] = symbolSupports;
    });


    //const newDataObj = {};
    let updatedPositions = [];
    const activeDeals = [];

    Object.keys(lastKlines).map((symbol, i) => {
        let lastKline = lastKlines[symbol];
        let prevKline = symbols[symbol][symbols[symbol].length - 2];
        let twoPrevKline = symbols[symbol][symbols[symbol].length - 3];
        let prevKlines = [];
        let lastPivot = null;

        if(analysisModel === "zombieSpot") {
            prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - (spotPrevKlines + 1), spotPrevKlines)
        }
        else if (analysisModel === "depthHill") {
            prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - (gunstockPrevKlines + 1), gunstockPrevKlines)
        }
        else if (analysisModel === "rsiDivergence") {
            prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - (pivotValue + 2), (pivotValue + 1))
        }
        else {
            prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - (5 + 1), 5); 
        }

        if(analysisModel === "rsiDivergence") {
            let hasPivot = rsiDivergenceType === "tight" ? twoPrevKline?.candlestick.pivot : prevKline.candlestick.pivot;
            if(hasPivot === "low") {
                lastPivot = symbols[symbol].slice(0, symbols[symbol].length - 4).sort((a, b) => b.candlestick.openTime - a.candlestick.openTime).find(c => c.candlestick.pivot === "low")
            }
            else if(hasPivot === "high") {
                lastPivot = symbols[symbol].slice(0, symbols[symbol].length - 4).sort((a, b) => b.candlestick.openTime - a.candlestick.openTime).find(c => c.candlestick.pivot === "high")
            }
            console.log(symbol, lastPivot);
        }        

        let openPosition = openPositions && openPositions.find(p => p.symbol === symbol);

        let beforeComparisonResult;
        let currentDeal = {};

        if(openPosition) {
            beforeComparisonResult = openPosition.status;
            currentDeal = {...openPosition};
        }
        else {
            beforeComparisonResult = 'CLOSE';
            currentDeal = {
                symbol: symbol,
            }
        }

        /* 
        Açık pozisyon varsa o pozisyona göre LONG ya da SHORT pozisyon verileri güncellenecek.
        veri güncellemesinden sonra açık pozun kapanma durumu oluştuysa CLOSE ile ilgili veriler eklenecek.
        Kapanan pozisyon açık pozlardan çıkarılacak ve old positions kısmına dahil edilecek.
        Açık poz yok ise pozisyon açılışı yapılarak veriler oluşturulup açık pozisyonlara eklenecek.
        Güncel fırsatlar kısmı içinde burada bi takım ayarlamalar yapılacak. Önemli
        Güncel fırsatlarda bu fonksiyon içerisinden gönderilecek.
        */
        let comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supportResistance[symbol], lastPivot);

        if(beforeComparisonResult === "LONG" || beforeComparisonResult === "SHORT") {
            currentDeal.candleCount++;

            if(currentDeal.openPrice) {
                const closeMinusPerc = (Number(lastKline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                let tempCloseReverse = currentDeal.closeReverse;
                currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
                let tempMaxReverse = currentDeal.maxReverse;

                let pnl = Number(((lastKline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));

                if(beforeComparisonResult === "LONG") {
                    currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                    const maxMinusPerc = (Number(lastKline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                    currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                    currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
    
                    currentDeal.highPriceIndex = Number(lastKline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                    currentDeal.highPrice = Number(lastKline.candlestick.close) > currentDeal.highPrice ? Number(lastKline.candlestick.close) : currentDeal.highPrice;
                    currentDeal.topNeedleIndex = Number(lastKline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                    currentDeal.topNeedle = Number(lastKline.candlestick.high) > currentDeal.topNeedle ? Number(lastKline.candlestick.high) : currentDeal.topNeedle;
                    if(currentDeal.candleCount === 1) {
                        currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                    } 

                    currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                    currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));                     
                }
                else if(beforeComparisonResult === "SHORT") {
                    currentDeal.closeReverse = -1 * closeMinusPerc < tempCloseReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
                    const maxMinusPerc = (Number(lastKline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                    currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;     
                    currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                    currentDeal.highPriceIndex = Number(lastKline.candlestick.close) < currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                    currentDeal.highPrice = Number(lastKline.candlestick.close) < currentDeal.highPrice ? Number(lastKline.candlestick.close) : currentDeal.highPrice;
                    currentDeal.topNeedleIndex = Number(lastKline.candlestick.low) < currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                    currentDeal.topNeedle = Number(lastKline.candlestick.low) < currentDeal.topNeedle ? Number(lastKline.candlestick.low) : currentDeal.topNeedle;    

                    if(currentDeal.candleCount === 1) {
                        currentDeal.firstReverse = -1 * maxMinusPerc < tempMaxReverse ? Number(-1 * maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                    }

                    currentDeal.highProfit = Number(-1 * ((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                    currentDeal.mostProfit = Number(-1 * ((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));    
                
                    pnl = -1 * pnl; 
                }

                currentDeal.closePrice = Number(lastKline.candlestick.close);
                currentDeal.closeDate = lastKline.candlestick.closeTime;
                currentDeal.time = getPassingTime(new Date(lastKline.candlestick.closeTime), new Date(currentDeal.openDate));
                //currentDeal.closeType = comparisonResult.closeType;

                if(analysisModel === "depthHill") {
                    //pnl = pnl < -1.5 ? -1.5 : pnl;
                    /*if(currentDeal.mostProfit >= 1) {
                        pnl = pnl < 0 ? 0.1 : pnl;
                    }
                    else {
                        pnl = pnl < -2 ? -2 : pnl;
                    }*/
                    /*
                    if(currentDeal.topNeedle > currentDeal.openPrice * 1.02 && currentDeal.topNeedle < currentDeal.openPrice * 1.03 && lastKline.candlestick.low <= currentDeal.openPrice) {
                        pnl = 0;
                    }
                    else if(currentDeal.topNeedle >= currentDeal.openPrice * 1.03) {
                        if(lastKline.candlestick.low <= currentDeal.openPrice) {
                            pnl = 0;
                        }
                        else {
                            pnl = currentDeal.mostProfit - 3;
                        }
                    }
                    else if(pnl < -4) {
                        pnl = -4;
                    }*/
                    if(currentDeal.mostProfit >= 1) {
                        pnl = currentDeal.mostProfit - 1;
                    }
                    else if(pnl < -2) {
                        pnl = -2;
                    }
                }
                else if(analysisModel === "explosionLoose") {
                    if(currentDeal.mostProfit > 3 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.97) {
                        pnl = currentDeal.mostProfit - 3;
                    }
                    else if(currentDeal.mostProfit <= 3 && currentDeal.mostProfit > 0 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.95) {
                        pnl = currentDeal.mostProfit - 5;
                    }
                    else if(pnl < -2) {
                        pnl = -2;
                    }
                    /*
                    else if(currentDeal.mostProfit > 1 && lastKline.candlestick.low <= currentDeal.openPrice) {
                        pnl = 0;
                    }
                    else if(currentDeal.mostProfit > 10 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.96) {
                        pnl = currentDeal.mostProfit - 4;
                    }  
                    else if(currentDeal.mostProfit > 5 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.97) {
                        pnl = currentDeal.mostProfit - 3;
                    }
                    else if(currentDeal.mostProfit > 2 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.98) {
                        pnl = currentDeal.mostProfit - 2;
                    }
                    else if(currentDeal.mostProfit > 1 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.99) {
                        pnl = currentDeal.mostProfit - 1;
                    }
                    else if(currentDeal.mostProfit <= 1 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.98) {
                        pnl = currentDeal.mostProfit - 2;
                    }
                    */
                }
                else if(analysisModel === "rsiDivergence") {
                    /*if(currentDeal.mostProfit >= takeProfit) {
                        pnl = takeProfit;
                    }*//*
                    else if (comparisonResult.closeType === "CP-rd3") {
                        pnl = 0;
                    }*/
                    if (comparisonResult.closeType === "CP-rd-t") {
                        pnl = currentDeal.mostProfit - trailingStop;
                    }
                    else {
                        pnl = currentDeal.maxReverse <= -stopLoss ? -stopLoss : pnl;
                    }
                }
                else {
                    pnl = pnl < -5 ? -5 : pnl;
                }

                currentDeal.pnl = Number(pnl.toFixed(2));

                if(openPosition) {
                    openPosition.candleCount = currentDeal.candleCount;
                    openPosition.closeReverse = currentDeal.closeReverse;
                    openPosition.closeReverseIndex = currentDeal.closeReverseIndex;
                    openPosition.maxReverse = currentDeal.maxReverse;
                    openPosition.maxReverseIndex = currentDeal.maxReverseIndex;
                    openPosition.highPrice = currentDeal.highPrice;
                    openPosition.highPriceIndex = currentDeal.highPriceIndex;
                    openPosition.topNeedle = currentDeal.topNeedle;
                    openPosition.topNeedleIndex = currentDeal.topNeedleIndex;
                    openPosition.firstReverse = currentDeal.firstReverse;
                    openPosition.closePrice = currentDeal.closePrice;
                    openPosition.closeDate = currentDeal.closeDate;
                    openPosition.time = currentDeal.time;
                    openPosition.pnl = currentDeal.pnl;
                    openPosition.highProfit = currentDeal.highProfit;
                    openPosition.mostProfit = currentDeal.mostProfit;       
                }
            }
        }

        if(beforeComparisonResult !== comparisonResult.position) {
            if(comparisonResult.position === "CLOSE") {
                currentDeal.closeType = comparisonResult.closeType;

                if(openPosition) {
                    openPosition.closeType = currentDeal.closeType;
                }

                currentDeal.status = "CLOSE";
                currentDeal.openPrice = null;
                currentDeal.highPrice = null;
                currentDeal.highPriceIndex = 0;
                currentDeal.topNeedle = null;
                currentDeal.topNeedleIndex = 0;
                currentDeal.posType = "";
                currentDeal.closeReverse = 0;
                currentDeal.closeReverseIndex = 0;
                currentDeal.maxReverse = 0;
                currentDeal.maxReverseIndex = 0;
                currentDeal.firstReverse = 0;
                currentDeal.candleCount = 0;  

                // var olan açık pozisyonu güncelle

                beforeComparisonResult = comparisonResult.position;
                comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supportResistance[symbol], lastPivot, "currentcontrol2");
            }

            if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                currentDeal.key = lastKline.candlestick.closeTime + symbol;
                currentDeal.closeType = 'NC';
                currentDeal.status = comparisonResult.position;
                currentDeal.posType = comparisonResult.posType;
                currentDeal.openPrice = Number(lastKline.candlestick.close);
                currentDeal.highPrice = Number(lastKline.candlestick.close);
                currentDeal.topNeedle = Number(lastKline.candlestick.close);
                currentDeal.closePrice = Number(lastKline.candlestick.close);
                currentDeal.openDate = lastKline.candlestick.closeTime;
                currentDeal.closeDate = lastKline.candlestick.closeTime;
                currentDeal.time = getPassingTime(new Date(lastKline.candlestick.closeTime), new Date(currentDeal.openDate));
                currentDeal.highProfit = 0;
                currentDeal.mostProfit = 0;
                currentDeal.candleCount = 0;
                currentDeal.closeReverse = 0;
                currentDeal.closeReverseIndex = 0;
                currentDeal.maxReverse = 0;
                currentDeal.maxReverseIndex = 0;
                currentDeal.firstReverse = 0;
                currentDeal.pnl = 0;
                if(analysisModel === "depthHill") {
                    currentDeal.supportLine = comparisonResult.supportLine;
                }
                
                // yeni pozisyon ekle
                openPositions.push({...currentDeal});
                updatedPositions = openPositions;
            }

            let activeDeal = {
                key: symbol,
                status: '',
                symbol: symbol,
                price: lastKline.candlestick.close,
                date: lastKline.candlestick.closeTime,
                volume: lastKline.candlestick.vol,
                posType: currentDeal.posType,
                closeType: currentDeal.closeType,
                ema20: lastKline.indicators.ema20,
                ema50: lastKline.indicators.ema50,
                ema100: lastKline.indicators.ema100,
                ema200: lastKline.indicators.ema200,
                prevRsi: prevKline.indicators.rsi,
                rsi: lastKline.indicators.rsi,
                bbUp: lastKline.indicators.bollinger.upper,
                bbMid: lastKline.indicators.bollinger.middle,
                bbLow: lastKline.indicators.bollinger.lower
            };

            if (currentDeal.status === "CLOSE" || currentDeal.status === "LONGCLOSE") {
                activeDeal.status = 'CLOSE POSITION';
            }
            else {
                activeDeal.status = `OPEN ${currentDeal.status}`;
            }
            //console.log('Current Single Deal:', currentDeal);
            activeDeals.push({...activeDeal}); 
        }

        // açık pozisyon varsa eklenen yeni verilerle güncelleme yap.
        if(openPosition) {
            let findIndex = openPositions.findIndex(p => p.symbol === openPosition.symbol);
            openPositions.splice(findIndex, 1, openPosition);
            updatedPositions = openPositions;
        }

        return true;
    });

    return {candlesticks: symbols, positions: updatedPositions, supports: supportResistance, activeDeals: activeDeals };
}

// Short güncellemeleri entegre edilecek.
export const allCurrencyUpdateNewModelAnalysis = async (symbols, analysisModel, supports) => {
    const newDataObj = {};
    const allPastPositionsList = [];

    Object.keys(symbols).map((symbol, i) => {
        let prevKlines = [];
        let beforeComparisonResult = '';

        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            openPrice: null,
            highPrice: null,
            closePrice: null,
            openDate: null,
            closeDate:  null,
            posType: null,
            closeType: null
        };

        newDataObj[symbol] = symbols[symbol].map((kline, ix) => {
            //const currentBtcKline = symbols['BTCUSDT'][ix];
            let newObj = {};
            //prevKlines = [...symbols[symbol]].splice(ix - 5, 5);
            //prevKlines = analysisModel === "zombieSpot" ? [...symbols[symbol]].splice(ix - spotPrevKlines, spotPrevKlines) : [...symbols[symbol]].splice(ix - 5, 5);

            if(analysisModel === "zombieSpot") {
                prevKlines = [...symbols[symbol]].splice(ix - spotPrevKlines, spotPrevKlines)
            }
            else if (analysisModel === "depthHill") {
                prevKlines = [...symbols[symbol]].splice(ix - gunstockPrevKlines, gunstockPrevKlines)
            }
            else {
                prevKlines = [...symbols[symbol]].splice(ix - 5, 5); 
            }

            if(ix > 200) {
                let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supports[symbol]);

                beforeComparisonResult = comparisonResult.position;            
                
                if(currentDeal.status === "LONG") {
                    currentDeal.candleCount++;

                    if(currentDeal.openPrice) {
                        const closeMinusPerc = (Number(kline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        let tempCloseReverse = currentDeal.closeReverse;
                        currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                        currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
    
                        const maxMinusPerc = (Number(kline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        let tempMaxReverse = currentDeal.maxReverse;
                        currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                        currentDeal.highPriceIndex = Number(kline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                        currentDeal.highPrice = Number(kline.candlestick.close) > currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                        currentDeal.topNeedleIndex = Number(kline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                        currentDeal.topNeedle = Number(kline.candlestick.high) > currentDeal.topNeedle ? Number(kline.candlestick.high) : currentDeal.topNeedle;
                        if(currentDeal.candleCount === 1) {
                            currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        } 
                    }
                }
                else if (currentDeal.status === "SHORT") {
                    if(currentDeal.openPrice) {
                        const closeMinusPerc = (Number(kline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        currentDeal.closeReverse = -1 * closeMinusPerc < currentDeal.closeReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
    
                        const maxMinusPerc = (Number(kline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
    
                        currentDeal.highPrice = Number(kline.candlestick.close) < currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                        currentDeal.topNeedle = Number(kline.candlestick.high) < currentDeal.topNeedle ? Number(kline.candlestick.high) : currentDeal.topNeedle;    
                    }
                }

                if(currentDeal.status !== comparisonResult.position) {     
            
                    if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {
                        currentDeal.key = ix + symbol;
                        currentDeal.closePrice = Number(kline.candlestick.close);
                        currentDeal.closeDate = kline.candlestick.closeTime;
                        //currentDeal.time = (new Date(kline.candlestick.closeTime).getTime() - new Date(currentDeal.openDate).getTime()) / (1000 * 60 * 60);
                        currentDeal.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(currentDeal.openDate));

                        let pnl = Number(((kline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                        pnl = pnl < -5 ? -5 : pnl;

                        currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                        currentDeal.closeType = comparisonResult.closeType;
                        currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                        currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                        // all position object push
                        allPastPositionsList.push({...currentDeal});
                        currentDeal.status = "CLOSE";
                        currentDeal.openPrice = null;
                        currentDeal.highPrice = null;
                        currentDeal.highPriceIndex = 0;
                        currentDeal.topNeedle = null;
                        currentDeal.topNeedleIndex = 0;
                        currentDeal.posType = "";
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.candleCount = 0;                        
                    }
    
                    if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                        currentDeal.closeType = null;
                        currentDeal.status = comparisonResult.position;
                        currentDeal.posType = comparisonResult.posType;
                        currentDeal.openPrice = Number(kline.candlestick.close);
                        currentDeal.highPrice = Number(kline.candlestick.close);
                        currentDeal.topNeedle = Number(kline.candlestick.close);
                        currentDeal.closePrice = null;
                        currentDeal.openDate = kline.candlestick.closeTime;
                        currentDeal.closeDate = null;
                        currentDeal.time = null;
                        currentDeal.highProfit = null;
                        currentDeal.mostProfit = null;
                        currentDeal.candleCount = 0;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.pnl = null;                  
                    }
                }

                if(ix === symbols[symbol].length - 1 && currentDeal.status !== "CLOSE" && currentDeal.status !== '') {
                    currentDeal.key = kline.candlestick.closeTime + symbol; 

                    currentDeal.closePrice = Number(kline.candlestick.close);
                    currentDeal.closeDate = kline.candlestick.closeTime;
                    //currentDeal.time = (new Date(kline.candlestick.closeTime).getTime() - new Date(currentDeal.openDate).getTime()) / (1000 * 60 * 60);
                    currentDeal.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(currentDeal.openDate));

                    let pnl = Number(((kline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                    pnl = pnl < -5 ? -5 : pnl;
                    currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                    currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                    currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                    currentDeal.closeType = "NC";
    
                    allPastPositionsList.push({...currentDeal});           
                }

                newObj.position = {
                    status: currentDeal.status,
                    openDate: currentDeal.openDate,
                    openPrice: currentDeal.openPrice,
                    highPrice: currentDeal.highPrice,
                    highPriceIndex: currentDeal.highPriceIndex,
                    posType: currentDeal.posType,
                    closeType: currentDeal.closeType,
                    candleCount: currentDeal.candleCount,
                    closeReverse: currentDeal.closeReverse,
                    closeReverseIndex: currentDeal.closeReverseIndex,
                    maxReverse: currentDeal.maxReverse,
                    maxReverseIndex: currentDeal.maxReverseIndex,
                    topNeedle: currentDeal.topNeedle,
                    topNeedleIndex: currentDeal.topNeedleIndex,
                    firstReverse: currentDeal.firstReverse
                }
            }

            return {...kline, ...newObj};
        });

        return true;
    });

    return {candlesticks: newDataObj, positions: allPastPositionsList };
}

// burası deneysel geçmiş veri amaçlı. aktif kullanılmıyor.
export const singleCurrencyCandlestickAnalysis = async (klines) => {
    // Tek coinin 1500'den fazla olacak şekilde 2021 başından itibaren datalarını çektiğim analiz ortamı.
    // Macd için oluşturulan veriler henüz buraya eklenmedi.
    let newMaObj = klines.map((kline, ix) => {
        let newObj = {};
        newObj.candlestick = [...kline].splice(0, 7);

        fibonacci.map(fib => {
            if(fib < 56) {
                newObj[`ma${fib}`] = ix >= fib ? movingAverage([...klines].splice((ix + 1) - fib, fib)) : null;
            }
        });

        return newObj;
    });

    return newMaObj;
}

// Bottaki versiyona göre yeniden düzenlenecek.
const movingAverage = (data, lastKline) => {
    let fixedValue;
    let sum;
    let length;

    let splitPrice = data[0].candlestick.close.toString().split('.');
    fixedValue = splitPrice[1] ? splitPrice[1].length : 0;

    if(lastKline) {  
        //data.length === 4 && console.log('mAvgData', data, lastKline[4]); 
        let priceArray = data.map(x => x.candlestick.close);
        priceArray.push(lastKline.close);
        //data.length === 4 && console.log(symbol, priceArray);
        length = priceArray.length;
        sum = priceArray.reduce((a, b) => {
            return Number(a) + Number(b);   
        });
        //data.length === 4 && console.log('ma5:', symbol, priceArray, sum, length);
    }
    else {
        length = data.length;
        sum = data.map(x => x.candlestick.close).reduce((a, b) => {
            return Number(a) + Number(b);
        });
    }

    return Number((sum / length).toFixed(fixedValue));
}

const obvMovingAverage = (data) => {
    let sum;
    let length;

    length = data.length;
    sum = data.reduce((a, b) => {
        return Number(a) + Number(b);
    });

    return Number(sum / length);
}

const expMovingAverage = (beforeEma, activeClosePrice, periyod) => {
    const splitPrice = activeClosePrice.toString().split('.');
    const fixedValue = splitPrice[1] ? splitPrice[1].length : 0;    

    return Number((activeClosePrice * (2 / (periyod + 1)) + (beforeEma * (1 - (2 / (periyod + 1))))).toFixed(fixedValue));
}

const firstRsiAvgCalculate = (data) => {
    let greenCandlesticks = [];
    let redCandlesticks = [];

    data.map((kline, i) => {
        if(i > 0) {
            let prevKline = data[i - 1];

            let currVal = kline.candlestick.close - prevKline.candlestick.close;
            if(currVal > 0) {
                greenCandlesticks.push(currVal);
            }
            else {
                redCandlesticks.push(-1 * currVal);
            }
        }
    });

    let greenAverage;
    if(greenCandlesticks.length > 0) {
        const greenTotal = greenCandlesticks.reduce((a, b) => {
            return Number(a) + Number(b);
        });
    
        greenAverage = greenTotal / rsiValue; 
    }
    else {
        greenAverage = 0;
    }
    
    let redAverage;
    if(redCandlesticks.length > 0) {
        const redTotal = redCandlesticks.reduce((a, b) => {
            return Number(a) + Number(b);
        });
    
        redAverage = redTotal / rsiValue;
    }
    else {
        redAverage = 0;
    }

    return {greenAvg: greenAverage, redAvg: redAverage};
}

const relativeStrengthIndex = (prevGreenAvg, prevRedAvg, activeKline, prevKline) => {
    //console.log(`prevGreen: ${prevGreenAvg}, prevRed: ${prevRedAvg}, kline: ${activeKline}`);
    let greenAverage;
    let redAverage;

    let currVal = activeKline.candlestick.close - prevKline.candlestick.close;

    if(currVal > 0) {
        //console.log('currVal artı: ', currVal);
        greenAverage = ((prevGreenAvg * (rsiValue - 1)) + currVal) / rsiValue;
        redAverage = ((prevRedAvg * (rsiValue - 1)) + 0) / rsiValue;
    }
    else {
        //console.log('currVal eksi: ', currVal);
        greenAverage = ((prevGreenAvg * (rsiValue - 1)) + 0) / rsiValue;
        redAverage = ((prevRedAvg * (rsiValue - 1)) + (-1 * currVal)) / rsiValue;
    }

    const rsi = Number((100 - (100 / (1 + (greenAverage / redAverage)))).toFixed(2))

    return {greenAvg: greenAverage, redAvg: redAverage, rsi: rsi};
}

const onBalanceVolume = (prevObv, prevKline, lastKline, ix) => {
    let currentObv = 0;
    if(lastKline.candlestick.close > prevKline.candlestick.close) {
        currentObv = prevObv + Number(lastKline.candlestick.vol);
    }
    else if(lastKline.candlestick.close < prevKline.candlestick.close) {
        currentObv = prevObv - Number(lastKline.candlestick.vol);
    }
    else {
        currentObv = prevObv;
    }
    return currentObv;
}

const checkPivot = (prevCandles, nextCandles, kline) => {
    let pivot = "";
    let prevCloses = prevCandles.map(p => p.candlestick.close);
    let nextCloses = nextCandles.map(p => p.candlestick.close);
    //let prevRsis = prevCandles.map(p => p.indicators?.rsi);
    //let nextRsis = nextCandles.map(p => p.indicators?.rsi)
    //let allCloses = [...prevCloses, ...nextCloses];

    let prevHighestPrice = Math.max(...[...prevCloses]);
    let prevLowestPrice = Math.min(...[...prevCloses]);
    //let prevHighestRsi = Math.max(...[...prevRsis]);
    //let prevLowestRsi = Math.min(...[...prevRsis]);

    let nextHighestPrice = Math.max(...[...nextCloses]);
    let nextLowestPrice = Math.min(...[...nextCloses]);
    //let nextHighestRsi = Math.max(...[...nextRsis]);
    //let nextLowestRsi = Math.min(...[...nextRsis]);

    if(kline.candlestick.close >= prevHighestPrice && 
        kline.candlestick.close > nextHighestPrice && 
        //kline.indicators.rsi > prevHighestRsi &&
        //kline.indicators.rsi > nextHighestRsi && 
        (kline.candlestick.color === "green" || kline.candlestick.change === 0)) {
        pivot = "high";
    }
    else if (kline.candlestick.close <= prevLowestPrice && 
            kline.candlestick.close < nextLowestPrice && 
            //kline.indicators.rsi < prevLowestRsi &&
            //kline.indicators.rsi < nextLowestRsi &&
            (kline.candlestick.color === "red" || kline.candlestick.change === 0)) {
        pivot = "low";
    }
    //console.log(pivot);
    return pivot;
};

const findSupportResistance = (klines, symbol, backBarCount = 500, numberOfCandles = 6, filterStepCount = 14, autoSimplification = true) => {    
    const filterParams = {
        backBarCount: backBarCount,
        numberOfCandles: numberOfCandles,
        filterStepCount: filterStepCount,
        autoSimplification: autoSimplification
    };
    //console.log('support klines', klines);
    let newKlines = [...klines].splice(klines.length - filterParams.backBarCount - filterParams.numberOfCandles, filterParams.backBarCount + filterParams.numberOfCandles);
    //console.log('support 500 klines', newKlines);

    let closePrices = newKlines.map(p => p.candlestick.close);

    let highestPrice = Math.max(...[...closePrices]);
    let lowestPrice = Math.min(...[...closePrices]);
    let channelWidth = (highestPrice - lowestPrice) * 100 / lowestPrice;

    //console.log(symbol, channelWidth);
    
    let simplification = 0;
    if(filterParams.autoSimplification) {
        if(channelWidth < 7) {
            simplification = 7
        }
        else if(channelWidth >= 7 && channelWidth < 10)
            simplification = 14;
        else if(channelWidth >= 10 && channelWidth < 20)
            simplification = 20;
        else if(channelWidth > 20 && channelWidth < 40)
            simplification = 30;
        else if(channelWidth >= 40 && channelWidth < 100)
            simplification = 50;
        else
            simplification = 100;
    }
    else {
        simplification = filterParams.filterStepCount;
    }
    
    let simplifiArray = [];
    for (let i = 1; i <= simplification; i++) {
        let val = 1.0000 + 0.0005 * i;
        simplifiArray.push(val);
    }

    let lines = [];
    let temp_lines = [];
    
    // number of candles
    let noc = filterParams.numberOfCandles * 2 + 1;
        
    newKlines.map((p, i) => {
        if(i + noc <= newKlines.length) {
            let searchPriceList = [...newKlines].slice(i, noc + i);
    
            let openPrices = searchPriceList.map(p => p.candlestick.open);
            let closePrices = searchPriceList.map(p => p.candlestick.close);
            let allPrices = [...openPrices, ...closePrices];
    
            let highestPrice = Math.max(...[...allPrices]);
            let lowestPrice = Math.min(...[...allPrices]);
            let middleCandle = newKlines[i + filterParams.numberOfCandles];
            if(middleCandle.candlestick.close === lowestPrice || middleCandle.candlestick.close === highestPrice) {
                if(lines.map(s => s.price).indexOf(middleCandle.candlestick.close) < 0) {
                    lines.push({strength: 1, price: middleCandle.candlestick.close});
                }
                //lines.indexOf(middleCandle.candlestick.close) < 0 && lines.push(middleCandle.candlestick.close);
            }
            else if(middleCandle.candlestick.open === lowestPrice || middleCandle.candlestick.open === highestPrice) {
                if(lines.map(s => s.price).indexOf(middleCandle.candlestick.open) < 0) {
                    lines.push({strength: 1, price: middleCandle.candlestick.open});
                }
                //lines.indexOf(middleCandle.candlestick.open) < 0 && lines.push(middleCandle.candlestick.open);
            }
        }
    });

    lines.sort((a, b) => a.price - b.price);
    
    simplifiArray.map(step => {
        let matched_index = -1;
        lines.sort((a, b) => a.price - b.price);
        lines.map((line, i) => {
            //console.log(line);
            let nextLine = lines.length > i + 1 ? lines[i + 1] : 0;
            let diffPercent = line.price * step;
            if(nextLine.price > 0 && diffPercent > nextLine.price) {
                let newLine = (line.price + nextLine.price) / 2;
                temp_lines.push({strength: line.strength + 1, price: newLine});
                matched_index = i + 1;
            }
            else {
                if(i !== matched_index) {
                    temp_lines.push(line);
                }
            }
        });
    
        lines = [...temp_lines];
        temp_lines = [];
    });
    
    /*
    lines.sort((a, b) => a - b);
    
    simplifiArray.map(step => {
        let matched_index = -1;
        lines.sort((a, b) => a - b);
        lines.map((line, i) => {
            let nextLine = lines.length > i + 1 ? lines[i + 1] : 0;
            let diffPercent = line * step;
            if(nextLine > 0 && diffPercent > nextLine) {
                let newLine = (line + nextLine) / 2;
                temp_lines.push(newLine);
                matched_index = i + 1;
            }
            else {
                if(i !== matched_index) {
                    temp_lines.push(line);
                }
            }
        });
    
        lines = [...temp_lines];
        temp_lines = [];
    });
    */
    
    return lines;
};

const findSupportResistanceOld = (klines) => {
    const lines = [];

    const filter_params = {
        numberOfCandles: 16,
        wantedQuantity: 8,
        filterStepCount: 5
    }

    klines.map((k, i) => {
        let redCount = 0;
        let greenCount = 0;
        let prevLowestClosingPrice = 100000;
        let nextLowestClosingPrice = 100000;
    
        let prevHighestClosingPrice = 0;
        let nextHighestClosingPrice = 0;
    
        if(i > 9) {
            if(k.candlestick.color === 'red') {
                let prevKlines = [...klines].slice((i - filter_params.numberOfCandles), i); // 5
                let nextKlines = [...klines].slice((i + 1), (i + (filter_params.numberOfCandles + 1))); // 3
    
                prevKlines.map(p => {
                    p.candlestick.color === 'red' && redCount++;
                    prevLowestClosingPrice = p.candlestick.close < prevLowestClosingPrice ? p.candlestick.close : prevLowestClosingPrice;
                });
    
                nextKlines.map(n => {
                    n.candlestick.color === 'green' && greenCount++;
                    nextLowestClosingPrice = n.candlestick.close < nextLowestClosingPrice ? n.candlestick.close : nextLowestClosingPrice;
                });
    
                if(k.candlestick.close <= prevLowestClosingPrice && redCount >= filter_params.wantedQuantity && k.candlestick.close <= nextLowestClosingPrice && greenCount >= filter_params.wantedQuantity) { // 3 - 2
                    //let redActiveLine = lines.filter(s => s.price >= k[3] && s.price <= k[2]);
                    //if(redActiveLine.length === 0) {
                        lines.push({type: 'red', price: k.candlestick.close});    
                    //}
                }
            }
            else {
                let prevKlines = [...klines].slice((i - filter_params.numberOfCandles), i); // 3
                let nextKlines = [...klines].slice((i + 1), (i + (filter_params.numberOfCandles + 1))); // 3
    
                prevKlines.map(p => {
                    p.candlestick.color === 'green' && greenCount++;
                    prevHighestClosingPrice =  p.candlestick.close > prevHighestClosingPrice ? p.candlestick.close : prevHighestClosingPrice;
                });
    
                nextKlines.map(n => {
                    n.candlestick.color === 'red' && redCount++;
                    nextHighestClosingPrice = n.candlestick.close > nextHighestClosingPrice ? n.candlestick.close : nextHighestClosingPrice;
                });
                
                if(k.candlestick.close >= prevHighestClosingPrice && greenCount >= filter_params.wantedQuantity && k.candlestick.close >= nextHighestClosingPrice && redCount >= filter_params.wantedQuantity) { // 2 - 2
                    //let greenActiveLine = lines.filter(s => s.price >= k[3] && s.price <= k[2]);
                    //if(greenActiveLine.length === 0) {
                        lines.push({type: 'green', price: k.candlestick.close});
                    //}
                }
    
            }
        }
    });

    let filteredLines = [...lines].sort((a, b) => a.price - b.price);

    const filterSteps = [1.001, 1.002, 1.003, 1.0035, 1.004, 1.005, 1.006, 1.007, 1.008, 1.009, 1.010].slice(0, filter_params.filterStepCount);

    let deletedLines = [];

    const getDifference = (array1, array2) => {
        return array1.filter(object1 => {
          return !array2.some(object2 => {
            return object1.price === object2.price;
          });
        });
    }
    
    for (const key in filterSteps) {
        filteredLines.map((l, i) => {
            if(deletedLines.find(d => d.price === l.price)) {
                return;
            }
    
            filteredLines.map((lx, ix) => {
                if(ix <= i) {
                    return;
                }
    
                if(Number(l.price) * filterSteps[key] > Number(lx.price)) {
                    deletedLines.push(lx);
                }
            });
        });
        
        let getDifferenceLines = getDifference(filteredLines, deletedLines);
    
        filteredLines = getDifferenceLines;
        deletedLines = [];
    }

    return filteredLines;
}

// get current active positions
export const allCurrencyCandlestickFilter = async (symbols, supports) => {
    let deals = {};
    //let currentDeals = [];
    let currentEma20Deals = [];
    let currentRsiUp30Deals = [];
    let currentRsiDown30Deals = [];
    let currentEma200Deals = [];
    let currentEma100Deals = [];
    let currentSrDeals = [];

    Object.keys(symbols).map((symbol, i) => {
        if(true) {            
            //let currencyInfo = symbolList.find(x => x.symbol === symbol);
            let lastKline = symbols[symbol][symbols[symbol].length - 1];
            let prevKline = symbols[symbol][symbols[symbol].length - 2];

            /*
            const currentPosition = lastKline.position.status;
            const prevPosition = prevKline.position.status;

            if(prevPosition !== currentPosition) {
                let currentDeal = {
                    key: symbol,
                    status: '',
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    date: lastKline.candlestick.closeTime,
                    volume: lastKline.candlestick.vol,
                    posType: lastKline.position.posType,
                    closeType: lastKline.position.closeType,
                    ema20: lastKline.indicators.ema20,
                    ema50: lastKline.indicators.ema50,
                    ema100: lastKline.indicators.ema100,
                    ema200: lastKline.indicators.ema200,
                    prevRsi: prevKline.indicators.rsi,
                    rsi: lastKline.indicators.rsi,
                    BbTop: lastKline.indicators.bollinger.bbUp,
                    BbMiddle: lastKline.indicators.bollinger.bbMid,
                    BbBottom: lastKline.indicators.bollinger.bbLow
                };

                if (currentPosition === "CLOSE" || currentPosition === "LONGCLOSE") {
                    currentDeal.status = 'CLOSE POSITION';
                }
                else {
                    currentDeal.status = `OPEN ${currentPosition}`;
                }
                //console.log('Current Single Deal:', currentDeal);
                currentDeals.push({...currentDeal}); 
            }
            */

            if(calculateEma20Test(lastKline)) {
                let currentEma20Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close
                }

                currentEma20Deals.push(currentEma20Deal);
            }     
            
            if(calculateRsiUpThirthy(prevKline, lastKline)) {
                let currentRsiUp30Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    rsi: lastKline.indicators.rsi
                }

                currentRsiUp30Deals.push(currentRsiUp30Deal);
            }  
            
            if(calculateRsiDownThirthy(lastKline)) {
                let currentRsiDown30Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    rsi: lastKline.indicators.rsi
                }

                currentRsiDown30Deals.push(currentRsiDown30Deal);
            }

            const ema200Control = calculateEma200(prevKline, lastKline);

            if(ema200Control.condition) {
                let currentEma200Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    type: ema200Control.type
                }

                currentEma200Deals.push(currentEma200Deal);
            }

            const ema100Control = calculateEma100(prevKline, lastKline);

            if(ema100Control.condition) {
                let currentEma100Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    type: ema100Control.type
                }

                currentEma100Deals.push(currentEma100Deal);
            }

            const SrControl = calculateSupRes(prevKline, lastKline, supports[symbol]);

            if(SrControl.condition) {
                let currentSrDeal = {
                    key: symbol,
                    symbol: symbol,
                    image: SrControl.image,
                    line: SrControl.line,
                    strength: SrControl.strength,
                    prevLine: SrControl.prevLine,
                    nextLine: SrControl.nextLine
                }

                currentSrDeals.push(currentSrDeal);
            }

            deals = {
                //currentDeals: currentDeals.sort((a, b) => ('' + a.symbol).localeCompare(b.symbol)),
                currentEma20Deals,
                currentRsiUp30Deals,
                currentRsiDown30Deals,
                currentEma200Deals,
                currentEma100Deals,
                currentSrDeals
            };
        }

        return true;
    });

    return deals;
}

export const allCurrencyProfitLossGroupAndFilter = (allPositions, simulator, analysisModel) => {
    let allProfitLossList = []; 

    const groupBySymbols = allPositions.reduce((group, position) => {
        const { symbol } = position;
        group[symbol] = group[symbol] ?? [];
        group[symbol].push(position);
        return group;
      }, {});

      Object.keys(groupBySymbols).map((symbol, i) => {
        //console.log(symbol);
        let currencyHistoricalData = {};
        /*
        groupBySymbols[symbol].map((pos, i) => {
            console.log(pos, i);
        });
        */
        //console.log(symbol, groupBySymbols[symbol]);

        let posArray = [];
        let negArray = [];
        let notrArray = [];
        let totalProfits;
        let totalLoss;
        let mostProfit;
        let mostLoss;

        if(simulator || analysisModel === "depthHill" || analysisModel === "explosionLoose") {
            posArray = groupBySymbols[symbol].filter(x => x.pnl > 0.1);
            negArray = groupBySymbols[symbol].filter(x => x.pnl < 0);
            notrArray = groupBySymbols[symbol].filter(x => x.pnl <= 0.1 && x.pnl >= 0);
            totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
            mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
        }
        else if(analysisModel === "rsiDivergence") {
            posArray = groupBySymbols[symbol].filter(x => x.pnl > 0);
            negArray = groupBySymbols[symbol].filter(x => x.pnl < 0);
            notrArray = groupBySymbols[symbol].filter(x => x.pnl === 0);
            totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
            mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
        }
        else {
            posArray = groupBySymbols[symbol].filter(x => x.mostProfit > 1);
            negArray = groupBySymbols[symbol].filter(x => x.mostProfit <= 1);
            totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => a + b).toFixed(2) : 0;
            totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
            mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
        }
        
        currencyHistoricalData.key = symbol;
        currencyHistoricalData.symbol = symbol;
        currencyHistoricalData.totalPosProcess = posArray.length;
        currencyHistoricalData.totalNegProcess = negArray.length;
        currencyHistoricalData.totalNotrProcess = notrArray.length;
        currencyHistoricalData.totalProfits = totalProfits;
        currencyHistoricalData.totalLoss = totalLoss;
        currencyHistoricalData.pnlRatio = (Number(totalProfits) + Number(totalLoss)).toFixed(2);
        currencyHistoricalData.mostProfit = mostProfit;
        currencyHistoricalData.mostLoss = mostLoss;

        allProfitLossList.push(currencyHistoricalData);
      });

      //console.log('all profit loss list', allProfitLossList);

      return allProfitLossList;
}

const calculatePositions = (kline, prevKlines, beforeComparison, currentDeal, analysisModel, supports, lastPivot, testText) => {
    let comparisonResult = {};
    //console.log('POS TYPE', currentDeal.posType);
    let prevKline;
    let twoPrevKline;
    if(analysisModel === "rsiDivergence") {
        prevKline = [...prevKlines][prevKlines.length - 1];
        twoPrevKline = [...prevKlines][prevKlines.length - 2];
    }
    else {
        prevKline = [...prevKlines][prevKlines.length - 1];
        twoPrevKline = [...prevKlines][prevKlines.length - 2];
    }


    //let btcPercentage = calculatePercentage(currentBtcKline);
    //console.log('BTC Percentage', btcPercentage);

    let lastKlinePercentage = kline.change;

    let bollBandwidthPercentage = Number(((kline.indicators.bollinger.upper - kline.indicators.bollinger.lower) * 100 / kline.indicators.bollinger.lower).toFixed(2));
    let prevBollBandwidthPercentage = Number(((prevKline.indicators.bollinger.upper - prevKline.indicators.bollinger.lower) * 100 / prevKline.indicators.bollinger.lower).toFixed(2));
    let twoPrevBollBandwidthPercentage = Number(((twoPrevKline.indicators.bollinger.upper - twoPrevKline.indicators.bollinger.lower) * 100 / twoPrevKline.indicators.bollinger.lower).toFixed(2));

    let topNeedleVolumeComparison = kline.candlestick.high - kline.candlestick.close < kline.candlestick.close - kline.candlestick.open; 

    /**********************************/
    /******** CLOSE MODELS ************/
    /**********************************/
    // CP-br: mum kapanışı, boll alt bandından aşağı taşarsa. (Sadece openLongBollRsi için geçerli bir tedbir kapanışı.)
    const closeLongBollRsi = kline.candlestick.close < kline.indicators.bollinger.bbLow;
    
    // CP-h1: Güncel fiyat, ulaştığı en yüksek fiyattan %1 geri döndüyse;
    const closeLongHp1 = kline.candlestick.low < currentDeal.highPrice * 0.99;  
       
    // CP-h2: Güncel fiyat, ulaştığı en yüksek fiyattan %2 geri döndüyse;
    const closeLongHp2 = kline.candlestick.low < currentDeal.highPrice * 0.98;  
    
    // CP-h3: Güncel fiyat, ulaştığı en yüksek fiyattan %3 geri döndüyse;
    const closeLongHp3 = kline.candlestick.low < currentDeal.highPrice * 0.97;  
    
    // CP-h3: Güncel fiyat, ulaştığı en yüksek fiyattan %3 geri döndüyse;
    const closeShortHp3 = kline.candlestick.high > currentDeal.highPrice * 1.03;  

    // CP-h4: Güncel fiyat, ulaştığı en yüksek fiyattan %4 geri döndüyse;
    const closeLongHp4 = kline.candlestick.low < currentDeal.highPrice * 0.96;

    // CP-h5: Güncel fiyat, ulaştığı en yüksek fiyattan %5 geri döndüyse;
    const closeLongHp5 = kline.candlestick.low < currentDeal.highPrice * 0.95;
    
    const closeShortHp5 = kline.candlestick.high > currentDeal.highPrice * 1.05;

    // CP-h6: Güncel fiyat, ulaştığı en yüksek fiyattan %6 geri döndüyse;
    const closeLongHp6 = kline.candlestick.low < currentDeal.highPrice * 0.94;

    // CP-r2: son mum kırmızı VE Mum yüzdesi ortalama %2 ise;
    const closeLongRed2 = kline.candlestick.color === 'red' && lastKlinePercentage < -2.3;

    // CP-o1: son mum kapanışı, açılış fiyatından %1 geri döndüyse;
    const closeLongOp1 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.99;
    
    // CP-o2: son mum kapanışı, açılış fiyatından %2 geri döndüyse;
    const closeLongOp2 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.98;
        
    // CP-o3: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeLongOp3 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.97;

    // CP-o3: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeShortOp3 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.high > currentDeal.openPrice * 1.03;

    // CP-o4: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeLongOp4 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.96;    
    
    // CP-o5: son mum kapanışı, açılış fiyatından %5 geri döndüyse;
    const closeLongOp5 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.95;

    // CP-o6: son mum kapanışı, açılış fiyatından %6 geri döndüyse;
    const closeLongOp6 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.94;
        
    // CP-o10: son mum kapanışı, açılış fiyatından %10 geri döndüyse;
    const closeLongOp10 = currentDeal.openPrice === currentDeal.highPrice && kline.candlestick.low < currentDeal.openPrice * 0.90;
    
    // CP-p1: son mum kapanışı, açılış fiyatından %1 yükseğe çıktığında;
    const closeLongMp1 = kline.candlestick.high > currentDeal.openPrice * 1.01;

    // CP-Cc10: 10 mum boyunca %1 kar üstüne cıkamadıysa kapanır.
    const closeLongCc10 = currentDeal.candleCount >= 10 && currentDeal.highPrice < currentDeal.openPrice * 1.01;

    let currentTopNeedle = currentDeal.topNeedle > kline.candlestick.high ? currentDeal.topNeedle : kline.candlestick.high;
    let currentBottomNeedle = kline.candlestick.low < currentDeal.topNeedle ? kline.candlestick.low : currentDeal.topNeedle;    

    let currentMostProfit = Number(((currentTopNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2)); 
    
    const closeLongOp2d = currentDeal.openPrice === currentTopNeedle && kline.candlestick.low < currentDeal.openPrice * 0.98;

    const closeLongHp2d = currentMostProfit <= 3 && currentMostProfit > 0 && kline.candlestick.low <= currentTopNeedle * 0.95;

    const closeLongNotr = false; //currentMostProfit > 1 && kline.candlestick.low <= currentTopNeedle * 0.98;
    
    const closeLongTS1 = currentMostProfit > 3 && kline.candlestick.low <= currentTopNeedle * 0.97;

    const closeLongTS2 = false; //currentMostProfit > 3 && currentMostProfit < 5 && kline.candlestick.low <= currentTopNeedle * 0.98;

    const closeLongTS5 = false; //currentMostProfit >= 5 && currentMostProfit < 10 && kline.candlestick.low <= currentTopNeedle * 0.97;

    const closeLongTS10 = false; //currentMostProfit >= 10 && kline.candlestick.close <= currentTopNeedle * 0.96;

    //const closeLongDh = calculateCloseLongDepthHill(kline, prevKline, twoPrevKline, currentDeal, supports);
    const closeLongDh = calculateCloseLongDepthHillNew(kline, prevKline, twoPrevKline, currentDeal, supports);

    //const closeShortDh = calculateCloseShortDepthHill(kline, prevKline, twoPrevKline, currentDeal, supports);
    const closeShortDh = calculateCloseShortDepthHillNew(kline, prevKline, twoPrevKline, currentDeal, supports);

    //const closeLongRd1 = kline.candlestick.high > currentDeal.openPrice * 1.004;
    const closeLongRd1 = kline.candlestick.high > currentDeal.openPrice * 1.01;

    const closeLongRd2 = kline.candlestick.low < currentDeal.openPrice * 0.99;

    const closeLongRd3 = kline.candlestick.low < currentDeal.openPrice && currentDeal.topNeedle > currentDeal.openPrice * 1.005;

    const closeLongRdTrailing = currentTopNeedle >= currentDeal.openPrice * 1.002 && kline.candlestick.close <= currentTopNeedle * 0.998;

    //const closeShortRd1 = kline.candlestick.low < currentDeal.openPrice * 0.996;
    const closeShortRd1 = kline.candlestick.low < currentDeal.openPrice * 0.99;

    const closeShortRd2 = kline.candlestick.high > currentDeal.openPrice * 1.01;

    const closeShortRd3 = kline.candlestick.high > currentDeal.openPrice && currentDeal.topNeedle < currentDeal.openPrice * 0.995;

    const closeShortRdTrailing = currentBottomNeedle <= currentDeal.openPrice * 0.998 && kline.candlestick.close >= currentBottomNeedle * 1.002;

    const closeTimeLongRd = currentDeal.candleCount >= posTimeLimit;// && kline.candlestick.close < currentDeal.openPrice;
    const closeTimeShortRd = currentDeal.candleCount >= posTimeLimit; // && kline.candlestick.close > currentDeal.openPrice;

    /**********************************/

    if(beforeComparison === "CLOSE" || beforeComparison === "" || beforeComparison === "LONGCLOSE") {
        // önceki poz close ise;  
        // KARMA BUNDLE ya da olarak eklenemez çünkü diğer else if leri devre dışı bırakıyor!!!!
        if(analysisModel === "explosionTight") {
            // OL-eT:
            let openLongExplosionTight = calculateLongExplosionTight(kline, prevKline, calculatePrevKlines(prevKlines, "explosionTight"), 50, 63);

            if(openLongExplosionTight) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eT"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }  
        else if(analysisModel === "explosion4560") {
            // OL-ex 45:
            let openLongExplosion4560 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 45, 60);
    
            if(openLongExplosion4560) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex45"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosion5065") {
            // OL-ex 50:
            let openLongExplosion5065 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 50, 65);
        
            if(openLongExplosion5065) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex50"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosion") {
            // OL-ex:
            let openLongExplosion = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 55, 100);

            if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "percent3") {
            // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
            let openLongPercent3 = calculateLongPercent3(kline, prevKline);
    
            if(openLongPercent3) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-p3"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else if(analysisModel === "explosionPercent3") {
            // OL-ex:
            let openLongExplosion = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 55, 100);
            // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
            let openLongPercent3 = calculateLongPercent3(kline, prevKline);

            if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else if(openLongPercent3) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-p3"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosionBundle") {
            // OL-ex:
            let openLongExplosion = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 55, 100);
            // OL-ex 50:
            let openLongExplosion5065 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 50, 65);
            // OL-ex 45:
            let openLongExplosion4560 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 45, 60);
            // OL-eL:
            let openLongExplosionLoose = calculateLongExplosionLoose(kline, prevKline, calculatePrevKlines(prevKlines, "explosionLoose"));
            // OL-eT:
            let openLongExplosionTight = calculateLongExplosionTight(kline, prevKline, calculatePrevKlines(prevKlines, "explosionTight"), 50, 63);

            if(openLongExplosionTight) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eT"; 
            }
            else if(openLongExplosion4560) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex45"; 
            }
            else if(openLongExplosion5065) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex50"; 
            }
            else if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else if(openLongExplosionLoose) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eL"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "karmaBundle") {
            // OL-ex:
            let openLongExplosion = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 55, 100);
            // OL-ex 50:
            let openLongExplosion5065 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 50, 65);
            // OL-ex 45:
            let openLongExplosion4560 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 45, 60);
            // OL-eL:
            let openLongExplosionLoose = calculateLongExplosionLoose(kline, prevKline, calculatePrevKlines(prevKlines, "explosionLoose"));
            // OL-eT:
            let openLongExplosionTight = calculateLongExplosionTight(kline, prevKline, calculatePrevKlines(prevKlines, "explosionTight"), 50, 63);
            // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
            let openLongPercent3 = calculateLongPercent3(kline, prevKline);
            // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
            let openLongBollRsi = calculateLongBollRsi(kline, prevKline, twoPrevKline);
            // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
            let openLongBollBands = calculateLongBollBandwidth(kline, prevKline, twoPrevKline, bollBandwidthPercentage, twoPrevBollBandwidthPercentage);
       
            if(openLongExplosionTight) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eT"; 
            }
            else if(openLongExplosion4560) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex45"; 
            }
            else if(openLongExplosion5065) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex50"; 
            }
            else if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else if(openLongPercent3) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-p3"; 
            }
            else if(openLongBollBands) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-bb"; 
            }
            else if(openLongExplosionLoose) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eL"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "bollRsi") {
            // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
            let openLongBollRsi = calculateLongBollRsi(kline, prevKline, twoPrevKline);
    
            if(openLongBollRsi) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-br"; 
            }
            else {
                comparisonResult.position = "";
            }           
        }
        else if(analysisModel === "bollBandwidth") {
            // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
            let openLongBollBands = calculateLongBollBandwidth(kline, prevKline, twoPrevKline, bollBandwidthPercentage, twoPrevBollBandwidthPercentage);
       
            if(openLongBollBands) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-bb"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else if(analysisModel === "explosionLoose") {
            // OL-eL:
            let openLongExplosionLoose = calculateLongExplosionLoose(kline, prevKline, calculatePrevKlines(prevKlines, "explosionLoose"));
    
            if(openLongExplosionLoose) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eL"; 
            }
            else {
                comparisonResult.position = comparisonResult;
            }
        }
        else if(analysisModel === "zombieSpot") {
            // OL-zs:
            const openLongZombieSpot = calculateLongZombieSpot(kline, prevKline, calculatePrevKlines(prevKlines, "zombieSpot"), 50, 63);

            if(openLongZombieSpot) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-zs"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else if(analysisModel === "depthHill") {
            // OL-dh:
            //const openLongDepthHill = calculateLongDepthHill(kline, prevKline, twoPrevKline, prevKlines, supports);
            let openLongDepthHill = calculateLongDepthHillNew(kline, prevKline, twoPrevKline, prevKlines, supports);

            // OS-dh:
            //const openShortDepthHill = calculateShortDepthHill(kline, prevKline, prevKlines, supports);
            let openShortDepthHill = calculateShortDepthHillNew(kline, prevKline, twoPrevKline, prevKlines, supports);

            if(openLongDepthHill.condition) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-dh" + openLongDepthHill.type; 
                comparisonResult.supportLine = openLongDepthHill.supportLine;
            }
            else if(openShortDepthHill.condition) {
                // Short açılışı fonksiyonları kontrol edilecek.
                comparisonResult.position = "SHORT";
                comparisonResult.posType = "OS-dh" + openShortDepthHill.type; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }   
                    
        }
        else if(analysisModel === "rsiDivergence") {
            let openLongRsiDivergence = calculateLongRsiDivergence15(kline, prevKline, twoPrevKline, prevKlines, lastPivot);

            let openShortRsiDivergence = calculateShortRsiDivergence15(kline, prevKline, twoPrevKline, prevKlines, lastPivot);
        
            if(openLongRsiDivergence) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-rd"; 
            }
            else if(openShortRsiDivergence) {
                comparisonResult.position = "SHORT";
                comparisonResult.posType = "OS-rd";                 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(currentDeal.posType === "OL-p3" || currentDeal.posType === "OL-bb") {
                if(closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }/*
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }*/
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else if(currentDeal.posType === "OL-br") {
                if(closeLongBollRsi || closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }/*
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }*/
                    else if (closeLongBollRsi) {
                        comparisonResult.closeType = "CP-br";
                    }
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                } 
            }
            else if(currentDeal.posType === "OL-ex" || currentDeal.posType === "OL-eL" || currentDeal.posType === "OL-eT" || currentDeal.posType === "OL-ex50" || currentDeal.posType === "OL-ex45") {
                //if(closeLongOp2d || closeLongHp2d || closeLongNotr || closeLongTS10 || closeLongTS5 || closeLongTS2 || closeLongTS1) {
                if(closeLongHp3 || closeLongOp3) {
                    //console.log('close pos', currentTopNeedle, currentMostProfit, currentDeal.openPrice);
                    //console.log(`TS2: ${closeLongTS2}, TS5: ${closeLongTS5}, TS10: ${closeLongTS10}, Close: ${kline.candlestick.close} %4: ${currentTopNeedle * 0.96}`);
                    comparisonResult.position = "CLOSE";

                    /*
                    if(closeLongOp2d) {
                        comparisonResult.closeType = "CP-o2";
                    }
                    else if(closeLongHp2d) {
                        comparisonResult.closeType = "CP-h2";
                    }
                    else if(closeLongNotr) {
                        comparisonResult.closeType = "CP-ntr";
                    }
                    else if(closeLongTS10) {
                        comparisonResult.closeType = "CP-TS10";
                    }
                    else if(closeLongTS5) {
                        comparisonResult.closeType = "CP-TS5";
                    }
                    else if(closeLongTS2) {
                        comparisonResult.closeType = "CP-TS2";
                    }
                    else if(closeLongTS1) {
                        comparisonResult.closeType = "CP-TS1";
                    }*/
                    
                    if(closeLongHp3) {
                        comparisonResult.closeType = "CP-h3";
                    }
                    else if (closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                } 
            }
            else if(currentDeal.posType === "OL-zs") {
                if(closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else if(currentDeal.posType.includes("OL-dh")) {
                /*if(closeLongHp3 || closeLongOp3) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp3) {
                        comparisonResult.closeType = "CP-h3";
                    }
                    else if (closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }*/

                if(closeLongDh.condition) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-dh" + closeLongDh.type;
                }
                else {
                    comparisonResult.position = beforeComparison;
                }   
            }
            else if(currentDeal.posType === "OL-rd") {
                if(closeLongRdTrailing || closeLongRd2 || closeTimeLongRd) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongRdTrailing) {
                        comparisonResult.closeType = "CP-rd-t";
                    }
                    else if (closeLongRd2) {
                        comparisonResult.closeType = "CP-rd2";
                    }
                    else if(closeTimeLongRd) {
                        comparisonResult.closeType = "CP-rd0";
                    }/*
                    else if(closeLongRd3) {
                        comparisonResult.closeType = "CP-rd3";
                    }*/
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }/*
            else if(currentDeal.posType === "OL-rd") {
                if(closeLongHp3 || closeLongOp3) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp3) {
                        comparisonResult.closeType = "CP-h3";
                    }
                    else if(closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }*/    
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(beforeComparison === "SHORT") {
            // short için kapanış fonksiyonları eklenecek.
            if(currentDeal.posType.includes("OS-dh")) {
                if(closeShortDh.condition) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-dh" + closeShortDh.type;
                }
                else {
                    comparisonResult.position = beforeComparison;
                }  
            }
            else if(currentDeal.posType === "OS-rd") {
                if(closeShortRdTrailing || closeShortRd2 || closeTimeShortRd) {
                    comparisonResult.position = "CLOSE";
                    if(closeShortRdTrailing) {
                        comparisonResult.closeType = "CP-rd-t";
                    }
                    else if (closeShortRd2) {
                        comparisonResult.closeType = "CP-rd2";
                    }
                    else if(closeTimeShortRd) {
                        comparisonResult.closeType = "CP-rd0";
                    }/*
                    else if (closeShortRd3) {
                        comparisonResult.closeType = "CP-rd3";
                    }*/
                }/*
                else if(currentDeal.posType === "OL-rd") {
                    if(closeShortHp3 || closeShortOp3) {
                        comparisonResult.position = "CLOSE";
                        if(closeShortHp3) {
                            comparisonResult.closeType = "CP-h3";
                        }
                        else if(closeShortOp3) {
                            comparisonResult.closeType = "CP-o3";
                        }
                    }
                    else {
                        comparisonResult.position = beforeComparison;
                    }       
                }*/ 
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }

    return comparisonResult;
}

const calculateLongBollRsi = (kline, prevKline, twoPrevKline) => {
    // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
    const openLongBollRsi = ((twoPrevKline.candlestick.close < twoPrevKline.indicators.bollinger.bbLow || twoPrevKline.candlestick.open < twoPrevKline.indicators.bollinger.lower) && 
                             twoPrevKline.indicators.rsi < 30) &&
                            (prevKline.candlestick.color === 'green' && prevKline.candlestick.close > prevKline.indicators.bollinger.lower && prevKline.indicators.rsi > 30) &&
                            (kline.candlestick.color === 'green' && kline.candlestick.close > kline.indicators.bollinger.lower && kline.candlestick.open > kline.indicators.bollinger.lower && kline.indicators.rsi > 30);
    
    return openLongBollRsi;
}

const calculateLongBollBandwidth = (kline, prevKline, twoPrevKline, bollBandwidthPercentage, twoPrevBollBandwidthPercentage) => {
    // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
    const openLongBollBands = twoPrevKline.candlestick.color === 'green' &&
                              prevKline.candlestick.color === 'green' &&
                              kline.candlestick.color === 'green' &&
                              kline.candlestick.close > kline.indicators.bollinger.middle &&
                              kline.candlestick.change < 4 &&
                              ((twoPrevBollBandwidthPercentage < 3 && bollBandwidthPercentage > 3) ||
                              (twoPrevBollBandwidthPercentage < 4 && bollBandwidthPercentage > 4));

    return openLongBollBands;
}

const calculateLongPercent3 = (kline, prevKline) => {
    // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
    let openLongPercent3 = kline.candlestick.change > 2.8;
    return openLongPercent3;
}

const calculateLongExplosion = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    const openLongExplosion = prevKlines.klinesPercentage.length === 5 &&
                              prevKlines.parallelEq === 4 &&
                              kline.indicators.ema20 > kline.indicators.ema50 &&
                              kline.indicators.ema20 > kline.indicators.ema100 &&
                              kline.indicators.ema20 > kline.indicators.ema200 &&
                              kline.candlestick.close > kline.indicators.ema20 &&
                              kline.candlestick.open > kline.indicators.ema50 &&
                              kline.candlestick.color === 'green' &&
                              kline.candlestick.close > kline.indicators.bollinger.middle &&
                              kline.indicators.bollinger.upper > prevKline.indicators.bollinger.upper &&
                              kline.indicators.rsi > rsiOne && kline.indicators.rsi < rsiTwo &&
                              kline.candlestick.change > 0.9 && kline.candlestick.change < 1.5;

    return openLongExplosion;
}

// TODO: buradaki değerlerle biraz oyna bakalım.
const calculateLongExplosionLoose = (kline, prevKline, prevKlines) => {
    //console.log('calculateLongExplosionLoose', prevKlines.prevs, prevKlines.maxTopPrice, kline.candlestick.close);
    const openLongExplosionLoose = prevKlines.klinesPercentage.length === 5 &&
                                   prevKlines.maxTopPrice < kline.candlestick.close &&
                                   kline.candlestick.close > kline.indicators.ema20 &&
                                   kline.candlestick.color === 'green' &&
                                   kline.candlestick.close > kline.indicators.bollinger.middle &&
                                   kline.indicators.bollinger.upper > prevKline.indicators.bollinger.upper &&
                                   kline.indicators.rsi > 55 &&
                                   kline.candlestick.change > 0.9;
    return openLongExplosionLoose;
}

const calculateLongExplosionTight = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    //console.log('calculateLongExplosionTight', kline, prevKline, prevKlinesPercentage, greenPrevKlines, prevKlinesAverageWidth);
    const openLongExplosionTight = prevKlines.klinesPercentage.length === 5 &&
                              prevKlines.greenKlinesCount < 5 &&
                              prevKlines.klinesAverageWidth < 1 &&
                              //parallelEq === 4 &&
                              kline.indicators.ema20 > kline.indicators.ema50 &&
                              kline.indicators.ema20 > kline.indicators.ema100 &&
                              kline.indicators.ema20 > kline.indicators.ema200 &&
                              kline.candlestick.close > kline.indicators.ema20 &&
                              kline.candlestick.open > kline.indicators.ema50 &&
                              kline.candlestick.color === 'green' &&
                              kline.candlestick.close > kline.indicators.bollinger.middle &&
                              kline.indicators.bollinger.upper > prevKline.indicators.bollinger.upper &&
                              kline.indicators.rsi > rsiOne && kline.indicators.rsi < rsiTwo &&
                              kline.candlestick.change > 1.1 && kline.candlestick.change < 1.5;

    return openLongExplosionTight;
}

const calculateLongZombieSpot = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    //console.log('calculateLongExplosionTight', kline, prevKline, prevKlinesPercentage, greenPrevKlines, prevKlinesAverageWidth);
    const openLongZombieSpot = prevKlines.klinesPercentage.length === spotPrevKlines &&
                               prevKlines.parallelEq === spotPrevKlines - 1;
    return openLongZombieSpot;
}

const calculateLongDepthHillNew = (kline, prevKline, twoPrevKline, prevKlines, supports) => {
    let activeLines = supports.filter(l => l.price >= prevKline.candlestick.open && l.price < prevKline.candlestick.close && l.strength > 1);
    let linePrices = activeLines.map(x => x.price);
    let smallestLine = activeLines.length > 0 ? Math.min(...linePrices) : 0;

    const oldh1 = twoPrevKline.candlestick.color === 'red' &&
                  prevKline.candlestick.color === 'green' &&
                  kline.candlestick.color === 'green' &&
                  smallestLine > 0 &&
                  hasActiveLine(smallestLine, twoPrevKline.candlestick.open, twoPrevKline.candlestick.close);// &&
                  //prevKline.candlestick.close < prevKline.indicators.ema50 &&
                  //prevKline.candlestick.close < prevKline.indicators.ema21;

    let condition = false;
    let type = '';

    if(oldh1) {
        condition = true;
        type = 1;
    }
    else {
        condition = false;
        type = "";
    }

    return { condition: condition, type: type, supportLine: smallestLine };
}

const calculateCloseLongDepthHillNew = (kline, prevKline, twoPrevKline, currentDeal, supports) => {
    const cldh1 = currentDeal.topNeedle >= currentDeal.openPrice * 1.01 &&
                  kline.candlestick.close * 1.01 <= currentDeal.topNeedle;
    
    const cldh2 = kline.candlestick.low < currentDeal.openPrice * 0.98;

    let condition = false;
    let type = '';

    if(cldh1) {
        condition = true;
        type = 1;        
    }
    else if(cldh2) {
        condition = true;
        type = 2;
    }

    return { condition: condition, type: type};
}

const calculateShortDepthHillNew = (kline, prevKline, twoPrevKline, prevKlines, supports) => {
    let activeLines = supports.filter(l => l.price > prevKline.candlestick.close && l.price <= prevKline.candlestick.open && l.strength > 1);
    let linePrices = activeLines.map(x => x.price);
    let smallestLine = activeLines.length > 0 ? Math.min(...linePrices) : 0;

    const osdh1 = twoPrevKline.candlestick.color === 'green' &&
                  prevKline.candlestick.color === 'red' &&
                  kline.candlestick.color === 'red' &&
                  smallestLine > 0 &&
                  hasActiveLine(smallestLine, twoPrevKline.candlestick.close, twoPrevKline.candlestick.open);// &&
                  //prevKline.candlestick.close > prevKline.indicators.ema50 &&
                  //prevKline.candlestick.close > prevKline.indicators.ema21;

    let condition = false;
    let type = '';


    if(osdh1) {
        condition = true;
        type = 1;
    }
    else {
        condition = false;
        type = "";
    }
    return { condition: condition, type: type};
}

const calculateCloseShortDepthHillNew = (kline, prevKline, twoPrevKline, currentDeal, supports) => {
    const csdh1 = currentDeal.topNeedle <= currentDeal.openPrice * 0.99 &&
                  kline.candlestick.close * 0.99 >= currentDeal.topNeedle;

    const csdh2 = kline.candlestick.high > currentDeal.openPrice * 1.02;

    let condition = false;
    let type = '';

    if(csdh1) {
        condition = true;
        type = 1;        
    }
    else if(csdh2) {
        condition = true;
        type = 2;
    }

    return { condition: condition, type: type};
}

const calculateLongRsiDivergence = (kline, prevKline, twoPrevKline, prevKlines, lastPivot) => {
    //prevKlines.pop();
    let prevRsiValues = prevKlines.slice(0, pivotValue).map(c => c.indicators.rsi);

    //console.log('prevRsi Length', prevRsiValues.length, "  -  ", prevRsiValues.join(","), " -  ", prevKline.indicators.rsi);

    let lowestRsi = Math.min(...[...prevRsiValues]);

    let tightControl = prevKline.candlestick.color === "green" &&
                       twoPrevKline.candlestick.color === "red" &&
                       twoPrevKline.indicators.rsi <= lowestRsi &&
                       prevKline.candlestick.close < twoPrevKline.candlestick.open &&
                       twoPrevKline.candlestick.pivot === "low" &&
                       prevKline.candlestick.change < 0.5 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "low" && 
                       kline.candlestick.change < 0.5 &&
                       kline.candlestick.close > twoPrevKline.candlestick.close &&
                       kline.candlestick.color === "green";

    let tightRegular = lastPivot &&
                       twoPrevKline.candlestick.close < lastPivot.candlestick.close &&
                       twoPrevKline.indicators.rsi > lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi < 33;

    let tightHidden = lastPivot &&
                      twoPrevKline.candlestick.close > lastPivot.candlestick.close &&
                      twoPrevKline.indicators.rsi < lastPivot.indicators.rsi &&
                      twoPrevKline.indicators.rsi < 33;

    let looseControl = kline.candlestick.color === "green" &&
                       prevKline.candlestick.color === "red" &&
                       prevKline.indicators.rsi < lowestRsi &&
                       kline.candlestick.close < prevKline.candlestick.open &&
                       prevKline.candlestick.pivot === "low" &&
                       kline.candlestick.change < 0.5 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "low";

    let looseRegular = lastPivot &&
                       prevKline.candlestick.close < lastPivot.candlestick.close &&
                       prevKline.indicators.rsi > lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi < 33;
    
    let looseHidden = lastPivot &&
                      prevKline.candlestick.close > lastPivot.candlestick.close &&
                      prevKline.indicators.rsi < lastPivot.indicators.rsi &&
                      prevKline.indicators.rsi < 33;

    /*
    let openLongRsiDivergence = prevKline.candlestick.color === "green" &&
                                twoPrevKline.candlestick.color === "red" &&
                                prevKline.candlestick.close < twoPrevKline.candlestick.open &&
                                twoPrevKline.candlestick.pivot === "low" &&
                                prevKline.candlestick.change < 1 &&
                                kline.candlestick.change < 1 &&
                                lastPivot &&
                                lastPivot.candlestick.pivot === "low" && 
                                kline.candlestick.close > twoPrevKline.candlestick.close && //
                                //((prevKline.candlestick.close < lastPivot.candlestick.close &&
                                //prevKline.indicators.rsi > lastPivot.indicators.rsi &&
                                //lastPivot.indicators.rsi < 50) || 
                                //(prevKline.candlestick.close > lastPivot.candlestick.close &&
                                //prevKline.indicators.rsi < lastPivot.indicators.rsi &&
                                //prevKline.indicators.rsi < 50));
                                ((twoPrevKline.candlestick.close < lastPivot.candlestick.close &&
                                twoPrevKline.indicators.rsi > lastPivot.indicators.rsi &&
                                lastPivot.indicators.rsi < 50));
*/

//((prevKline.candlestick.close < lastPivot.candlestick.close &&
//prevKline.indicators.rsi > lastPivot.indicators.rsi &&
//lastPivot.indicators.rsi < 50) || 
//(prevKline.candlestick.close > lastPivot.candlestick.close &&
//prevKline.indicators.rsi < lastPivot.indicators.rsi &&
//prevKline.indicators.rsi < 50));

    return looseControl && (looseRegular || looseHidden);
    //return tightControl && (tightRegular || tightHidden);
}

const calculateShortRsiDivergence = (kline, prevKline, twoPrevKline, prevKlines, lastPivot) => {
    //prevKlines.pop();
    let prevRsiValues = prevKlines.slice(0, pivotValue).map(c => c.indicators.rsi);

    let highestRsi = Math.max(...[...prevRsiValues]);

    let tightControl = prevKline.candlestick.color === "red" &&
                       twoPrevKline.candlestick.color === "green" &&
                       twoPrevKline.indicators.rsi >= highestRsi &&
                       prevKline.candlestick.close > twoPrevKline.candlestick.open &&
                       twoPrevKline.candlestick.pivot === "high" &&
                       prevKline.candlestick.change > -0.5 &&
                       kline.candlestick.change > -0.5 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "high" && 
                       twoPrevKline.candlestick.close > kline.candlestick.close &&
                       kline.candlestick.color === "red";

    let tightRegular = lastPivot &&
                       twoPrevKline.candlestick.close > lastPivot.candlestick.close &&
                       twoPrevKline.indicators.rsi < lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi > 67;

    let tightHidden = lastPivot &&
                      twoPrevKline.candlestick.close < lastPivot.candlestick.close &&
                      twoPrevKline.indicators.rsi > lastPivot.indicators.rsi &&
                      twoPrevKline.indicators.rsi > 67;

    let looseControl = kline.candlestick.color === "red" &&
                       prevKline.candlestick.color === "green" &&
                       prevKline.indicators.rsi > highestRsi &&
                       kline.candlestick.close > prevKline.candlestick.open &&
                       prevKline.candlestick.pivot === "high" &&
                       kline.candlestick.change > -0.5 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "high";

    let looseRegular = lastPivot &&
                       prevKline.candlestick.close > lastPivot.candlestick.close &&
                       prevKline.indicators.rsi < lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi > 67;
    
    let looseHidden = lastPivot &&
                      prevKline.candlestick.close < lastPivot.candlestick.close &&
                      prevKline.indicators.rsi > lastPivot.indicators.rsi &&
                      prevKline.indicators.rsi > 67;

/*
    let openShortRsiDivergence = prevKline.candlestick.color === "red" &&
                                 twoPrevKline.candlestick.color === "green" &&
                                 prevKline.candlestick.close > twoPrevKline.candlestick.open &&
                                 twoPrevKline.candlestick.pivot === "high" &&
                                 prevKline.candlestick.change > -1 &&
                                 kline.candlestick.change > -1 &&
                                 lastPivot &&
                                 lastPivot.candlestick.pivot === "high" && 
                                 twoPrevKline.candlestick.close > kline.candlestick.close &&
                                 //((prevKline.candlestick.close > lastPivot.candlestick.close &&
                                 //prevKline.indicators.rsi < lastPivot.indicators.rsi &&
                                 //lastPivot.indicators.rsi > 50) || 
                                 //(prevKline.candlestick.close < lastPivot.candlestick.close &&
                                 //prevKline.indicators.rsi > lastPivot.indicators.rsi &&
                                 //prevKline.indicators.rsi > 50));
                                 ((twoPrevKline.candlestick.close > lastPivot.candlestick.close &&
                                 twoPrevKline.indicators.rsi < lastPivot.indicators.rsi &&
                                 lastPivot.indicators.rsi > 50));
*/
//    if(openShortRsiDivergence) {
//        console.log('SHORT', lastPivot);
//        if(testText && lastPivot) {
//            console.log('SHORT', testText, lastPivot.candlestick.localOpenTime, lastPivot.candlestick.close, lastPivot.indicators.rsi, prevKline.candlestick.localOpenTime, prevKline.candlestick.close, prevKline.indicators.rsi)
//        }
//    }
//((prevKline.candlestick.close > lastPivot.candlestick.close &&
//prevKline.indicators.rsi < lastPivot.indicators.rsi &&
//lastPivot.indicators.rsi > 50) || 
//(prevKline.candlestick.close < lastPivot.candlestick.close &&
//prevKline.indicators.rsi > lastPivot.indicators.rsi &&
//prevKline.indicators.rsi > 50));                 
    return looseControl && (looseRegular || looseHidden);
    //return tightControl && (tightRegular || tightHidden);
}


const calculateLongRsiDivergence15 = (kline, prevKline, twoPrevKline, prevKlines, lastPivot) => {
    let prevRsiValues = prevKlines.slice(0, pivotValue).map(c => c.indicators.rsi);

    let lowestRsi = Math.min(...[...prevRsiValues]);

    let tightControl = prevKline.candlestick.color === "green" &&
                       twoPrevKline.candlestick.color === "red" &&
                       kline.candlestick.color === "green" &&
                       twoPrevKline.indicators.rsi <= lowestRsi &&
                       prevKline.candlestick.close < twoPrevKline.candlestick.open &&
                       twoPrevKline.candlestick.pivot === "low" &&
                       prevKline.candlestick.change < 1 &&
                       kline.candlestick.change < 1 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "low" && 
                       kline.candlestick.close > twoPrevKline.candlestick.close;

    let tightRegular = lastPivot &&
                       twoPrevKline.candlestick.close < lastPivot.candlestick.close &&
                       twoPrevKline.indicators.rsi > lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi < 33;

    let tightHidden = lastPivot &&
                      twoPrevKline.candlestick.close > lastPivot.candlestick.close &&
                      twoPrevKline.indicators.rsi < lastPivot.indicators.rsi &&
                      twoPrevKline.indicators.rsi < 33;

    let looseControl = kline.candlestick.color === "green" &&
                       prevKline.candlestick.color === "red" &&
                       prevKline.indicators.rsi < lowestRsi &&
                       kline.candlestick.close < prevKline.candlestick.open &&
                       prevKline.candlestick.pivot === "low" &&
                       //kline.candlestick.change < 0.5 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "low";

    let looseRegular = lastPivot &&
                       prevKline.candlestick.close < lastPivot.candlestick.close &&
                       prevKline.indicators.rsi > lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi < 33;
    
    let looseHidden = lastPivot &&
                      prevKline.candlestick.close > lastPivot.candlestick.close &&
                      prevKline.indicators.rsi < lastPivot.indicators.rsi &&
                      prevKline.indicators.rsi < 33;

    if(rsiDivergenceType === "tight") {
        return tightControl && (tightRegular || tightHidden);
    }
    else {
        return looseControl && (looseRegular || looseHidden);
    }
}

const calculateShortRsiDivergence15 = (kline, prevKline, twoPrevKline, prevKlines, lastPivot) => {
    let prevRsiValues = prevKlines.slice(0, pivotValue).map(c => c.indicators.rsi);

    let highestRsi = Math.max(...[...prevRsiValues]);

    let tightControl = prevKline.candlestick.color === "red" &&
                       twoPrevKline.candlestick.color === "green" &&
                       kline.candlestick.color === "red" &&
                       twoPrevKline.indicators.rsi >= highestRsi &&
                       prevKline.candlestick.close > twoPrevKline.candlestick.open &&
                       twoPrevKline.candlestick.pivot === "high" &&
                       prevKline.candlestick.change > -1 &&
                       kline.candlestick.change > -1 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "high" && 
                       twoPrevKline.candlestick.close > kline.candlestick.close;

    let tightRegular = lastPivot &&
                       twoPrevKline.candlestick.close > lastPivot.candlestick.close &&
                       twoPrevKline.indicators.rsi < lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi > 67;

    let tightHidden = lastPivot &&
                      twoPrevKline.candlestick.close < lastPivot.candlestick.close &&
                      twoPrevKline.indicators.rsi > lastPivot.indicators.rsi &&
                      twoPrevKline.indicators.rsi > 67;

    let looseControl = kline.candlestick.color === "red" &&
                       prevKline.candlestick.color === "green" &&
                       prevKline.indicators.rsi > highestRsi &&
                       kline.candlestick.close > prevKline.candlestick.open &&
                       prevKline.candlestick.pivot === "high" &&
                       //kline.candlestick.change > -0.5 &&
                       lastPivot &&
                       lastPivot.candlestick.pivot === "high";

    let looseRegular = lastPivot &&
                       prevKline.candlestick.close > lastPivot.candlestick.close &&
                       prevKline.indicators.rsi < lastPivot.indicators.rsi &&
                       lastPivot.indicators.rsi > 67;
    
    let looseHidden = lastPivot &&
                      prevKline.candlestick.close < lastPivot.candlestick.close &&
                      prevKline.indicators.rsi > lastPivot.indicators.rsi &&
                      prevKline.indicators.rsi > 67;
              
    if(rsiDivergenceType === "tight") {
        return tightControl && (tightRegular || tightHidden);
    }
    else {
        return looseControl && (looseRegular || looseHidden);
    }
}







const calculateLongDepthHill = (kline, prevKline, twoPrevKline, prevKlines, supports) => {
    // pop shift bişi yapılacak son gelen çıkmalı
    let prevClosePrices = prevKlines.map(p => p.candlestick.close);
    let prevOpenPrices = prevKlines.map(p => p.candlestick.open);
    let prevPerc = prevKline.candlestick.change;
    let currentPerc = kline.candlestick.change;

    let minClosePrice = Math.min(...prevClosePrices);
    let maxClosePrice = Math.max(...prevClosePrices);
    //console.log(kline, prevKline, prevKlines, supports);

    let mostLowPrice = Math.min(...[...prevClosePrices, ...prevOpenPrices]);
    let mostHighPrice = Math.max(...[...prevClosePrices, ...prevOpenPrices]);

    let sortedSupports = supports.sort((a, b) => a.price - b.price);
    //green
    let activeLine1 = sortedSupports.filter(s => s.price >= kline.candlestick.open && s.price <= kline.candlestick.close); 
    //red
    let activeLine2 = sortedSupports.filter(s => s.price <= kline.candlestick.open && s.price >= kline.candlestick.close);
    let activePrevLine = sortedSupports.filter(s => s.price >= prevKline.candlestick.open && s.price <= prevKline.candlestick.close);
    //let activeTwoPrevLine = sortedSupports.filter(s => s.price >= twoPrevKline.candlestick.open && s.price <= twoPrevKline.candlestick.close);

    let prevObvComparison = prevKlines.filter(k => k.indicators.obv > k.indicators.obvMa21);

    let condition = false;
    let type = '';

    const oldh1 = prevObvComparison.length === 0 &&
                  kline.indicators.obv > kline.indicators.obvMa21;

    /*const oldh1x = kline.candlestick.color === 'green' &&
                  prevKline.candlestick.color === 'red' &&
                  prevKline.candlestick.close <= minClosePrice;*/
    const oldh1l = prevKlinesPercentages(prevKlines) === prevKlines.length &&
                  mostLowPrice * 1.02 > mostHighPrice &&
                  mostHighPrice < kline.candlestick.close; //&&
                  //kline.candlestick.close > kline.indicators.ema20;
                  //minClosePrice * 1.02 <= maxClosePrice;


    /*
        Mum yeşil, önceki mum kırmızı, 
        önceki mum kendinden önceki 15 mumun en düşük fiyatlısı
        aktif mum en az bir çizgi üzerinden geçiyor.
        önceki mum, aktif çizgiye temas halinde.
    */

    
    const oldh1x = kline.candlestick.color === 'green' &&
                  prevKline.candlestick.color === 'red' &&
                  prevKline.candlestick.close <= minClosePrice && // orj. 15 olmalı
                  activeLine1.length > 0 &&
                  isOnActiveLine(activeLine1[0]?.price, prevKline.candlestick.open, prevKline.candlestick.close) &&
                  prevKline.indicators.rsi <= 50;// &&
                  //calculateBetweenPercentage(maxClosePrice, minClosePrice) > 2.5;// &&
                  //prevKline.indicators.rsi <= 35;// &&
                  //kline.indicators.rsi >= prevKline.indicators.rsi + 6;// &&
                  //currentPerc > (-1 * prevPerc);
    /*
    const oldh3 = prevKline.candlestick.color === 'green' &&
                  twoPrevKline.candlestick.color === 'red' &&
                  twoPrevKline.candlestick.close <= minClosePrice &&
                  activePrevLine.length > 0 &&
                  isOnActiveLine(twoPrevKline, activePrevLine[0]?.price) &&
                  twoPrevKline.indicators.rsi < 33 &&
                  kline.candlestick.color === 'green';// &&
                  //kline.indicators.rsi >= prevKline.indicators.rsi + 6;// &&
                  //currentPerc > (-1 * prevPerc);              
                 
    const oldh4 = kline.candlestick.color === 'red' &&
                  //prevKline.candlestick.close < prevKline.candlestick.open &&
                  //prevKline.candlestick.close <= minClosePrice &&
                  activeLine2.length > 0 &&
                  //isOnActiveLine(prevKline, activeLine[0]?.price) &&
                  kline.indicators.rsi < 30;// &&
                  //kline.indicators.rsi >= prevKline.indicators.rsi + 6;// &&
                  //currentPerc > (-1 * prevPerc);
        
    const oldh2 = kline.candlestick.color === 'green' &&
                  prevKline.candlestick.color === 'red' &&
                  prevKline.candlestick.close <= minClosePrice &&
                  activeLine1 &&
                  activeLine1[0]?.price < prevKline.candlestick.open &&
                  activeLine1[0]?.price >= prevKline.candlestick.low;

    let redBottomNeedlePerc = calculateBetweenPercentage(kline.candlestick.close, kline.candlestick.low);
    let redTopNeedlePerc = calculateBetweenPercentage(kline.candlestick.high, kline.candlestick.open);
    let redCurrentPerc = calculateBetweenPercentage(kline.candlestick.open, kline.candlestick.close);
    let redActiveLine = sortedSupports.filter(s => s.price >= kline.candlestick.low && s.price <= kline.candlestick.open);

    const oldh5 = kline.candlestick.color === 'red' &&
                  redActiveLine.length > 0 &&
                  redBottomNeedlePerc >= 0.5 &&
                  redBottomNeedlePerc > redCurrentPerc &&
                  redTopNeedlePerc <= redCurrentPerc;

    let greenBottomNeedlePerc = calculateBetweenPercentage(kline.candlestick.open, kline.candlestick.low);
    let greenTopNeedlePerc = calculateBetweenPercentage(kline.candlestick.high, kline.candlestick.close);
    let greenCurrentPerc = calculateBetweenPercentage(kline.candlestick.close, kline.candlestick.open);
    let greenActiveLine = sortedSupports.filter(s => s.price <= kline.candlestick.close && s.price >= kline.candlestick.low);


    const oldh6 = kline.candlestick.color === 'green' &&
                  greenActiveLine.length > 0 &&
                  greenBottomNeedlePerc >= 0.5 &&
                  greenBottomNeedlePerc > greenCurrentPerc &&
                  greenTopNeedlePerc <= greenCurrentPerc;

    const openLongDepthHill = kline.candlestick.color === 'green' &&
                             prevKline.candlestick.color === 'red' &&
                             prevKline.candlestick.close <= minClosePrice &&
                             activeLine1 &&
                             activeLine1.price < prevKline.candlestick.open &&
                             activeLine1.price >= prevKline.candlestick.low;
    */

    if(oldh1) {
        condition = true;
        type = 1;
        //console.log(mostLowPrice, mostHighPrice);
    }/*
    else if(oldh5) {
        condition = true;
        type = 5;
    }
    else if(oldh6) {
        condition = true;
        type = 6;        
    }*//*
    else if(oldh2) {
        condition = true;
        type = 2;        
    }*/
    else {
        condition = false;
        type = "";
    }

    return { condition: condition, type: type, supportLine: activeLine1 };
}

const calculateCloseLongDepthHill = (kline, prevKline, twoPrevKline, currentDeal, supports) => {
    let currentLine = supports.filter(s => s.price <= kline.candlestick.open && s.price >= kline.candlestick.low).sort((a, b) => a.price - b.price);
    let prevCurrentLine = supports.filter(s => s.price >= prevKline.candlestick.close && s.price <= prevKline.candlestick.open).sort((a, b) => a.price - b.price);
    let twoPrevCurrentLine = supports.find(s => s.price === twoPrevKline.candlestick.open || (s.price > twoPrevKline.candlestick.close && s.price < twoPrevKline.candlestick.open));

    let closeLongOp2 = kline.candlestick.close < currentDeal.openPrice * 0.98;

    let percentage = kline.candlestick.change;
    let twoKlinePercentageMix = Number(((kline.candlestick.close - prevKline.candlestick.open) * 100 / prevKline.candlestick.open).toFixed(2));

    let prevKlineBodyPerc = Number(((prevKline.candlestick.close - prevKline.candlestick.open) * 100 / prevKline.candlestick.open).toFixed(2));
    let prevKlineTopNeedlePerc = Number(((prevKline.candlestick.high - prevKline.candlestick.close) * 100 / prevKline.candlestick.close).toFixed(2));


    let condition = false;
    let type = '';

    // mum kırmızı, önceki mum yeşil, son mum gövdesi çizgi üzerinde, son %-1.5'tan büyük.
    const dh1 = kline.candlestick.color === 'red' &&
                prevKline.candlestick.color === 'green' &&
                currentLine.length > 0 &&
                //percentage <= -1.5;
                prevKline.indicators.rsi >= 65;
                //kline.indicators.rsi < 60;   

    // güncel kapanış, açılış fiyatından %1.5 düşük.
    //const dh2 = kline.candlestick.close < currentDeal.openPrice * 0.985;

    const dh2 = kline.candlestick.low < currentDeal.openPrice * 0.96;


    // mum kırmızı, önceki mum kırmızı, güncel ya da önceki mumun gövdesi çizgi üzerinde, 2 mumun yüzdesel oranı %-1.5'tan büyük.
    const dh3 = kline.candlestick.color === 'red' &&
                prevKline.candlestick.color === 'red' &&
                (prevCurrentLine || currentLine) &&
                //twoKlinePercentageMix <= -1.5;
                prevKline.indicators.rsi >= 60 && 
                kline.indicators.rsi < 60;

    // günce mum çizgi üzerinde, önceki mum rsi 60'tan büyük, güncel mum rsi 60'tan küçük.
    const dh4 = currentLine &&
                prevKline.indicators.rsi >= 60 && 
                kline.indicators.rsi < 60;
    
    // en yüksek fiyat, açılış fiyatından en az %1 daha büyük, güncel kapanış fiyatı, açılış fiyatının altında.
    const dh5 = currentDeal.highPrice >= currentDeal.openPrice * 1.01 &&
                kline.candlestick.close <= currentDeal.openPrice * 1.005;

    const dh6 = currentDeal.topNeedle >= currentDeal.openPrice * 1.03 && currentDeal.topNeedle >= kline.candlestick.close * 1.03;

    const dh7 = currentDeal.candleCount === 2;

    const dh8 = currentDeal.topNeedle >= currentDeal.openPrice * 1.02 && kline.candlestick.close <= currentDeal.openPrice;

    const forObv = false;//kline.indicators.obv < kline.indicators.obvMa21;

    /*if(dh1) {
        condition = true;
        type = 1;
    }
    else */if(dh2) {
        condition = true;
        type = 2;        
    }
    else if(forObv) {
        condition = true;
        type = 'obv';
    }
    /*
    else if(dh3) {
        condition = true;
        type = 3;        
    }
    else if(dh4) {
        condition = true;
        type = 4;        
    }
    else if(dh5) {
        condition = true;
        type = 5;        
    }*/
    else if(dh6) {
        condition = true;
        type = 6; 
    }
    /*
    else if(dh8) {
        condition = true;
        type = 8;         
    }*/
    /*
    else if(dh7) {
        condition = true;
        type = 7; 
    }*/
    /*
    else {
        condition = false;
        type = "";
    }*/

    return { condition: condition, type: type, closeLine: currentLine || prevCurrentLine};
}

const calculateShortDepthHill = (kline, prevKline, prevKlines, supports) => {
    // pop shift bişi yapılacak son gelen çıkmalı
    let prevClosePrices = prevKlines.map(p => p.candlestick.close);

    let maxClosePrice = Math.max(...prevClosePrices);
    //console.log(kline, prevKline, prevKlines, supports);

    let sortedSupports = supports.sort((a, b) => a.price - b.price);
    let activeLine = sortedSupports.filter(s => s.price <= kline.candlestick.high && s.price >= kline.candlestick.open);

    let condition = false;
    let type = '';

    /*
    let activeLine1 = hasActiveLine(sortedSupports, prevKline.candlestick.open, prevKline.candlestick.close);
    const osdh1 = prevKline.candlestick.color === "red" &&
                  prevKline.candlestick.change < -1 &&
                  prevKline.candlestick.change > -2 &&
                  activeLine1.length > 0 &&
                  kline.candlestick.color === 'green' &&
                  kline.candlestick.high < activeLine1[0]?.price;
    */

    const osdh1 = false;
    /*
    let prevHighPrices = prevKlines.map(p => p.candlestick.high);
    let maxHighPrices = Math.max(...prevHighPrices);
    let activeLine1 = hasActiveLine(sortedSupports, kline.candlestick.high * 1.001, kline.candlestick.open * 0.999);
    const osdh1 = activeLine1.length > 0 &&
                  kline.candlestick.high >= maxHighPrices &&
                  kline.candlestick.color === 'red';
    */

    /*
    const openShortDepthHill = kline.candlestick.color === 'red' &&
                             prevKline.candlestick.color === 'green' &&
                             prevKline.candlestick.close >= maxClosePrice &&
                             prevKline.indicators.rsi >= 65 &&
                             //kline.indicators.rsi < 65 &&
                             activeLine;
                             //activeLine.price < prevKline.candlestick.open &&
                             //activeLine.price >= prevKline.candlestick.close;
                             // üstteki iki koşulda düzenleme yapılacak. anlayamadım.
    //console.log('OPEN SHORT DATA', kline, prevKline, supports, openShortDepthHill);
*/

if(osdh1) {
    condition = true;
    type = 1;
}/*
else if(oldh5) {
    condition = true;
    type = 5;
}
else if(oldh6) {
    condition = true;
    type = 6;        
}*//*
else if(oldh2) {
    condition = true;
    type = 2;        
}*/
else {
    condition = false;
    type = "";
}
    return { condition: condition, type: type, supportLine: activeLine };
}

// shorta uygun ve long açabilecek şekilde koşullar yazılacak.
const calculateCloseShortDepthHill = (kline, prevKline, twoPrevKline, currentDeal, supports) => {
    let currentLine = supports.find(s => s.price <= kline.candlestick.close && s.price >= kline.candlestick.open);
    let prevCurrentLine = supports.find(s => s.price >= prevKline.candlestick.close && s.price <= prevKline.candlestick.open);
    
    let sortedSupports = supports.sort((a, b) => a.price - b.price);

    let twoPrevCurrentLine = supports.find(s => s.price === twoPrevKline.candlestick.open || (s.price > twoPrevKline.candlestick.close && s.price < twoPrevKline.candlestick.open));

    let closeLongOp2 = kline.candlestick.close < currentDeal.openPrice * 0.98;

    let percentage = kline.candlestick.change;
    let twoKlinePercentageMix = Number(((kline.candlestick.close - prevKline.candlestick.open) * 100 / prevKline.candlestick.open).toFixed(2));

    let prevKlineBodyPerc = Number(((prevKline.candlestick.close - prevKline.candlestick.open) * 100 / prevKline.candlestick.open).toFixed(2));
    let prevKlineTopNeedlePerc = Number(((prevKline.candlestick.high - prevKline.candlestick.close) * 100 / prevKline.candlestick.close).toFixed(2));


    let condition = false;
    let type = '';

    let activeLine1 = hasActiveLine(sortedSupports, kline.candlestick.close, kline.candlestick.low);
    const csdh1 = prevKline.candlestick.color === 'red' &&
                  kline.candlestick.color === 'green' &&
                  activeLine1.length > 0;



    if(csdh1) {
        condition = true;
        type = 1;
    }
    else {
        condition = false;
        type = "";
    }

    /*

    // mum yeşil, önceki mum kırmızı, son mum gövdesi çizgi üzerinde, son %1'den büyük.
    const dh1 = kline.candlestick.color === 'green' &&
                prevKline.candlestick.color === 'red' &&
                currentLine &&
                //percentage >= 1;
                prevKline.indicators.rsi <= 35 && 
                kline.indicators.rsi > 35;
    
    // güncel kapanış, açılış fiyatından %2 daha büyük.
    const dh2 = kline.candlestick.close > currentDeal.openPrice * 1.02;

    // mum yeşil, önceki mum yeşil, güncel ya da önceki mumun gövdesi çizgi üzerinde, 2 mumun yüzdesel oranı %1'den büyük.
    const dh3 = kline.candlestick.color === 'green' &&
                prevKline.candlestick.color === 'green' &&
                (prevCurrentLine || currentLine) &&
                //twoKlinePercentageMix >= 1.5;
                prevKline.indicators.rsi <= 35 && 
                kline.indicators.rsi > 35;

    // güncel mum çizgi üzerinde, önceki mum rsi 40'tan küçük, güncel mum rsi 40'tan büyük.
    const dh4 = currentLine &&
                prevKline.indicators.rsi <= 35 && 
                kline.indicators.rsi > 35;
    
    // en yüksek fiyat, açılış fiyatından en az %1 daha büyük, güncel kapanış fiyatı, açılış fiyatının altında.
    const dh5 = currentDeal.highPrice <= currentDeal.openPrice * 0.99 &&
                kline.candlestick.close >= currentDeal.openPrice;

    if(dh1) {
        condition = true;
        type = 1;
    }
    else if(dh2) {
        condition = true;
        type = 2;        
    }
    else if(dh3) {
        condition = true;
        type = 3;        
    }
    else if(dh4) {
        //condition = true;
        type = 4;        
    }
    else if(dh5) {
        //condition = true;
        type = 5;        
    }
    else {
        condition = false;
        type = "";
    }
*/
    return { condition: condition, type: type, closeLine: currentLine || prevCurrentLine};
}

const calculatePrevKlines = (prevKlines, analysisModel) => {

    const firstPrevKline = [...prevKlines][0];
    // Mum yeşilse açılış fiyatı, kırmızı ise kapanış fiyatı baz alınır.
    const firstPrevBasePrice = firstPrevKline.candlestick.color === 'green' ? firstPrevKline.candlestick.open : firstPrevKline.candlestick.close;
    // mumun orta fiyatını buluyor. alternatif olarak duracak.
    //const fivePrevBasePrice = fivePrevKline.candlestick.close > fivePrevKline.candlestick.open ? fivePrevKline.candlestick.close - ((fivePrevKline.candlestick.close - fivePrevKline.candlestick.open) / 2) : fivePrevKline.candlestick.open - ((fivePrevKline.candlestick.open - fivePrevKline.candlestick.close) / 2);

    let parallelEq = 0;
    let greenPrevKlines = 0;

    let closePrices = prevKlines.map(p => p.candlestick.close);
    let openPrices = prevKlines.map(p => p.candlestick.open);

    //let maxTopPrice = 0;
    //let maxBottomPrice = 100000;

    let maxTopPrice = Math.max(...[...closePrices, ...openPrices]);
    let maxBottomPrice = Math.min(...[...closePrices, ...openPrices]);

    const prevKlinesPercentage = prevKlines.filter((k, i) => {
        let perc = k.candlestick.change;
        let percentage = 0;
        if(k.candlestick.color === 'green') {
            greenPrevKlines++;

            //maxTopPrice = k.candlestick.close > maxTopPrice ? k.candlestick.close : maxTopPrice;
            //maxBottomPrice = k.candlestick.open < maxBottomPrice ? k.candlestick.open : maxBottomPrice;

            // önceki mumların orta fiyatlarını bulup ona göre oranlıyor.
            /*
            const middlePrice = k.candlestick.close - ((k.candlestick.close - k.candlestick.open) / 2);
            percentage = Number(((fivePrevBasePrice - middlePrice) * 100 / middlePrice).toFixed(2));
            */
            percentage = Number(((firstPrevBasePrice - k.candlestick.close) * 100 / k.candlestick.close).toFixed(2));
        }
        else {
            //maxTopPrice = k.candlestick.open > maxTopPrice ? k.candlestick.open : maxTopPrice;
            //maxBottomPrice = k.candlestick.close < maxBottomPrice ? k.candlestick.close : maxBottomPrice;
            // önceki mumların orta fiyatlarını bulup ona göre oranlıyor.
            /*
            const middlePrice = k.candlestick.open - ((k.candlestick.open - k.candlestick.close) / 2);
            percentage = Number(((fivePrevBasePrice - middlePrice) * 100 / middlePrice).toFixed(2));
            */
            percentage = Number(((firstPrevBasePrice - k.candlestick.open) * 100 / k.candlestick.open).toFixed(2));
        }

        if(analysisModel === "zombieSpot") {
            if(i !== 0 && (percentage < 11 && percentage > -1)) {
                parallelEq++;
            }
        }
        else {
            if(i !== 0 && (percentage < 1 && percentage > -1)) {
                parallelEq++;
            }
        }

        if(analysisModel === "explosionLoose" || analysisModel === "zombieSpot") {
            return perc < 1 && perc > -1;
        }
        else if(analysisModel === "explosionTight") {
            return perc < 0.7 && perc > -0.7 && (k.candlestick.color === 'green' ? k.candlestick.close > k.indicators.ema50 : k.candlestick.open > k.indicators.ema50); 
        }

        return perc < 1 && perc > -1 && (k.candlestick.color === 'green' ? k.candlestick.close > k.indicators.ema50 : k.candlestick.open > k.indicators.ema50); 
        //return perc < 1 && perc > -1 && k.candlestick.close > k.indicators.ema21;
    });

    const prevKlinesAverageWidth = Number(((maxTopPrice - maxBottomPrice) * 100 / maxBottomPrice).toFixed(2));
    //console.log('maxTopPrice', maxTopPrice);
    //console.log('maxBottomPrice', maxBottomPrice);

    return {parallelEq: parallelEq, greenKlinesCount: greenPrevKlines, klinesPercentage: prevKlinesPercentage, klinesAverageWidth: prevKlinesAverageWidth, maxTopPrice: maxTopPrice}
} 

const prevKlinesPercentages = (prevKlines) => {
    let prevKlinesPerc = prevKlines.filter((k, i) => {
        let change = k.candlestick.change;
        return change < 1.00 && change > -1.00;
    });

    return prevKlinesPerc.length;
}

const prevObvComparison = (prevKlines) => {
    let result = true;
    prevKlines.map(k => {
        if(k.indicators.obv > k.indicators.obvMa21) {
            result = false;
        }
    });

    return result;
}

const calculateLongFindBottom = (kline, prevKline, currentDeal) => {
    return null;
}

const calculateEma20Test = (kline) => {
    const openLongT20 = kline.indicators.ema20 > kline.indicators.ema100 &&
    kline.candlestick.low <= kline.indicators.ema20 &&
    kline.candlestick.close > kline.indicators.ema20 &&
    kline.candlestick.color === 'green';
    return openLongT20;
}

const calculateRsiUpThirthy = (prevKline, lastKline) => {
    const upRsiThirthy = prevKline.indicators.rsi < 30 && lastKline.indicators.rsi >= 30;
    return upRsiThirthy;
}

const calculateRsiDownThirthy = (kline) => {
    const downRsiThirthy = kline.indicators.rsi < 30;
    return downRsiThirthy;
}

const calculateEma200 = (prevKline, kline) => {
    let isTrue = false;
    let type = '';

    // son mum yeşil ise;
    if(kline.candlestick.color === 'green') {
        // son mum yeşil ise;

        if(prevKline.candlestick.color === 'red' && prevKline.candlestick.close < prevKline.indicators.ema200 && prevKline.candlestick.open > prevKline.indicators.ema200 && kline.candlestick.close > kline.indicators.ema200 && kline.candlestick.open < kline.indicators.ema200) {
            // önceki mum kırmızı, önceki kapanış ema200 altında, önceki açılış ema200 üstünde, son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "DownUp";
            //console.log('Yeşil', 'Kırmızı', 'Son Ema200', kline.indicators.ema200, 'Mum kapanışı', kline.candlestick.close);
        }
        else if (kline.candlestick.close > kline.indicators.ema200 && kline.candlestick.open < kline.indicators.ema200) {
            // son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "Up";
            //console.log('Yeşil', 'Son Ema200', kline.indicators.ema200, 'Mum kapanışı', kline.candlestick.close);
        }
    }
    else {
        // son mum kırmızı ise;
        if(prevKline.candlestick.color === 'green' && prevKline.candlestick.close > prevKline.indicators.ema200 && prevKline.candlestick.open < prevKline.indicators.ema200 && kline.candlestick.close < kline.indicators.ema200 && kline.candlestick.open > kline.indicators.ema200) {
            // önceki mum yeşil, önceki kapanış em200 üstünde, önceki açılış ema200 altında, son mum kapanış ema200 altında, son mum açılış ema200 üstünde;          
            isTrue = true;
            type = "UpDown";
            //console.log('Kırmızı', 'Yeşil', 'Son Ema200', kline.indicators.ema200, 'Mum açılışı', kline.candlestick.open);
        }
        else if(kline.candlestick.close < kline.indicators.ema200 && kline.candlestick.open > kline.indicators.ema200) {
            isTrue = true;
            type = "Down";
            //console.log('Kırmızı', 'Son Ema200', kline.indicators.ema200, 'Mum açılışı', kline.candlestick.open);
            // LIT : ema200 yanlış hesaplanıyor. 5 Agu, 14:30 ile başlayan mum. aslı 1.002,487 hesaplanan 1.014
        }

    }
    return {condition: isTrue, type: type};
}

const calculateEma100 = (prevKline, kline) => {
    let isTrue = false;
    let type = '';

    // son mum yeşil ise;
    if(kline.candlestick.color === 'green') {
        // son mum yeşil ise;

        if(prevKline.candlestick.color === 'red' && prevKline.candlestick.close < prevKline.indicators.ema100 && prevKline.candlestick.open > prevKline.indicators.ema100 && kline.candlestick.close > kline.indicators.ema100 && kline.candlestick.open < kline.indicators.ema100) {
            // önceki mum kırmızı, önceki kapanış ema200 altında, önceki açılış ema200 üstünde, son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "DownUp";
        }
        else if (kline.candlestick.close > kline.indicators.ema100 && kline.candlestick.open < kline.indicators.ema100) {
            // son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "Up";
        }
    }
    else {
        // son mum kırmızı ise;
        if(prevKline.candlestick.color === 'green' && prevKline.candlestick.close > prevKline.indicators.ema100 && prevKline.candlestick.open < prevKline.indicators.ema100 && kline.candlestick.close < kline.indicators.ema100 && kline.candlestick.open > kline.indicators.ema100) {
            // önceki mum yeşil, önceki kapanış em200 üstünde, önceki açılış ema200 altında, son mum kapanış ema200 altında, son mum açılış ema200 üstünde;
            
            isTrue = true;
            type = "UpDown";
    
        }
        else if(kline.candlestick.close < kline.indicators.ema100 && kline.candlestick.open > kline.indicators.ema100) {
            isTrue = true;
            type = "Down";
        }

    }
    return {condition: isTrue, type: type};
}

const calculateSupRes = (prevKline, kline, supports) => {
    let activeLine = supports.find(l => l.price >= kline.candlestick.low && l.price <= kline.candlestick.high);
    let sr = {
        condition: false,
        image: null,
        line: null,
        strength: null,
        prevLine: null,
        nextLine: null
    }
    if(activeLine) {
        let activeLineIndex = supports.map(x => x.price).indexOf(activeLine.price);
        sr.condition = true;
        sr.line = Number(activeLine.price.toFixed(5));
        sr.strength = activeLine.strength;
        sr.prevLine = activeLineIndex > 0 ? Number(supports[activeLineIndex - 1].price.toFixed(4)) : 0;
        sr.nextLine = activeLineIndex < supports.length - 1 ? Number(supports[activeLineIndex + 1].price.toFixed(4)) : 0;
        let prevKlineIsOnLine = hasActiveLine(activeLine.price, prevKline.candlestick.high, prevKline.candlestick.low);
        let prevIsGreen = prevKline.candlestick.color === 'green';

        if(kline.candlestick.color === 'green') {
            if(hasActiveLine(sr.line, kline.candlestick.close, kline.candlestick.open, 'no')) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenzero';
                }
                else {
                    sr.image = 'greenzero';
                }   
            }
            else if(sr.line === kline.candlestick.open) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenminus1';
                }
                else {
                    sr.image = 'greenminus1';
                }
            }
            else if(hasActiveLine(sr.line, kline.candlestick.open, kline.candlestick.low, 'no')) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenminus2';
                }
                else {
                    sr.image = 'greenminus2';
                }
            }
            else if(sr.line === kline.candlestick.low) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenminus3';
                }
                else {
                    sr.image = 'greenminus3';
                }
            }
            else if(sr.line === kline.candlestick.close) {
                sr.image = 'greenplus1';
            }
            else if(hasActiveLine(sr.line, kline.candlestick.high, kline.candlestick.close, 'no')) {
                sr.image = 'greenplus2';
            }
            else if(sr.line === kline.candlestick.high) {
                sr.image = 'greenplus3';
            }
            else {
                sr.image = null;
            }
        }
        else {
            // kline is red
            if(hasActiveLine(sr.line, kline.candlestick.open, kline.candlestick.close, 'no')) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredzero';
                }
                else {
                    sr.image = 'redzero';
                }   
            }
            else if(sr.line === kline.candlestick.open) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredplus1';
                }
                else {
                    sr.image = 'redplus1';
                }
            }
            else if(hasActiveLine(sr.line, kline.candlestick.high, kline.candlestick.open, 'no')) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredplus2';
                }
                else {
                    sr.image = 'redplus2';
                }
            }
            else if(sr.line === kline.candlestick.high) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredplus3';
                }
                else {
                    sr.image = 'redplus3';
                }
            }
            else if(sr.line === kline.candlestick.close) {
                sr.image = 'redminus1';
            }
            else if(hasActiveLine(sr.line, kline.candlestick.close, kline.candlestick.low, 'no')) {
                sr.image = 'redminus2';
            }
            else if(sr.line === kline.candlestick.low) {
                sr.image = 'redminus3';
            }
            else {
                sr.image = null;
            }
        }
    }
    return sr;
}

const calculatePercentage = (kline) => {
    //console.log('calculatePercentage inside', kline);
    return Number(((kline.candlestick.close - kline.candlestick.open) * 100 / kline.candlestick.open).toFixed(2));
}

const calculateBetweenPercentage = (price1, price2) => {
    //console.log('calculatePercentage inside', kline);
    return Number(((price1 - price2) * 100 / price2).toFixed(2));
}

const hasActiveLine = (line, bigValue, smallValue, type) => {
    //return lines.filter(s => s.price <= bigValue && s.price >= smallValue); 
    if(type === "no") {
        // no equality
        return line < bigValue && line > smallValue;
    }
    else if (type === "small") {
        // just small value equality
        return line < bigValue && line >= smallValue;
    }
    else if (type === "big") {
        // just big value equality
        return line <= bigValue && line > smallValue;
    }
    else {
        // full equality
        return line <= bigValue && line >= smallValue;
    }
}

const isOnActiveLine = (activeLine, bigValue, smallValue) => {
    let isOnActiveLine = bigValue >= activeLine && smallValue <= activeLine;
    return isOnActiveLine;
}

/*
const isOnActiveLine = (kline, activeLine) => {
    let isOnActiveLine = false;
    if(kline.candlestick.color === 'green') {
        isOnActiveLine = kline.candlestick.open <= activeLine && kline.candlestick.close >= activeLine; 
    }
    else {
        isOnActiveLine = kline.candlestick.open >= activeLine && kline.candlestick.low <= activeLine; 
    }
    return isOnActiveLine;
}
*/

    /*
    const closeLongDepthHill = (kline.candlestick.close < kline.candlestick.open &&
                               prevKline.candlestick.close > prevKline.candlestick.open &&
                               currentLine &&
                               percentage <= -1.5) ||
                               closeLongOp2 ||
                               (kline.candlestick.close < kline.candlestick.open &&
                                prevKline.candlestick.close < prevKline.candlestick.open &&
                                (prevCurrentLine || currentLine) &&
                                twoKlinePercentageMix <= -1.5) ||
                                (currentLine &&
                                 prevKline.indicators.rsi >= 60 && kline.indicators.rsi < 60) ||
                                (currentDeal.highPrice >= currentDeal.openPrice * 1.01 &&
                                 kline.candlestick.close <= currentDeal.openPrice) ||
                                //
                                 (kline.candlestick.close < kline.candlestick.open &&
                                prevKline.candlestick.close > prevKline.candlestick.open &&
                                currentLine && 
                                twoPrevKline.candlestick.high > currentLine.price &&
                                currentDeal.candleCount > 3) ||
                                (kline.candlestick.close < kline.candlestick.open &&
                                prevKline.candlestick.close > prevKline.candlestick.open &&
                                currentLine &&
                                prevKlineTopNeedlePerc >= 1) ||
                                (kline.candlestick.close < kline.candlestick.open &&
                                prevKline.candlestick.close < prevKline.candlestick.open &&
                                twoPrevKline.candlestick.close < twoPrevKline.candlestick.open &&
                                twoPrevCurrentLine)
    closeLongDepthHill && console.log(currentDeal.activeLine, currentLine);
    */






    /*
    Mevcut mum:
        boll üst band değeri,
        boll orta band değeri,
        boll alt band değeri,
        rsi değeri (30un altı, 70in üstü kontroller)
        mum kapanış değeri,
        mum açılış değeri,
        mum max değeri,
        mum min değeri,
        mum rengi
    Önceki mum:
        boll üst band değeri,
        boll orta band değeri,
        boll alt band değeri,
        rsi değeri (30un altı, 70in üstü kontroller) 
        mum kapanış değeri,
        mum açılış değeri,
        mum max değeri,
        mum min değeri,
        mum rengi  
    */


















/*
        export const allCurrencyLastCandlestickAnalysis = async (symbols, lastKlines, symbolList, analysisModel, openPositions, supports) => {
            // güncellenen yeni mumların eski liste ile analiz edilip döndürülmesi.
            const supportResistance = {};
            
            Object.keys(lastKlines).map((symbol, i) => {
                let indicatorObj = {};
        
                fibonacci.map(fib => { 
                    indicatorObj[`ma${fib}`] = movingAverage([...symbols[symbol]].splice(symbols[symbol].length - (fib - 1), (fib - 1)), lastKlines[symbol].candlestick, symbol);
                    indicatorObj[`ema${fib}`] = expMovingAverage(symbols[symbol][symbols[symbol].length - 1].indicators[`ema${fib}`], lastKlines[symbol].candlestick.close, fib);
                }); 
           
                const prevKline = symbols[symbol][symbols[symbol].length - 1];
        
                // CALCULATE RSI VALUE
                const rsiObj = relativeStrengthIndex(prevKline.indicators.greenAvg, prevKline.indicators.redAvg, lastKlines[symbol], prevKline);
                indicatorObj.greenAvg = rsiObj.greenAvg;
                indicatorObj.redAvg = rsiObj.redAvg;
                indicatorObj.rsi = rsiObj.rsi;
        
                // CALCULATE BOLLINGER BANDS
                const calculate21forBoll = [...symbols[symbol]].splice(symbols[symbol].length - 21, 21).map(x => Number(x.candlestick.close));
        
                const splitPrice = lastKlines[symbol].candlestick.close.toString().split('.');
                const fixedValue = splitPrice[1] ? splitPrice[1].length : 0;
                const upper = Number((indicatorObj.ma21 + std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
                const lower = Number((indicatorObj.ma21 - std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
                indicatorObj.bollinger = {
                    upper: upper,
                    lower: lower,
                    middle: indicatorObj.ma21,
                    bandwidth: Number((upper - lower).toFixed(fixedValue))
                }
        
                lastKlines[symbol].indicators = indicatorObj;
        
                // Support Resistance Calculate
                let tempCurrentKlines = [...symbols[symbol]];
                tempCurrentKlines.push(lastKlines[symbol]);
                let symbolSupports = findSupportResistance(tempCurrentKlines);
                supportResistance[symbol] = symbolSupports;
                // Support Resistance Calculate - End
        
                return true;
            });
        
            //const newDataObj = {};
            let updatedPositions = [];
            const activeDeals = [];
        
            Object.keys(lastKlines).map((symbol, i) => {
                let lastKline = lastKlines[symbol];
                let prevKline = symbols[symbol][symbols[symbol].length - 1];
                let twoPrevKline = symbols[symbol][symbols[symbol].length - 2];
                let prevKlines = [];
        
                if(analysisModel === "zombieSpot") {
                    prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - spotPrevKlines, spotPrevKlines)
                }
                else if (analysisModel === "depthHill") {
                    prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - gunstockPrevKlines, gunstockPrevKlines)
                }
                else {
                    prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - 5, 5); 
                }
        
                let openPosition = openPositions && openPositions.find(p => p.symbol === symbol);
        
                let beforeComparisonResult;
                let currentDeal = {};
        
                if(openPosition) {
                    beforeComparisonResult = openPosition.status;
                    currentDeal = {...openPosition};
                }
                else {
                    beforeComparisonResult = 'CLOSE';
                    currentDeal = {
                        symbol: symbol,
                    }
                }
        
                 
                //Açık pozisyon varsa o pozisyona göre LONG ya da SHORT pozisyon verileri güncellenecek.
                //veri güncellemesinden sonra açık pozun kapanma durumu oluştuysa CLOSE ile ilgili veriler eklenecek.
                //Kapanan pozisyon açık pozlardan çıkarılacak ve old positions kısmına dahil edilecek.
                //Açık poz yok ise pozisyon açılışı yapılarak veriler oluşturulup açık pozisyonlara eklenecek.
                //Güncel fırsatlar kısmı içinde burada bi takım ayarlamalar yapılacak. Önemli
                //Güncel fırsatlarda bu fonksiyon içerisinden gönderilecek.
                
                let comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supportResistance[symbol]);
        
                if(beforeComparisonResult === "LONG" || beforeComparisonResult === "SHORT") {
                    currentDeal.candleCount++;
        
                    if(currentDeal.openPrice) {
                        const closeMinusPerc = (Number(lastKline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        let tempCloseReverse = currentDeal.closeReverse;
                        currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
                        let tempMaxReverse = currentDeal.maxReverse;
        
                        let pnl = Number(((lastKline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
        
                        if(beforeComparisonResult === "LONG") {
                            currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                            const maxMinusPerc = (Number(lastKline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                            currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                            currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
            
                            currentDeal.highPriceIndex = Number(lastKline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                            currentDeal.highPrice = Number(lastKline.candlestick.close) > currentDeal.highPrice ? Number(lastKline.candlestick.close) : currentDeal.highPrice;
                            currentDeal.topNeedleIndex = Number(lastKline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                            currentDeal.topNeedle = Number(lastKline.candlestick.high) > currentDeal.topNeedle ? Number(lastKline.candlestick.close) : currentDeal.topNeedle;
                            if(currentDeal.candleCount === 1) {
                                currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                            } 
        
                            currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                            currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));                     
                        }
                        else if(beforeComparisonResult === "SHORT") {
                            currentDeal.closeReverse = -1 * closeMinusPerc < tempCloseReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
                            const maxMinusPerc = (Number(lastKline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                            currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;     
                            currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
        
                            currentDeal.highPriceIndex = Number(lastKline.candlestick.close) < currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                            currentDeal.highPrice = Number(lastKline.candlestick.close) < currentDeal.highPrice ? Number(lastKline.candlestick.close) : currentDeal.highPrice;
                            currentDeal.topNeedleIndex = Number(lastKline.candlestick.low) < currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                            currentDeal.topNeedle = Number(lastKline.candlestick.low) < currentDeal.topNeedle ? Number(lastKline.candlestick.low) : currentDeal.topNeedle;    
        
                            if(currentDeal.candleCount === 1) {
                                currentDeal.firstReverse = -1 * maxMinusPerc < tempMaxReverse ? Number(-1 * maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                            }
        
                            currentDeal.highProfit = Number(-1 * ((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                            currentDeal.mostProfit = Number(-1 * ((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));    
                        
                            pnl = -1 * pnl; 
                        }
        
                        currentDeal.closePrice = Number(lastKline.candlestick.close);
                        currentDeal.closeDate = lastKline.candlestick.closeTime;
                        currentDeal.time = getPassingTime(new Date(lastKline.candlestick.closeTime), new Date(currentDeal.openDate));
                        //currentDeal.closeType = comparisonResult.closeType;
        
                        if(analysisModel === "depthHill") {
                            //pnl = pnl < -1.5 ? -1.5 : pnl;
                            if(currentDeal.mostProfit >= 1) {
                                pnl = currentDeal.mostProfit - 1;
                            }
                            else if(pnl < -2) {
                                pnl = -2;
                            }
                        }
                        else if(analysisModel === "explosionLoose") {
                            if(currentDeal.mostProfit > 3 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.97) {
                                pnl = currentDeal.mostProfit - 3;
                            }
                            else if(currentDeal.mostProfit <= 3 && currentDeal.mostProfit > 0 && lastKline.candlestick.low <= currentDeal.topNeedle * 0.95) {
                                pnl = currentDeal.mostProfit - 5;
                            }
                            else if(pnl < -2) {
                                pnl = -2;
                            }
                        }
                        else {
                            pnl = pnl < -5 ? -5 : pnl;
                        }
        
                        currentDeal.pnl = pnl;
        
                        if(openPosition) {
                            openPosition.candleCount = currentDeal.candleCount;
                            openPosition.closeReverse = currentDeal.closeReverse;
                            openPosition.closeReverseIndex = currentDeal.closeReverseIndex;
                            openPosition.maxReverse = currentDeal.maxReverse;
                            openPosition.maxReverseIndex = currentDeal.maxReverseIndex;
                            openPosition.highPrice = currentDeal.highPrice;
                            openPosition.highPriceIndex = currentDeal.highPriceIndex;
                            openPosition.topNeedle = currentDeal.topNeedle;
                            openPosition.topNeedleIndex = currentDeal.topNeedleIndex;
                            openPosition.firstReverse = currentDeal.firstReverse;
                            openPosition.closePrice = currentDeal.closePrice;
                            openPosition.closeDate = currentDeal.closeDate;
                            openPosition.time = currentDeal.time;
                            openPosition.pnl = currentDeal.pnl;
                            openPosition.highProfit = currentDeal.highProfit;
                            openPosition.mostProfit = currentDeal.mostProfit;       
                        }
                    }
                }
        
                if(beforeComparisonResult !== comparisonResult.position) {
                    if(comparisonResult.position === "CLOSE") {
                        currentDeal.closeType = comparisonResult.closeType;
        
                        if(openPosition) {
                            openPosition.closeType = currentDeal.closeType;
                        }
        
                        currentDeal.status = "CLOSE";
                        currentDeal.openPrice = null;
                        currentDeal.highPrice = null;
                        currentDeal.highPriceIndex = 0;
                        currentDeal.topNeedle = null;
                        currentDeal.topNeedleIndex = 0;
                        currentDeal.posType = "";
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.candleCount = 0;  
        
                        // var olan açık pozisyonu güncelle
        
                        beforeComparisonResult = comparisonResult.position;
                        comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisModel, supportResistance[symbol]);
                    }
        
                    if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                        currentDeal.key = lastKline.candlestick.closeTime + symbol;
                        currentDeal.closeType = 'NC';
                        currentDeal.status = comparisonResult.position;
                        currentDeal.posType = comparisonResult.posType;
                        currentDeal.openPrice = Number(lastKline.candlestick.close);
                        currentDeal.highPrice = Number(lastKline.candlestick.close);
                        currentDeal.topNeedle = Number(lastKline.candlestick.close);
                        currentDeal.closePrice = Number(lastKline.candlestick.close);
                        currentDeal.openDate = lastKline.candlestick.closeTime;
                        currentDeal.closeDate = lastKline.candlestick.closeTime;
                        currentDeal.time = getPassingTime(new Date(lastKline.candlestick.closeTime), new Date(currentDeal.openDate));
                        currentDeal.highProfit = 0;
                        currentDeal.mostProfit = 0;
                        currentDeal.candleCount = 0;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.pnl = 0;
                        if(analysisModel === "depthHill") {
                            currentDeal.supportLine = comparisonResult.supportLine;
                        }
                        
                        // yeni pozisyon ekle
                        openPositions.push({...currentDeal});
                        updatedPositions = openPositions;
                    }
        
                    let activeDeal = {
                        key: symbol,
                        status: '',
                        symbol: symbol,
                        price: lastKline.candlestick.close,
                        date: lastKline.candlestick.closeTime,
                        volume: lastKline.candlestick.vol,
                        posType: currentDeal.posType,
                        closeType: currentDeal.closeType,
                        ema20: lastKline.indicators.ema20,
                        ema50: lastKline.indicators.ema50,
                        ema100: lastKline.indicators.ema100,
                        ema200: lastKline.indicators.ema200,
                        prevRsi: prevKline.indicators.rsi,
                        rsi: lastKline.indicators.rsi,
                        bbUp: lastKline.indicators.bollinger.upper,
                        bbMid: lastKline.indicators.bollinger.middle,
                        bbLow: lastKline.indicators.bollinger.lower
                    };
        
                    if (currentDeal.status === "CLOSE" || currentDeal.status === "LONGCLOSE") {
                        activeDeal.status = 'CLOSE POSITION';
                    }
                    else {
                        activeDeal.status = `OPEN ${currentDeal.status}`;
                    }
                    //console.log('Current Single Deal:', currentDeal);
                    activeDeals.push({...activeDeal}); 
                }
        
                // açık pozisyon varsa eklenen yeni verilerle güncelleme yap.
                if(openPosition) {
                    let findIndex = openPositions.findIndex(p => p.symbol === openPosition.symbol);
                    openPositions.splice(findIndex, 1, openPosition);
                    updatedPositions = openPositions;
                }
        
                return true;
            });
        
            return {candlesticks: lastKlines, positions: updatedPositions, supports: supportResistance, activeDeals: activeDeals };
        }

*/